import LOG from './log.js';
import PubSub from './pubSub';
import globalTracingLogger from '../common/globalTracingLogger.js';
import Zoom_Monitor from '../inside/Monitor';
import throttle from 'lodash/throttle';
import { DATACHANNEL_MONITOR_SEPARATOR } from '../worker/common/consts.js';

import { NetStatistic } from '../statistic/netStatistic';
const log = LOG('sdk.rtcUtil');

class RTCPeerConnectionUtil {
  constructor() {
    this.rtcPeerConnection = null;
    this.dataChannel = null;
    /**
     * indicate this is video dataChannel or audio dataChannel
     * @type {null}
     */
    this.dataChannelLabel = null;
    this.messageListener = null;
    this.datachannelcloselistener = null;
    this.datachannelopenlistener = null;
    this.rtcPeerConnectionCreatedListener = null;
    this.reconnectCount = 0;
    this.reconnectMax = 10;
    this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen = 0;
    this.connectionID = null;
    this.pubSubTokenList = [];
    this.isForceClosed = false;
    this.reconnectRTCPeerConnectionThrottle = throttle(() => {
      this.reconnectRTCPeerConnection();
    }, 2000);
    this.timeoutThenCloseInterval = null;
    this.userid = null;
    this.connectionType = null;
    this.dataChannelStatus = '';
    this.delaycloseTimeout = 0;
    this.delaycloseid_ = 0;
    this._send_statistic = null;
    this._recv_statistic = null;
  }

  setUserid(userid) {
    this.userid = userid;
  }
  setConnectionType(connectionType) {
    this.connectionType = connectionType;
  }

  isSupportDataChannel() {
    return !!window.RTCDataChannel;
  }

  iceClosed(ev) {
    if (this.isForceClosed) {
      return;
    }
    log('rtcPeerConnection.iceClosed', ev);
    this._close(true);
    this._clear();
  }

  async initConnection(
    connectionID,
    dataChannelLabel = 'ZoomWebclientVideoDataChannel'
  ) {
    Zoom_Monitor.add_monitor('DCCONN');
    if (!this.isSupportDataChannel()) {
      Zoom_Monitor.add_monitor('DCUNSUPPORT');
      return;
    }
    let isvideo = dataChannelLabel == 'ZoomWebclientVideoDataChannel' ? 1 : 0;
    const report_monitor_func = (tag, data) => {
      Zoom_Monitor.add_monitor(`${tag}:${data}`);
    };
    if (!this._send_statistic) {
      this._send_statistic = new NetStatistic({
        tag: isvideo ? 'VDCS' : 'ADCS',
        report_call: report_monitor_func,
      });
    }

    if (!this._recv_statistic) {
      this._recv_statistic = new NetStatistic({
        tag: isvideo ? 'VDCR' : 'ADCR',
        report_call: report_monitor_func,
      });
    }
    this.dataChannelLabel = dataChannelLabel;
    this.connectionID = connectionID;

    this.rtcPeerConnection = new RTCPeerConnection({
      iceCandidatePoolSize: 1,
    });
    /* remove close event no stand event
     */
    this.rtcPeerConnection.addEventListener(
      'iceconnectionstatechange',
      (ev) => {
        if (this.isForceClosed) {
          return;
        }
        let rtc = this.rtcPeerConnection;
        if (
          rtc.iceConnectionState === 'failed' ||
          rtc.iceConnectionState === 'closed'
        ) {
          log(
            `${this.dataChannelLabel} iceconnectionstatechange`,
            rtc.iceConnectionState
          );
          Zoom_Monitor.add_monitor(
            'ICECONNSTATECHANGE:' + this.dataChannelLabel
          );
          this.iceClosed();
          /// close delay close timeout
          if (this.delaycloseid_) {
            clearTimeout(this.delaycloseid_);
            this.delaycloseid_ = 0;
          }
        }
        /**
         * firefox ice is different from chrome, the firefox will callback 'disconneted' event, while the stun request no respone
         * firefox send stun request every 5 seconds
         * chrome/safari 3 senconds,  chrome/safri will quickly request again,while the stun request no respone
         */
        if (rtc.iceConnectionState === 'disconnected') {
          if (!this.delaycloseid_) {
            let self = this;
            this.delaycloseid_ = setTimeout(() => {
              self.delaycloseid_ = 0;
              self.iceClosed();
            }, self.delaycloseTimeout);
          }
        }
        if (rtc.iceConnectionState === 'connected') {
          if (this.delaycloseid_) {
            clearTimeout(this.delaycloseid_);
            this.delaycloseid_ = 0;
          }
        }
      }
    );

    this._createDataChannel();

    await this.rtcPeerConnection
      .createOffer()
      .then(async (offer) => {
        log('original offer', JSON.stringify(offer));
        offer.sdp = offer.sdp.replace(
          /a=ice-ufrag:.+/g,
          `a=ice-ufrag:${connectionID}`
        );
        log('modified offer', offer);
        return this.rtcPeerConnection.setLocalDescription(offer);
      })
      .then(() => {
        this.rtcPeerConnectionCreatedListener.call(
          null,
          this.rtcPeerConnection
        );
      });
  }

  _close(fromlocal = false) {
    Zoom_Monitor.add_monitor('RTCPeerConnUtil.CLOSE');
    this._recv_statistic && this._recv_statistic.stop();
    this._send_statistic && this._send_statistic.stop();
    try {
      this._closeDataChannel();
      this.rtcPeerConnection && this.rtcPeerConnection.close();
    } catch (ex) {
      globalTracingLogger.error('Error when closing RTCPeerConnection', ex);
    } finally {
      this.dataChannel = null;
      this.rtcPeerConnection = null;
      if (fromlocal && this.datachannelcloselistener) {
        this.datachannelcloselistener(null);
      }
      this.sendDataChannelStatusMonitorLog();
      this.reconnectRTCPeerConnectionThrottle();
    }
  }

  forceClose() {
    Zoom_Monitor.add_monitor('DCFORCECLOSE:' + this.dataChannelLabel);
    log('forceClose : ' + this.dataChannelLabel);
    this.isForceClosed = true;
    this._close(true);
    this._clear();
  }
  isDestroyed() {
    return this.isForceClosed;
  }
  _clear() {
    this.timeoutThenCloseInterval &&
      clearInterval(this.timeoutThenCloseInterval);
    this.timeoutThenCloseInterval = 0;
    this.messageListener = null;
    this.datachannelcloselistener = null;
    this.datachannelopenlistener = null;
    this.pubSubTokenList.forEach((token) => {
      PubSub.unsubscribe(token);
    });
    this.pubSubTokenList = [];
  }

  onConnectionCreated(fn) {
    this.rtcPeerConnectionCreatedListener = fn;
  }

  reconnectRTCPeerConnection() {
    if (this.isForceClosed) return;

    if (
      this.reconnectCount < this.reconnectMax &&
      this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen <
        this.reconnectMax
    ) {
      log(
        `${this.dataChannelLabel} reconnect reconnectTotalCount  : ${this.reconnectCount}; reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen : ${this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen}, reconnectMax : ${this.reconnectMax}`
      );
      this.reconnectCount += 1;
      this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen += 1;
      // reconnect sleep seconds 2s,4s,8s,16s,32s,64s...
      setTimeout(() => {
        this.initConnection(this.connectionID, this.dataChannelLabel);
      }, Math.pow(2, this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen) * 1000);
    }
  }

  /**
   * some logs for "datachannel status dashboard", they (dashboard developers) need "one line for one single log"
   * @param log
   */
  oneSingleLineLog(log) {
    setTimeout(() => {
      Zoom_Monitor.send_instant_monitor();
      Zoom_Monitor.add_monitor(log);
      Zoom_Monitor.send_instant_monitor();
    }, 0);
  }

  /**
   * @descrpition preview don't have userid, need to re-send dcopen log here.
   * waiting room userid will change
   */
  checkEmptyUserIdAndResendMonitor(userid) {
    if (userid && this.userid != userid) {
      this.setUserid(userid);
      this.sendDataChannelStatusMonitorLog();
    }
  }

  /** prev page init data channel already, after in meeting, we need to re-send a data channel status monitor log with userid to server */
  sendDataChannelStatusMonitorLog() {
    if (this.userid) {
      if (this.dataChannelStatus === 'OPEN') {
        Zoom_Monitor.add_monitor('DCOPEN:' + this.dataChannelLabel);
        this.oneSingleLineLog(
          `${DATACHANNEL_MONITOR_SEPARATOR},${this.userid},${this.connectionType},DCOPEN,${DATACHANNEL_MONITOR_SEPARATOR}`
        );
      } else if (this.dataChannelStatus === 'CLOSED') {
        Zoom_Monitor.add_monitor('DCCLOSE:' + this.dataChannelLabel);
        this.oneSingleLineLog(
          `${DATACHANNEL_MONITOR_SEPARATOR},${this.userid},${this.connectionType},DCCLOSE,${DATACHANNEL_MONITOR_SEPARATOR}`
        );
      }
    }
  }

  _closeDataChannel() {
    if (!this.dataChannel) {
      return;
    }
    try {
      this.dataChannelStatus = 'CLOSED';
      this.datachannelclosehander &&
        this.dataChannel.removeEventListener(
          'close',
          this.datachannelclosehander
        );
      this.dataChannel.close();
    } catch (e) {
      globalTracingLogger.error(
        'Error when trying to close existing RTCDataChannel',
        e
      );
    } finally {
      this.datachannelclosehander = null;
      this.dataChannel = null;
    }
  }

  _createDataChannel() {
    /**
     * MDN Docs here : https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createDataChannel
     * ordered : Indicates whether or not messages sent on the RTCDataChannel are required to arrive at their destination in the same order in which they were sent (true), or if they're allowed to arrive out-of-order (false). Default: true.
     * maxRetransmits : The maximum number of times the user agent should attempt to retransmit a message which fails the first time in unreliable mode. While this value is a16-bit unsigned number, each user agent may clamp it to whatever maximum it deems appropriate. Default: null
     */
    this._closeDataChannel();
    let dataChannel = this.rtcPeerConnection.createDataChannel(
      this.dataChannelLabel,
      {
        ordered: false,
        maxRetransmits: 0,
        reliable: false,
      }
    );
    dataChannel.binaryType = 'arraybuffer';
    dataChannel.addEventListener('open', (ev) => {
      this.datachannelopenlistener && this.datachannelopenlistener(ev);
      this._send_statistic.start();
      this._recv_statistic.start();
      this.reconnectCountBetweenCloseAndOpenAndSetTo0WhenDCOpen = 0;
      clearInterval(this.timeoutThenCloseInterval);
      this.dataChannelStatus = 'OPEN';
      this.sendDataChannelStatusMonitorLog();
      log('dataChannel.onopen', ev);
    });
    this.datachannelclosehander = this._ondatachannelclosed.bind(this);
    dataChannel.addEventListener('close', this.datachannelclosehander);
    dataChannel.addEventListener('error', (ev) => {
      const err = ev.error || {};
      globalTracingLogger.warn(
        `WebRTC DataChannel ${this.dataChannelLabel} received error with errorDetail: ${err.errorDetail}`,
        err
      );
      clearInterval(this.timeoutThenCloseInterval);
      Zoom_Monitor.add_monitor(
        'DCERROR',
        `${this.dataChannelLabel}: ${err.message}, error detail: ${err.errorDetail}`
      );
      log('dataChannel.onerror', ev);
    });
    dataChannel.addEventListener('message', (ev) => {
      this._recv_statistic.sample(false);
      if (this.messageListener) {
        this.messageListener.call(null, ev.data);
      }
    });
    this.dataChannel = dataChannel;
  }

  _ondatachannelclosed(ev) {
    if (this.dataChannelStatus == 'CLOSED') {
      return;
    }
    this._send_statistic && this._send_statistic.stop();
    this._recv_statistic && this._recv_statistic.stop();
    clearInterval(this.timeoutThenCloseInterval);
    log('dataChannel.onclose', ev);
    this.dataChannelStatus = 'CLOSED';
    this.datachannelcloselistener && this.datachannelcloselistener(ev);
    this._close();
    this._clear();
  }

  /**
   * only support one message listener, because message will be transfered ownership to worker
   * @param fn
   */
  onMessage(fn) {
    this.messageListener = fn;
  }
  onDataChannelClose(fn) {
    this.datachannelcloselistener = fn;
  }

  onDataChannelOpen(fn) {
    this.datachannelopenlistener = fn;
  }

  waitForAnswerFromRWG(pubSubEvent) {
    let that = this;
    return new Promise((resolve, reject) => {
      let token = PubSub.on(pubSubEvent, (msg, data) => {
        if (that.isDestroyed()) {
          reject();
        }
        resolve(data);
      });
      this.pubSubTokenList.push(token);
    });
  }

  setRemoteDescription(answer) {
    log('setRemoteDescription', answer);
    this.rtcPeerConnection.setRemoteDescription(
      new RTCSessionDescription({
        type: 'answer',
        sdp: answer.sdp,
      })
    );
  }

  closeIfTimeout() {
    clearInterval(this.timeoutThenCloseInterval);

    this.timeoutThenCloseInterval = setTimeout(() => {
      log('closeIfTimeout');
      this._close();
    }, 10 * 1000);
  }

  addIceCandidate(candidate) {
    this.rtcPeerConnection.addIceCandidate(
      new RTCIceCandidate({
        candidate,
        sdpMLineIndex: 0,
        sdpMid: '0',
      })
    );
  }

  sendVideoData(data) {
    try {
      if (this.dataChannel?.readyState === 'open') {
        this.dataChannel?.send(data);
        this._send_statistic.sample(false);
      }
    } catch (ex) {
      globalTracingLogger.error('Error when sending video data via WebRTC', ex);
      log.error('sendVideoData', ex);
    }
  }

  sendAudioData(data) {
    try {
      if (this.dataChannel?.readyState === 'open') {
        this.dataChannel?.send(data);
        this._send_statistic.sample(false);
      }
    } catch (ex) {
      globalTracingLogger.error('Error when sending audio data via WebRTC', ex);
    }
  }

  listenOnDataAndSend(subscribeEventName) {
    let token = PubSub.on(subscribeEventName, (msg, data) => {
      this.sendVideoData(data);
    });
    this.pubSubTokenList.push(token);
  }

  listenOnDataAndSendAudio(subscribeEventName) {
    let token = PubSub.on(subscribeEventName, (msg, data) => {
      this.sendAudioData(data);
    });
    this.pubSubTokenList.push(token);
  }
}

export { RTCPeerConnectionUtil };
