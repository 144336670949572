/**
 * Constant Definition
 */
export const QOS_DEFAULT_POLLING_INTERVAL = 1000;
export const VIDEO_MONITOR_LOG_SECOENDS = 5;
export const THREAD_STATE_IDLE = 43;
export const THREAD_STATE_CREATING = 44;
export const THREAD_STATE_CREATED = 45;

export const MEDIA_S2C_KEEPALIVE = 0;
export const MEDIA_AUDIO_DATA = 1;
export const MEDIA_AUDIO_RTCP = 6;
export const MEDIA_AUDIO_FEATURE = 146;
export const MEDIA_VIDEO_DATA = 2;
export const MEDIA_VIDEO_RTCP = 7;
export const MEDIA_NTP_UPDATE = 9;
export const VIDEO_CAPTURER_RESOLUTION_360P = 10;
export const VIDEO_CAPTURER_RESOLUTION_720P = 11;
export const VIDEO_CAPTURER_RESOLUTION_1080P = 12;
export const UPDATE_ENCRYPTION_GCM_MODEL_KEY = 102;
export const MEDIA_AUDIO_QOS_SESS_DATA = 107;
export const INTERPRETATION_ENABLE = 0;
export const INTERPRETATION_SET_LANG = 1;
export const INTERPRETATION_MUTE = 2;
export const INTERPRETATION_SET_INTERPRETER = 3;

export const serverHeartbeatMaxTimeoutSeconds = 65;

//video render Mode
export const RQUEST_ANIMATION_MODE = 0;
export const SET_INTERVAL_MODE = 1;

//video data format
export const VIDEO_INVALID = -1;
export const VIDEO_RGBA = 0;
export const VIDEO_I420 = 1;
export const VIDEO_NV12 = 2;
export const VIDEO_BGRA = 3;

// audio worker & audio worklet message channel
export const EVENT_ROLLBACK_BUFFER = 0;
export const EVENT_NEEDMORE_DATA = 1;
export const EVENT_CAPTURE_DATA = 2;
export const EVENT_CACHE_SIZE = 3;

//webcodec parameters
export const WEBCODEC_ENCODE_OFF = 0x01;
export const WEBCODEC_DECODE_OFF = 0x02;

export const QosSession = {
  SESSION_TYPE_CONF: 0,
  SESSION_TYPE_AUDIO: 1,
  SESSION_TYPE_DESKSHARE: 2,
  SESSION_TYPE_VIDEO: 3,
  SESSION_TYPE_CHAT: 4,
  SESSION_TYPE_TELEPHONE: 5,
  SESSION_TYPE_ZC_PING: 6,
  SESSION_TYPE_TOTAL_CNT: 7 /** the auto end of the enum, the count of total session types*/,
};

export const QosConnectType = {
  CONNECT_TYPE_UDP: 0,
  CONNECT_TYPE_TCP: 1,
};

//VIDEO CAPTURE
export const MAX_VIDEO_CAPTURE_FPS = 30;
export const MIN_VIDEO_CAPTURE_FPS = 1;
export const VIDEO_CAPTURE_FPS = 24;
export const VIDEO_CAPTURE_20FPS = 20;
export const DOWN_VIDEO_CAPTURE_FPS = 15;
export const LOWER_VIDEO_CAPTURE_FPS = 10;
export const VIDEO_DATA_MAX_SIZE = 1920 * 1080 * 4;
export const VIDEO_FRAME_BUFFER_SIZE = 5;
//SHARING MODE

export const SHARING_NULL = 0; //sharing undefined
export const SHARING_NORMAL = 1;
export const SHARING_VIDEO_MODE = 2;
export const SHARING_VIDEO_MODE_CAPTURED_FPS = 15;
export const SHARING_NORMAL_MODE_CAPTURED_FPS = 5;
export const VIDEO_RINGBUF_PKG_NUM = 400;
//end sharing mode

export const ADDITIONNAL_MULTITHREAD_NUMBER_ENCODE_FOR_360P = 3;
export const ADDITIONNAL_MULTITHREAD_NUMBER_ENCODE_FOR_720P = 7;
export const ADDITIONNAL_MULTITHREAD_NUMBER_ENCODE_FOR_1080p = 8;
//Platform TYPE
export const WCL_PLATFORM_TYPE = {
  DESKTOP: 0,
  MOBILE: 1,
  ANDROID: 2,
  IPHONE: 3,
};

export const AS_CAPTURE_SOURCE = {
  DESKTOP_SOURCE: 0,
  UAC_SOURCE: 1,
};
export const MEDIA_COMMAND = {
  SHARE_REMOTE_CONTROL_UAC_MOUSE: 144,
  SHARE_REMOTE_CONTROL_UAC_JPEG_FRAME: 145,
};

export const RENDER_UNSET = -1;
export const RENDER_IN_WORKER = 0;
export const RENDER_IN_MAIN = 1;

export const WEBRTC_NO_AUDIO_MODE = 0;
export const WEBRTC_COMMPUTER_AUDIO_MODE = 1;
export const WEBRTC_SHARE_AUDIO_MODE = 2;
export const WEBRTC_MULTI_AUDIO_MODE =
  WEBRTC_COMMPUTER_AUDIO_MODE + WEBRTC_SHARE_AUDIO_MODE;

export const VIDEO_FRAME = 0;
export const SHARING_FRAME = 1;

export const MAX_RENDER_WITHOUT_SAB = 25;
export const ACTIVE_SPEAKER_SSRC = 1;

export const FACE_MODE_UNKNOW = -1;
export const FACE_MODE_USER = 0;
export const FACE_MODE_ENVIRONMENT = 1;
export const ORIGINAL_SOUND_OFF = 0b00;
export const ORIGINAL_SOUND_ON = 0b01;
export const ORIGINAL_SOUND_STEREO = 0b10 | ORIGINAL_SOUND_ON;
export const ORIGINAL_SOUND_HIGHFIDELITY = 0b100 | ORIGINAL_SOUND_ON;
export const ORIGINAL_SOUND_HIGHFIDELITY_STEREO =
  ORIGINAL_SOUND_STEREO | ORIGINAL_SOUND_HIGHFIDELITY;
export const SHARE_AUDIO = 0b1000;
export const ORIGINAL_SOUND_OFF_HIGH_BITRATE = 0b10000;

//define comes from audio bridge
export const PUBLISHER_ICEConnectionState_Failed = 3;
export const SUBSCRIBER_ICEConnectionState_Failed = 107;

export const NO_MESSAGE_FAILOVER = '100';
export const WS_ERROR_FAILOVER = '101';
export const WS_CLOSE_FAILOVER = '102';
export const VB_CONSTANT = `WCL_M,isWebEnabled:1, isLocalEnabled:1, isSmartBkgnd:1, bkgType:2`;
export const AUDIO_ENCODE_WORKER = 0;
export const AUDIO_DECODE_WORKER = 1;
export const AUDIO_WASM_WORKLET = 2;
export const AUDIO_WEBRTC_WORKLET = 3;
export const DATACHANNEL_MONITOR_SEPARATOR = '{[WLCCONT]}';

export const LOADEDMETADATAT_IMEOUT = 10 * 1000;
export const MEDIA_SOLUTION_WEBRTC = 1;
export const MEDIA_SOLUTION_WASM = 2;

export const AudioProfile = {
  [ORIGINAL_SOUND_OFF]: new Map([
    ['useinbandfec', { value: 1, operater: 'add' }],
    ['maxaveragebitrate', { value: 48000, operater: 'add' }],
    ['maxplaybackrate', { value: 24000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 24000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'sub' }],
    ['stereo', { value: 1, operater: 'sub' }],
  ]),
  [ORIGINAL_SOUND_ON]: new Map([
    ['useinbandfec', { value: 1, operater: 'sub' }],
    ['maxaveragebitrate', { value: 96000, operater: 'add' }],
    ['maxplaybackrate', { value: 48000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 48000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'add' }],
    ['stereo', { value: 1, operater: 'add' }],
  ]),
  [ORIGINAL_SOUND_STEREO]: new Map([
    ['useinbandfec', { value: 1, operater: 'sub' }],
    ['maxaveragebitrate', { value: 96000, operater: 'add' }],
    ['maxplaybackrate', { value: 48000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 48000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'add' }],
    ['stereo', { value: 1, operater: 'add' }],
  ]),
  [ORIGINAL_SOUND_HIGHFIDELITY]: new Map([
    ['useinbandfec', { value: 1, operater: 'sub' }],
    ['maxaveragebitrate', { value: 128000, operater: 'add' }],
    ['maxplaybackrate', { value: 48000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 48000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'add' }],
    ['stereo', { value: 1, operater: 'add' }],
  ]),
  [ORIGINAL_SOUND_HIGHFIDELITY_STEREO]: new Map([
    ['useinbandfec', { value: 1, operater: 'sub' }],
    ['maxaveragebitrate', { value: 128000, operater: 'add' }],
    ['maxplaybackrate', { value: 48000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 48000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'add' }],
    ['stereo', { value: 1, operater: 'add' }],
  ]),
  [SHARE_AUDIO]: new Map([
    ['useinbandfec', { value: 1, operater: 'sub' }],
    ['maxaveragebitrate', { value: '96000', operater: 'add' }],
    ['maxplaybackrate', { value: '48000', operater: 'add' }],
    ['sprop-maxcapturerate', { value: '48000', operater: 'add' }],
  ]),
  [ORIGINAL_SOUND_OFF_HIGH_BITRATE]: new Map([
    ['useinbandfec', { value: 1, operater: 'add' }],
    ['maxaveragebitrate', { value: 64000, operater: 'add' }],
    ['maxplaybackrate', { value: 24000, operater: 'add' }],
    ['sprop-maxcapturerate', { value: 24000, operater: 'add' }],
    ['sprop-stereo', { value: 1, operater: 'sub' }],
    ['stereo', { value: 1, operater: 'sub' }],
  ]),
};
