import { add_monitor } from '../../worker/common/common';
import * as RenderConst from './RenderConst';

/**
 * It narrows a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.66
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.66
 *
 * @param {*} num a UV coordinate will be narrowed
 * @param {*} digits how many digits after decimal point will be dropped
 * @returns a narrowed UV coordinate
 */
export function narrowUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.floor(num * multiplier) / multiplier;
}

/**
 * It expands a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.67
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.67
 *
 * @param {*} num a UV coordinate will be expanded
 * @param {*} digits how many digits after decimal point will be expanded
 * @returns a narrowed UV coordinate
 */
export function expandUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.ceil(num * multiplier) / multiplier;
}

/**
 * Whether the size of width or height exceeds the max 2D dimension size.
 *
 * @param {*} resMgr GPUResourceManager contains the GPUFeatureHelper
 * @param {*} width width of the texture
 * @param {*} height height of the texture
 * @returns if true, width or height exceeds the max size
 */
export function isDimensionsOverMaxDimension2DSize(resMgr, width, height) {
  if (!resMgr || width < 0 || height < 0) {
    throw new Error(
      `isDimensionsOverMaxDimension2DSize() invalid parameters. res=${resMgr}, width=${width}, height=${height}`
    );
  }

  let isOver = false;
  let maxDimension = 0;
  const featuresHelper = resMgr.acquireGPUFeaturesHelper();
  if (featuresHelper) {
    maxDimension = featuresHelper.queryMaxTextureDimension2D();
    if (maxDimension > 0) {
      isOver = width > maxDimension || height > maxDimension;
    }
  }

  if (isOver) {
    console.log(
      `isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
    );

    add_monitor(
      `WGPU isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
    );
  }

  return isOver;
}

/**
 * Whether the required size of a GPUBuffer exceeds the max GPUBuffer size.
 *
 * @param {*} resMgr GPUResourceManager contains the GPUFeatureHelper
 * @param {*} bufferSize a required buffer size of a GPUBuffer
 * @returns if true, exceeds
 */
export function isBufferSizeOverMaxSize(resMgr, bufferSize) {
  if (!resMgr || bufferSize < 0) {
    throw new Error(
      `isBufferSizeOverMaxSize() invalid parameters. res=${resMgr}, bufferSize=${bufferSize}`
    );
  }

  let isOver = false;
  let maxBufferSize = 0;
  const featuresHelper = resMgr.acquireGPUFeaturesHelper();
  if (featuresHelper) {
    maxBufferSize = featuresHelper.queryMaxBufferSize();
    if (maxBufferSize > 0) {
      isOver = bufferSize > maxBufferSize;
    }
  }

  if (isOver) {
    console.log(
      `isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
    );

    add_monitor(
      `isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
    );
  }

  return isOver;
}

/**
 * Evaluate a cropping rectangle by rotation.
 * If the rotation indicates a portrait frame, exchange the width and height to follow the orientation.
 *
 * @param {*} croppingParams how to crop a rectangle on a texture
 * @param {*} rotation indicates the orientation
 * @returns a cropping rectangle that follows the rotation
 */
export function evalCroppingRect(croppingParams, rotation) {
  if (!croppingParams || rotation == null) {
    throw new Error(`evalCroppingRect() invalid parameters!`);
  }

  const isPortraitRotation =
    rotation === RenderConst.ROTATION_CLOCK_90 ||
    rotation === RenderConst.ROTATION_CLOCK_270;

  return isPortraitRotation
    ? {
        top: croppingParams.top,
        left: croppingParams.left,
        width: croppingParams.height,
        height: croppingParams.width,
      }
    : croppingParams;
}

/**
 * Find a max rect as the viewport that follow the cropRect ratio on the rectRect.
 *
 * @param {*} renderRect original rect for rendering
 * @param {*} cropRect crop a rect on the render rect
 * @returns a rect as viewport
 */
export function calculateRenderRect(renderRect, cropRect) {
  let _width = 0;
  let _height = 0;
  let _x = 0;
  let _y = 0;

  // Calculate the aspect ratio of the desired region
  const desiredAspectRatio = cropRect.width / cropRect.height;
  const canvasAspectRatio = renderRect.width / renderRect.height;

  if (canvasAspectRatio > desiredAspectRatio) {
    // Canvas is wider than the desired aspect ratio
    _height = renderRect.height;
    _width = _height * desiredAspectRatio;
    _x = (renderRect.width - _width) / 2;
    _y = 0;
  } else {
    // Canvas is taller or equal aspect ratio compared to the desired aspect ratio
    _width = renderRect.width;
    _height = _width / desiredAspectRatio;
    _x = 0;
    _y = (renderRect.height - _height) / 2;
  }

  if (_width <= renderRect.canvas.width) {
    _x = (renderRect.canvas.width - _width) / 2;
  }

  if (_height <= renderRect.canvas.height) {
    _y = (renderRect.canvas.height - _height) / 2;
  }

  return {
    x: _x,
    y: _y,
    width: _width,
    height: _height,
  };
}

/**
 * Calculate the UV coordinates.
 *
 * @param {*} renderRect final viewport where to draw a frame on the canvas
 * @param {*} textureRect rect of texture which will be rendered at the renderRect
 * @param {*} cropRect how to crop a rect on the textureRect
 * @param {*} canvasRect canvas rect, render the texture on it
 * @param {*} rotation indicates the orientation of texture/cropping
 * @returns a set of UV coordinates
 */
export function calculateUVCoord(
  renderRect,
  textureRect,
  cropRect,
  canvasRect,
  rotation
) {
  if (!renderRect || !textureRect || !cropRect || !canvasRect) {
    return null;
  }

  const croppingRatio = cropRect.width / cropRect.height;
  let croppingWidth = cropRect.width;
  let croppingHeight = cropRect.height;
  if (
    cropRect.width > textureRect.width ||
    cropRect.height > textureRect.height
  ) {
    const widthScale = textureRect.width / cropRect.width;
    const heightScale = textureRect.height / cropRect.height;
    const scale = Math.min(widthScale, heightScale);
    croppingWidth *= scale;
    croppingHeight *= scale;
  }

  let maxWidth = 0;
  let maxHeight = 0;
  if (textureRect.width / textureRect.height > croppingRatio) {
    maxHeight =
      Math.floor(textureRect.height / croppingHeight) * croppingHeight;
    maxWidth =
      Math.floor((maxHeight * croppingRatio) / croppingWidth) * croppingWidth;

    if (maxWidth > textureRect.width) {
      maxWidth = Math.floor(textureRect.width / croppingWidth) * croppingWidth;
      maxHeight =
        Math.floor(maxWidth / croppingRatio / croppingHeight) * croppingHeight;
    }
  } else {
    maxWidth = Math.floor(textureRect.width / croppingWidth) * croppingWidth;
    maxHeight =
      Math.floor(maxWidth / croppingRatio / croppingHeight) * croppingHeight;

    if (maxHeight > textureRect.height) {
      maxHeight =
        Math.floor(textureRect.height / croppingHeight) * croppingHeight;
      maxWidth =
        Math.floor((maxHeight * croppingRatio) / croppingWidth) * croppingWidth;
    }
  }

  let top = 0;
  let left = 0;
  let right = 0;
  let bottom = 0;

  if (rotation == RenderConst.ROTATION_CLOCK_0) {
    // flip U(x)
    top = 1 - (top + (croppingHeight - 1) / textureRect.height);
    left = cropRect.left / textureRect.width;
    bottom = 1 - cropRect.top / textureRect.height;
    right = left + croppingWidth / textureRect.width;
  } else if (rotation == RenderConst.ROTATION_CLOCK_90) {
    // flip V(y)
    left = 1 - (top + (croppingHeight - 1) / textureRect.height);
    right = 1 - cropRect.top / textureRect.height;
    top = cropRect.left / textureRect.width;
    bottom = left + croppingWidth / textureRect.width;
  } else if (rotation == RenderConst.ROTATION_CLOCK_180) {
    top = cropRect.top / textureRect.height;
    left = cropRect.left / textureRect.width;
    bottom = top + (croppingHeight - 1) / textureRect.height;
    right = left + croppingWidth / textureRect.width;
  } else if (rotation == RenderConst.ROTATION_CLOCK_270) {
    left = cropRect.top / textureRect.height;
    right = top + (croppingHeight - 1) / textureRect.height;
    top = cropRect.left / textureRect.width;
    bottom = left + croppingWidth / textureRect.width;
  }

  let uvCoords = [];
  let texPosInfo = [
    { x: right, y: bottom },
    { x: right, y: top },
    { x: left, y: top },
    { x: right, y: bottom },
    { x: left, y: bottom },
    { x: left, y: top },
  ];

  for (let i = 0; i < texPosInfo.length; ++i) {
    let uvCoord = { u: texPosInfo[i].x, v: texPosInfo[i].y };
    uvCoords.push(uvCoord);
  }

  let flattenUVCoords = [];
  for (let i = 0; i < uvCoords.length; ++i) {
    let uvCoord = uvCoords[i];
    flattenUVCoords.push(uvCoord.u);
    flattenUVCoords.push(uvCoord.v);
  }

  return flattenUVCoords;
}
