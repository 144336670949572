import util, { addUserEventListener } from '../common/util';
import globalTracingLogger from '../common/globalTracingLogger';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import * as jsEvent from '../common/jsEvent';
import PubSub from '../common/pubSub';
import Zoom_Monitor from './Monitor';
import {
  Notify_Sharing_Decode_Thread,
  Notify_Video_Decode_Thread,
} from '../lib/JsMediaEngine';
import { getR10Level } from '../common/audio-level';
import {
  SHARE_AUDIO,
  WEBRTC_COMMPUTER_AUDIO_MODE,
  WEBRTC_MULTI_AUDIO_MODE,
  WEBRTC_NO_AUDIO_MODE,
  WEBRTC_SHARE_AUDIO_MODE,
  ORIGINAL_SOUND_OFF,
  ORIGINAL_SOUND_ON,
  ORIGINAL_SOUND_HIGHFIDELITY,
  ORIGINAL_SOUND_STEREO,
  ORIGINAL_SOUND_HIGHFIDELITY_STEREO,
  ORIGINAL_SOUND_OFF_HIGH_BITRATE,
  PUBLISHER_ICEConnectionState_Failed,
  SUBSCRIBER_ICEConnectionState_Failed,
  NO_MESSAGE_FAILOVER,
  WS_ERROR_FAILOVER,
  WS_CLOSE_FAILOVER,
  AudioProfile,
} from '../worker/common/consts';
import { WebRTCWorkletManager } from './webRTCWorkletManager';
import deviceManager from './DeviceManager';

import { WORKER_TYPE } from '../common/enums/CommonEnums';

const audioBridgeMonitor = (log, e) => Zoom_Monitor.add_monitor('AB' + log);
const shareAudioMask = 0x200;
const isSafari = util.browser.isSafari;
const WEB_RTC_RECONNECT_TIMEOUT = 5000;
const HEARTBEAT_TIMEOUT = 2000;

const audioRecvQualityMonitor = (() => {
  let monitorInfoList = [];
  let audioLevelInfo = {};
  let prevTime = Date.now();
  return (monitorInfo) => {
    if (monitorInfo.onlyAudioLevel) {
      if (!audioLevelInfo[monitorInfo.key]) {
        audioLevelInfo[monitorInfo.key] = [];
      }
      audioLevelInfo[monitorInfo.key].push(monitorInfo.audioLevel);
    } else {
      Object.keys(audioLevelInfo).forEach((key) => {
        if (monitorInfo.ssrcMap[key]) {
          monitorInfo.ssrcMap[key].zoomAudioLevel = audioLevelInfo[key];
        }
      });
      audioLevelInfo = {};
      monitorInfoList.push(monitorInfo);
      if (Date.now() - prevTime > 14 * 1000) {
        let log = monitorInfoList.reduce((prev, now, idx) => {
          const { rtt, ssrcMap } = now;
          let ssrcLog = '';
          Object.keys(ssrcMap).forEach((key, index, arr) => {
            const {
              aveAudioLevel,
              audioLevel,
              aveJitterBufferDelay,
              jitter,
              packetsLost,
              packetsReceived,
              bytesReceived,
              zoomAudioLevel,
            } = ssrcMap[key];
            ssrcLog += `${
              rtt === undefined ? '' : rtt
            }|{[SSRCLOG]}|{${key}}|${zoomAudioLevel.join(
              ''
            )}|${audioLevel}|${aveAudioLevel}|${jitter}|${aveJitterBufferDelay}|${packetsLost}|${packetsReceived}|${bytesReceived}`;
            if (
              idx !== monitorInfoList.length - 1 ||
              index !== arr.length - 1
            ) {
              ssrcLog += '#';
            }
          });
          return prev + ssrcLog;
        }, 'WCL_AB,{[DOWNLINK]},');
        prevTime = Date.now();
        log += ',{[END]}';
        jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
          evt: jsEvent.RWG_MONITOR_LOG_EVENT,
          seq: 1,
          body: {
            data: log,
          },
        });
        audioLevelInfo = {};
        monitorInfoList = [];
      }
    }
  };
})();

const audioSendQualityMonitor = (() => {
  let monitorInfoList = [];
  let prevTime = Date.now();
  return (monitorInfo) => {
    monitorInfoList.push(monitorInfo);
    if (Date.now() - prevTime > 14 * 1000) {
      let audioLevelR16Log = '';
      let audioLevelR16LogDenoise = '';
      const log = monitorInfoList.reduce((prev, now, idx) => {
        let ssrcLog = '';
        Object.keys(now).forEach((key, index, arr) => {
          const {
            rtt,
            bytesSentPerSecond,
            packetsSent,
            audioLevel,
            audioLevelDenoise,
          } = now[key];
          audioLevelR16Log += audioLevel || '';
          audioLevelR16LogDenoise += audioLevelDenoise || audioLevel || '';
          ssrcLog =
            ssrcLog +
            `{${key}},${rtt},${bytesSentPerSecond},${packetsSent}${
              index !== arr.length - 1 ? ',' : ''
            }`;
        });

        ssrcLog += '#';
        if (idx === monitorInfoList.length - 1) {
          ssrcLog += `{[SPEECH_R16]},${audioLevelR16Log},${audioLevelR16LogDenoise},{[END]}`;
        }

        return prev + ssrcLog;
      }, 'WCL_AB,{[UPLINK]},');
      prevTime = Date.now();
      jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
        evt: jsEvent.RWG_MONITOR_LOG_EVENT,
        seq: 1,
        body: {
          data: log,
        },
      });
      monitorInfoList = [];
    }
  };
})();

const configuration = {
  bundlePolicy: 'max-bundle',
  rtcpMuxPolicy: 'require',
  sdpSemantics: 'unified-plan',
  iceServers: [],
  iceTransportPolicy: 'all',
};

export default class WebrtcAudioBridge {
  constructor(
    cid,
    wsUrl,
    recvOnly,
    codecDoAVSync,
    useWebRTCOnDesktop,
    workletPath,
    callback,
    onlyCheck
  ) {
    this.callback = callback;
    this.onlyCheck = onlyCheck;
    this.publisherCandidate = [];
    this.subscriberCandidate = [];
    this.retry = 0;
    this.retryPublish = 0;
    this.cid = cid;
    this.wsUrl = wsUrl;
    this.audioPlayerMap = new Map();
    this.isRetrying = false;
    this.recvOnly = !!recvOnly;
    this.audioStream = null;
    this.shareAudioStream = null;
    this.monitorTimer = null;
    this.audioReportCount = 0;
    this.monitorTimerDuration = 1 * 1000;
    this.monitorInfo = {
      subscriber: {},
      publisher: {},
    };
    this.muted = false;
    this.audioMuteStatus = new Map();
    this.ssrcUserIdMap = new Map();
    this.published = false;
    this.joinAudioAfterConnect = false;
    this.audioMode = WEBRTC_NO_AUDIO_MODE;
    this.codecDoAVSync = !!codecDoAVSync;
    this.normalAudioMap = new Map();
    this.shareAudioMap = new Map();
    this.audioProfile = ORIGINAL_SOUND_OFF;
    this.syncTimer = null;
    if (workletPath && !this.onlyCheck) {
      this.webRTCWorkletManager = new WebRTCWorkletManager(
        this.setNormalAudioStream.bind(this),
        workletPath
      );
    }
    if (isSafari) {
      this.monitorAudio = new Audio();
      this.shareMonitorAudio = new Audio();
    }
    this.hasPaused = false;
    this.recoverAfter1s = null;

    this.useWebRTCOnDesktop = useWebRTCOnDesktop;
    this.receiveAudioStatus = new Map();
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.playAllRemoteAudio();
      }
    });
    this.isMutedBySystem = false;
    this.leaveTime = 0;
  }

  playAllRemoteAudio() {
    if (this.isMutedBySystem) return;
    if (!this.hasPaused || !this.audioMode) return;

    let recoverList = Array.from(this.audioPlayerMap.values()).map(
      (audioPlayer) => {
        if (audioPlayer.paused) {
          return audioPlayer.play();
        }
      }
    );

    Promise.all(recoverList)
      .then(() => {
        this.hasPaused = false;
      })
      .catch((e) => {
        this.hasPaused = true;
        audioBridgeMonitor('REA');
        this.notifyUIMessage(jsEvent.RECOVER_WEBRTC_AUDIO);
      });
  }

  async reconnect(err) {
    if (this.isRetrying || this.isDestroyed) {
      return;
    }
    this.stopAVSyncTimer();
    this.stopAudioQualityMonitorTimer();
    this.destroySocketAndWebRtcConnect(true);
    if (this.retry >= 3) {
      this.isRetrying = false;
      globalTracingLogger.error('Audio Bridge failed to reconnect', err);
      this.notifyUIMessage(jsEvent.WCL_SIP_WEBSOCKET_CONNECT_ERROR);
      return;
    }
    this.isRetrying = true;
    await util.sleep(3000);
    if (this.isDestroyed) return;
    audioBridgeMonitor('JOIN' + this.retry);
    this.retry++;
    this.notifyUIMessage(jsEvent.WCL_AUDIO_BRIDGE_RECONNECT_START);
    await this.join(true);
    this.isRetrying = false;
  }

  async join(isRetry, abToken, audioMode = WEBRTC_NO_AUDIO_MODE) {
    //make sure user clicks
    this.startAudioQualityMonitorTimer();
    if (audioMode === WEBRTC_COMMPUTER_AUDIO_MODE) {
      try {
        if (!this.cleanUserEventListener) {
          this.cleanUserEventListener = addUserEventListener(
            this.playAllRemoteAudio.bind(this)
          );
        }
        this.webRTCWorkletManager?.createWebRTCWorklet(this.workletPath);
        this.webRTCWorkletManager?.changeAudioStatus(false, false);
        this.webRTCWorkletManager?.startCheckProcess();
      } catch (e) {
        globalTracingLogger.error('Error when creating webRTCWorklet', e);
      }
    }
    this.audioMode = this.audioMode | audioMode;
    if (this.signal) {
      if (audioMode) {
        this.startAVSyncTimer();
        if (this.signal.socket?.readyState === WebSocket.OPEN)
          this.signal.notify('audiostatus', { status: this.audioMode });
      }
      if (audioMode === WEBRTC_COMMPUTER_AUDIO_MODE) {
        this.unmuteAllRemoteAudio();
      }
      this.startAudioQualityMonitorTimer();
      return;
    }
    //if websocket is reconnecting when join audio, set join audio after retry success
    if (!isRetry) {
      if (this.isRetrying) {
        this.destroySocketAndWebRtcConnect(isRetry);
        return;
      }
    }
    this.destroySocketAndWebRtcConnect(isRetry);
    this.signal = new JsonRPCSignal();
    if (this.audioMode) this.startAVSyncTimer();

    try {
      let token;
      if (abToken) {
        token = abToken;
      } else {
        audioBridgeMonitor('RTK');
        jsMediaEngineVariables.sendMessageToRwg(
          jsEvent.REQUEST_AUDIO_BRIDGE_TOKEN,
          { evt: jsEvent.WS_CONF_AB_TOKEN_REQ },
          false
        );
        token = await this.waitForTokenFromRWG(
          jsEvent.PUBSUB_EVT.AUDIO_BRIDGE_WS_TOKEN
        );
        audioBridgeMonitor('TK');
      }
      this.signal.init(
        `${this.wsUrl}&token=${token}&${this.onlyCheck ? 'prob=1' : 'prob=0'}`
      );
    } catch (e) {
      this.reconnect(e);
    }

    this.signal.onopen(async (evt) => {
      if (this.audioMode) {
        this.signal.notify('audiostatus', { status: this.audioMode });
        this.startAudioQualityMonitorTimer();
      }
      this.isOpen = true;
      this.retryPublish = 0;
      this.clearAudioPlayerMap();
      await this.publish();
      this.retry = 0;
      if (this.audioMode) {
        this.notifyUIMessage(jsEvent.WCL_AUDIO_BRIDGE_RECONNECT_END);
      }
    });

    this.signal.onclose((e) => {
      let needReconnect =
        !this.audioMode && Date.now() - this.leaveTime >= 15 * 1000;
      if (needReconnect) {
        this.signal.destroy();
        this.signal = null;
        this.reconnect(e);
      } else {
        if (jsMediaEngineVariables.Notify_APPUI) {
          globalTracingLogger.error(
            'socket close, notify UI failover: ' + WS_CLOSE_FAILOVER
          );
          this.notifyUIMessage(jsEvent.NOTIFY_UI_FAILOVER, WS_CLOSE_FAILOVER);
        }
      }
    });

    this.signal.onerror((e) => {
      if (!this.isOpen) {
        globalTracingLogger.error('Audio Bridge WebSocket open error');
      }
      let needReconnect =
        !this.audioMode && Date.now() - this.leaveTime >= 15 * 1000;
      if (needReconnect) {
        this.signal.destroy();
        this.signal = null;
        this.reconnect(e);
      } else {
        if (jsMediaEngineVariables.Notify_APPUI) {
          globalTracingLogger.error(
            'socket error, notify UI failover: ' + WS_ERROR_FAILOVER
          );
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.NOTIFY_UI_FAILOVER,
            WS_ERROR_FAILOVER
          );
        }
      }
    });

    this.signal.onmessage((resp) => {
      if (resp.method === 'closing') {
        let reason = resp.params.reason;
        if (reason === PUBLISHER_ICEConnectionState_Failed) {
          reason = '0' + (this.publisher.firstConnected ? '1' : '0');
          jsMediaEngineVariables.peerConnectionCannotConnectTimes++;
        } else if (reason === SUBSCRIBER_ICEConnectionState_Failed) {
          reason = '1' + (this.subscriber.firstConnected ? '1' : '0');
          jsMediaEngineVariables.peerConnectionCannotConnectTimes++;
        }
        audioBridgeMonitor('closing: ' + reason);
        globalTracingLogger.error(
          'received closing from audioBridge, notify UI failover: ' + reason
        );
        //ZCC dont refresh page, this variable can't refresh
        if (jsMediaEngineVariables.peerConnectionCannotConnectTimes <= 5) {
          this.notifyUIMessage(jsEvent.NOTIFY_UI_FAILOVER, reason);
        } else {
          globalTracingLogger.error(
            'peerConnection cannot connect to audioBridge over 5 times'
          );
        }

        this.callback(
          'updateConnectionResult',
          reason !== '00' && reason !== '10'
        );
        this.callback('destroy');
        return true;
      }
      return false;
    });

    this.signal.notifyUImessage(this.notifyUIMessage.bind(this));

    this.signal.on_notify('command', (params) => {
      const { data } = params || {};
      if (data) {
        try {
          const res = JSON.parse(data);
          const { evt } = res || {};
          if (evt === jsEvent.WS_CONF_END_INDICATION) {
            this.destroy(false);
          }
        } catch (e) {}
      }
    });
    this.signal.UpdateNTP = (baseNTP, baseRTP, ssrc, abssrc) => {
      if (this.useWebRTCOnDesktop && this.codecDoAVSync) {
        let message = new ArrayBuffer(16);
        let NTPStr = baseNTP.toString(2);
        let high32bit = parseInt(NTPStr.substring(0, NTPStr.length - 32), 2);
        let low32bit = parseInt(
          NTPStr.substring(NTPStr.length - 32, NTPStr.length),
          2
        );

        let dataView = new DataView(message);

        dataView.setUint32(0, ssrc, true);
        //little endian
        dataView.setUint32(4, low32bit, true);
        dataView.setUint32(8, high32bit, true);

        dataView.setUint32(12, Date.now(), true);

        let data = new Uint8Array(message);

        Notify_Video_Decode_Thread({
          command: 'audioDecodeTime',
          status: 1,
          data: data,
        });
      } else {
        if (ssrc & shareAudioMask) {
          this.shareAudioMap.set(ssrc >> 10, {
            ntptime: baseNTP,
            rtptime: baseRTP,
            abssrc: abssrc,
          });
        } else {
          this.normalAudioMap.set(ssrc >> 10, {
            ntptime: baseNTP,
            rtptime: baseRTP,
            abssrc: abssrc,
          });
        }
      }
      return;
    };

    if (this.publisher) this.removeAudioSender();

    this.publisher = new RTCPeerConnection(configuration);
    this.subscriber = new RTCPeerConnection(configuration);

    this.normalAudioSender = null;
    this.shareAudioSender = null;

    this.publisher.addTransceiver('audio', { direction: 'sendrecv' });
    this.publisher.addTransceiver('audio', { direction: 'sendrecv' });

    this.signal.onOffer = async (offer) => {
      audioBridgeMonitor('RERSDP');
      this.updateSsrcUserIdMap(offer);
      await this.subscriber.setRemoteDescription(offer);
      this.subscriberCandidate.forEach((candidate) =>
        this.subscriber.addIceCandidate(candidate)
      );
      this.subscriberCandidate = [];

      const answer = await this.subscriber.createAnswer();
      answer.sdp = this.updateSdpCodecParameters(
        answer.sdp,
        'opus',
        new Map([['stereo', { value: 1, operater: 'add' }]])
      );
      await this.subscriber.setLocalDescription(answer);
      audioBridgeMonitor('RELSDP');
      await this.rpc('answer', { desc: answer });
    };

    this.signal.onTrickle = async (candidate, role) => {
      if (role == 0) {
        if (this.subscriber.remoteDescription != null) {
          audioBridgeMonitor('RERICE');
          this.subscriber.addIceCandidate(candidate);
        } else {
          audioBridgeMonitor('RERICEF');
          this.subscriberCandidate.push(candidate);
        }
      } else if (role == 1) {
        if (this.publisher.remoteDescription != null) {
          audioBridgeMonitor('SERICE');
          this.publisher.addIceCandidate(candidate);
        } else {
          audioBridgeMonitor('SERICEF');
          this.publisherCandidate.push(candidate);
        }
      } else {
        globalTracingLogger.error(
          `Audio Bridge onTrickle error: role: ${role}, candidate: ${candidate}`
        );
      }
    };

    this.publisher.onicecandidate = ({ candidate }) => {
      audioBridgeMonitor('SELICE');
      this.signal.notify('trickle', { candidate: candidate, role: 1 });
    };

    this.publisher.onconnectionstatechange = () => {
      audioBridgeMonitor('PCSC:' + this.publisher.connectionState);
      if (this.publisher) {
        if (this.publisher.connectionState === 'disconnected') {
          setTimeout(() => {
            if (
              this.publisher &&
              this.publisher.connectionState === 'disconnected'
            ) {
              this.publish(true);
            }
          }, WEB_RTC_RECONNECT_TIMEOUT);
        } else if (this.publisher.connectionState === 'connected') {
          jsMediaEngineVariables.peerConnectionCannotConnectTimes = 0;
          if (this.changeSDPAfterConnect) {
            this.changeSDPAfterConnect = false;
            this.resetOfferandAnswer();
          }
          if (!this.publisher.firstConnected) {
            this.publisher.firstConnected = true;
            this.notifyUIMessage(jsEvent.AUDIO_BRIDGE_CAN_SEND_DATA);
            if (this.subscriber.firstConnected) {
              this.callback('updateConnectionResult', true);
            }
          }
        }
      }
    };

    this.subscriber.onicecandidate = ({ candidate }) => {
      audioBridgeMonitor('RELICE');
      this.signal.notify('trickle', { candidate: candidate, role: 0 });
    };

    this.subscriber.ontrack = (event) => {
      audioBridgeMonitor('REVT');
      const stream = event.streams[0];
      const streamId = decodeURIComponent(stream.id);
      const audioId = 'ab-audio-' + streamId;
      event.track.onunmute = () => {
        this.hasPaused = true;
        let audioPlayer = this.audioPlayerMap.get(streamId);
        if (audioPlayer) {
          audioPlayer.srcObject = stream;
        } else {
          audioPlayer = document.createElement('audio');
          audioPlayer.addEventListener('playing', () => {
            audioBridgeMonitor('ASP:' + audioId);
          });

          audioPlayer.addEventListener('pause', () => {
            audioBridgeMonitor('APP:' + audioId);
            // audioPlayer.pause();
            // audioPlayer.removeAttribute('autoplay');
            this.hasPaused = true;
            if (
              document.visibilityState === 'visible' &&
              this.recoverAfter1s === null
            ) {
              this.recoverAfter1s = setTimeout(() => {
                this.recoverAfter1s = null;
                this.playAllRemoteAudio();
              }, 1000);
            }
          });

          audioPlayer.addEventListener('canplay', () => {
            audioBridgeMonitor('ACP:' + audioId);
          });

          audioPlayer.id = audioId;
          audioPlayer.srcObject = stream;
          audioPlayer.autoplay = true;
          audioPlayer.controls = false;
          this.audioPlayerMap.set(streamId, audioPlayer);
          document.documentElement.appendChild(audioPlayer);
          let deviceId = deviceManager.speakerId;
          if (audioPlayer.setSinkId && deviceId) {
            audioPlayer.setSinkId(deviceId).catch((e) => {
              globalTracingLogger.error(
                'Error when setting sink of audio player',
                e
              );
            });
          }
        }
        if (
          document.visibilityState === 'visible' &&
          this.recoverAfter1s === null
        ) {
          this.recoverAfter1s = setTimeout(() => {
            this.recoverAfter1s = null;
            this.playAllRemoteAudio();
          }, 1000);
        }

        this.receiveAudioStatus.forEach((value, key) => {
          this.setShareVolumeLevel(
            key / 10,
            value.volume,
            value.isFromMainSession,
            false
          );
        });

        if (this.isDestroyed) {
          audioPlayer.muted = true;
        }
        audioBridgeMonitor('REVTP');
      };
      event.track.onmute = () => {
        audioBridgeMonitor('REVTM');
        const audioPlayer = this.audioPlayerMap.get(streamId);
        if (audioPlayer) {
          audioPlayer.remove();
        }
        this.audioPlayerMap.delete(streamId);
      };
    };

    this.subscriber.onconnectionstatechange = () => {
      audioBridgeMonitor('SCSC:' + this.subscriber.connectionState);
      if (this.subscriber?.connectionState === 'connected') {
        jsMediaEngineVariables.peerConnectionCannotConnectTimes = 0;
        if (this.subscriber.firstConnected) return;
        this.subscriber.firstConnected = true;
        this.notifyUIMessage(jsEvent.AUDIO_BRIDGE_FIRST_RECV_DATA);
        if (this.publisher.firstConnected)
          this.callback('updateConnectionResult', true);
      }
    };
  }

  notifyUIMessage(event, data) {
    if (!this.onlyCheck && jsMediaEngineVariables.Notify_APPUI) {
      jsMediaEngineVariables.Notify_APPUI(event, data);
    }
  }

  async enableShareToBO(enable) {
    let res = null;
    try {
      res = await this.rpc('Enable_Share_To_BO', {
        enable,
      });
    } catch (e) {
      globalTracingLogger.error('Error when enable share to BO', e);
    }
    if (res && res.length == 2) {
      this.notifyUIMessage(jsEvent.AUDIOBRIDGE_EBABLE_SHARE_TO_BO_SUCCESS, {
        enable: res[1].enable,
      });
    } else {
      this.notifyUIMessage(jsEvent.AUDIOBRIDGE_EBABLE_SHARE_TO_BO_FAILURE, {
        enable,
      });
    }
  }

  async enableBroadCastToBO(enable) {
    let res = null;
    try {
      res = await this.rpc('Enable_Broadcast_To_BO', {
        enable,
      });
    } catch (e) {
      globalTracingLogger.error('Error when enable broadcast to bo', e);
    }
    if (res && res.length == 2) {
      this.notifyUIMessage(jsEvent.AUDIOBRIDGE_ENABLE_BROADCAST_TO_BO_SUCCESS, {
        enable: res[1].enable,
      });
    } else {
      this.notifyUIMessage(jsEvent.AUDIOBRIDGE_EBABLE_BROADCAST_TO_BO_FAILURE, {
        enable,
      });
    }
  }

  leaveAudioWithoutDisconnect(audioMode = WEBRTC_NO_AUDIO_MODE) {
    this.audioMode = this.audioMode & ~audioMode;
    if (this.signal) {
      this.signal.notify('audiostatus', { status: this.audioMode });
    }

    if (audioMode === WEBRTC_COMMPUTER_AUDIO_MODE) {
      this.setNormalAudioStream(null);
      this.muteAllRemoteAudio();
      if (this.cleanUserEventListener) {
        this.cleanUserEventListener();
        this.cleanUserEventListener = null;
      }
      this.webRTCWorkletManager?.changeAudioStatus(false, true);
      this.webRTCWorkletManager?.stopCheckProcess();
    } else if (audioMode === WEBRTC_SHARE_AUDIO_MODE) {
      this.setShareAudioStream(null);
    }

    if (this.audioMode === WEBRTC_NO_AUDIO_MODE) {
      this.leaveTime = Date.now();
      this.stopAVSyncTimer();
    }
  }

  async rpc(method, params = []) {
    if (this.isDestroyed) {
      globalTracingLogger.error(
        `audioBridge instance is destroyed, method ${method}`
      );
      return '';
    }
    try {
      if (!this.signal) {
        globalTracingLogger.error(
          `Audio Bridge RPC error: signal is not available,method: ${method}`
        );
        return '';
      }
      return this.signal.call(method, params);
    } catch (err) {
      globalTracingLogger.error(
        `Audio Bridge RPC error: method: ${method}, params: ${params}`,
        err
      );
      return '';
    }
  }

  async publish(retry) {
    if (!this.publisher || !this.isOpen) {
      return;
    }

    if (retry) {
      if (this.retryPublish >= 3) {
        return;
      }
      this.retryPublish++;
    }

    if (!retry) {
      if (this.published) return;
      this.published = true;
    }
    let offer = await this.publisher.createOffer({
      iceRestart: !!retry,
    });

    offer.sdp = this.changeOfferSDP(offer.sdp);

    await this.publisher.setLocalDescription(offer);

    if (!this.uid && !retry) {
      this.uid = uuidv4();
    }
    audioBridgeMonitor('SELSDP');
    const res = await (retry
      ? this.rpc('offer', { desc: this.publisher.localDescription })
      : this.rpc('join', {
          uid: this.uid,
          offer: this.publisher.localDescription,
        }));
    if (res && res.length === 2) {
      const jsep = res[1];
      if (jsep.type === 'answer') {
        audioBridgeMonitor('SERSDP');
        jsep.sdp = this.changeOfferSDP(jsep.sdp);
        this.publisherAnswer = jsep;
        await this.publisher.setRemoteDescription(jsep);

        let transceiver = this.publisher.getTransceivers();
        if (transceiver.length === 1) {
          this.normalAudioSender = transceiver[0].sender;
        } else if (transceiver.length == 2) {
          if (transceiver[0].mid < transceiver[1].mid) {
            this.normalAudioSender = transceiver[0].sender;
            this.shareAudioSender = transceiver[1].sender;
          } else {
            this.normalAudioSender = transceiver[1].sender;
            this.shareAudioSender = transceiver[0].sender;
          }
        }
        this.setNormalAudioStream(this.audioStream);
        this.setShareAudioStream(this.shareAudioStream);

        this.publisherCandidate.forEach((candidate) => {
          this.publisher.addIceCandidate(candidate);
          audioBridgeMonitor('SERICE');
        });
        this.publisherCandidate = [];
      }
    }
  }

  async resetOfferandAnswer() {
    let offer = await this.publisher.createOffer();

    offer.sdp = this.changeOfferSDP(offer.sdp);

    if (this.publisherAnswer) {
      await this.publisher.setLocalDescription(offer);
      this.publisherAnswer.sdp = this.changeOfferSDP(this.publisherAnswer.sdp);
      await this.publisher.setRemoteDescription(this.publisherAnswer);
      this.rpc('Set_AudioProfile', { audioProfile: this.audioProfile });
      return;
    }
    globalTracingLogger.log(
      'publisher answer is null when reset offer and answer'
    );
  }

  waitForTokenFromRWG(pubSubEvent) {
    return new Promise((resolve, reject) => {
      PubSub.on(pubSubEvent, (msg, data) => {
        resolve(data);
      });
    });
  }

  updateSdpCodecParameters(sdp, codec, paramsMap) {
    try {
      let codecPt = [];
      const lines = sdp.split('\r\n');
      const rtpRe = new RegExp(`^a=rtpmap:([0-9]+) ${codec}/`);
      for (let i = 0; i < lines.length; i++) {
        // Match all rtpmap lines with the specified codec.
        let m = lines[i].match(rtpRe);
        if (m) {
          codecPt.push(m[1]);
        }
      }
      if (codecPt.length) {
        for (let i = 0; i < lines.length; i++) {
          let m = codecPt.reduce((prev, curv) => {
            let s = lines[i].match(new RegExp(`^a=fmtp:${curv} (.*)`));
            return s || prev;
          }, null);
          if (m) {
            const fmtpMap = new Map(m[1].split(';').map((p) => p.split('=')));
            paramsMap.forEach((value, key) => {
              if (value.operater === 'add') {
                fmtpMap.set(key, value.value);
              } else if (value.operater === 'sub') {
                fmtpMap.delete(key);
              }
            });
            let fmtStr = lines[i].match(/a=fmtp:(\d+)/)[0] + ' ';
            for (const [key, value] of fmtpMap) {
              fmtStr += `${key}=${value};`;
            }
            // Remove the extra ';' at the end.
            fmtStr = fmtStr.slice(0, fmtStr.length - 1);
            lines[i] = fmtStr;
          }
        }
      }
      return lines.join('\r\n');
    } catch (e) {
      globalTracingLogger.error('Error when updateSdpCodecParameters', e);
      return sdp;
    }
  }

  changeOfferSDP(sdp) {
    try {
      let result = sdp.split('m=');
      let audioLine = 0;
      let midList = [];
      for (let i = 1; i < result.length; i++) {
        if (result[i].indexOf('audio') === 0) {
          audioLine++;
          let mid = result[i].match(/a=mid:(\d+)/);
          if (mid) midList.push(mid[1]);
        }
        result[i] = 'm=' + result[i];
      }
      if (audioLine === 1) {
        sdp = this.updateSdpCodecParameters(
          sdp,
          'opus',
          AudioProfile[this.audioProfile]
        );
        return sdp;
      }
      if (audioLine !== 2 || midList.length != 2) {
        globalTracingLogger.error('Audio line invalid: ' + audioLine);
        globalTracingLogger.error('mid line length invalid: ' + midList.length);
        return sdp;
      }

      let newsdp = '';
      for (let i = 0; i < result.length; i++) {
        if (result[i].startsWith('m=audio')) {
          if (
            result[i].indexOf('mid:' + Math.min(midList[0], midList[1])) != -1
          ) {
            //normal audio sdp

            result[i] = this.updateSdpCodecParameters(
              result[i],
              'opus',
              AudioProfile[this.audioProfile]
            );
            if (result[i].indexOf('cname:Normal') === -1) {
              result[i] = result[i].replace(
                /a=ssrc:(.+) cname:/,
                'a=ssrc:$1 cname:Normal+'
              );
              let match = result[i].match(/a=ssrc:(.+) cname:/);
              if (match && match[1]) {
                this.normalAudioSSRC = match[1];
              }
            }
          } else if (
            result[i].indexOf('mid:' + Math.min(midList[0], midList[1]))
          ) {
            //share audio sdp
            result[i] = this.updateSdpCodecParameters(
              result[i],
              'opus',
              AudioProfile[SHARE_AUDIO]
            );
            if (result[i].indexOf('cname:Share') === -1) {
              result[i] = result[i].replace(
                /a=ssrc:(.+) cname:/,
                'a=ssrc:$1 cname:Share+'
              );
            }
          }
        }
        newsdp += result[i];
      }
      return newsdp;
    } catch (e) {
      globalTracingLogger.error('Error when changeOfferSDP', e);
      return sdp;
    }
  }

  /** allow to talk or start to talk when join meeting from preview page */
  setRecvOnly(bool, audioStream) {
    this.recvOnly = !!bool;
    if (this.recvOnly) {
      this.setNormalAudioStream(null);
    } else {
      if (audioStream) {
        this.setNormalAudioStream(audioStream);
      }
    }
  }

  setCodecDoAVSync(codecDoAVSync) {
    this.codecDoAVSync = codecDoAVSync;
  }

  setNormalAudioStream(stream, publishDirectly = false) {
    if (!publishDirectly) {
      if (this.recvOnly) {
        this.audioStream = null;
      } else {
        this.audioStream = stream;
      }
      if (this.webRTCWorkletManager)
        this.webRTCWorkletManager.setAudioStream(this.audioStream);
    }

    let publishStream = stream;
    if (this.webRTCWorkletManager) {
      publishStream = this.webRTCWorkletManager.getAudioStream();
    }
    this.publishAudioStream(
      publishStream,
      this.monitorAudio,
      this.normalAudioSender,
      'PAT'
    );
  }

  //dont need replaceFakeAudio
  setShareAudioStream(stream) {
    this.shareAudioStream = stream;

    this.publishAudioStream(
      this.shareAudioStream,
      this.shareMonitorAudio,
      this.shareAudioSender,
      'PAST'
    );
  }

  publishAudioStream(audioStream, monitorAudioTag, sender, logHead) {
    if (this.isDestroyed) return;
    try {
      if (monitorAudioTag && audioStream) {
        monitorAudioTag.srcObject = audioStream;
        monitorAudioTag.muted = true;
        if (monitorAudioTag.paused) {
          monitorAudioTag
            .play()
            .catch((e) =>
              globalTracingLogger.warn('AB monitor audio play error', e)
            );
        }
      }
    } catch (e) {
      globalTracingLogger.error('error publishing audio stream', e);
    }

    if (sender) {
      if (!audioStream) {
        sender.replaceTrack(null);
        audioBridgeMonitor(`${logHead}-null`);
      } else {
        audioStream.getAudioTracks().forEach((track) => {
          globalTracingLogger.log('publish track label: ' + track.label);
          audioBridgeMonitor(`${logHead}-${track.id}`);
          sender.replaceTrack(track).catch((error) => {
            audioBridgeMonitor(`${logHead}-Failed-${track.id}`);
            globalTracingLogger.error('error publishing audio stream', error);
          });
        });
      }
    } else {
      audioBridgeMonitor(logHead + 'SE ');
    }
  }

  mute() {
    if (this.audioStream) {
      audioBridgeMonitor('SEVTP');
      this.muted = true;
      this.audioStream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
      this.webRTCWorkletManager?.changeAudioStatus(true, false);
    }
  }

  unmute() {
    if (this.audioStream) {
      audioBridgeMonitor('SEVTM');
      this.muted = false;
      this.audioStream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
      this.webRTCWorkletManager?.changeAudioStatus(false, false);
    }
  }

  stopIncomingAudio(enable) {
    if (enable) {
      this.muteAllRemoteAudio();
    } else {
      this.unmuteAllRemoteAudio();
    }
  }

  muteAllRemoteAudio() {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.muted = true;
    }
  }

  unmuteAllRemoteAudio() {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.muted = false;
    }
    this.playAllRemoteAudio();
  }
  /** local set others' audio volume */
  setSpeechVolumeLevel(userId, volume) {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      if (streamId.indexOf('OS') !== -1) continue;
      const id = Number(streamId.split('+')[0] || '0');
      const ssrc = Number.isNaN(id) || !id ? null : (id >> 10) << 10;
      if (ssrc && ssrc === (userId >> 10) << 10) {
        const level = Math.min(Math.max(0, volume), 100) / 100;
        audioPlayer.volume = level;
        break;
      }
    }
  }

  async changeSpeaker(speaker) {
    let success = true;
    let reason = '';
    let start = Date.now();
    let promiseArr = [];
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      if (!audioPlayer.setSinkId) {
        success = false;
        reason = 'audioplayer.setSinkId is not supported on your device';
        break;
      }
      let promiseRes = audioPlayer.setSinkId(speaker);
      promiseArr.push(promiseRes);
    }
    try {
      await Promise.all(promiseArr);
    } catch (e) {
      success = false;
      reason = 'Error when setting sink of audio player: ' + e.message;
      globalTracingLogger.error('Error when setting sink of audio player', e);
    }
    if (success) {
      this.notifyUIMessage(
        jsEvent.AUDIO_SPEAKER_SET_SUCCESS,
        speaker || 'default'
      );
    } else {
      this.notifyUIMessage(jsEvent.AUDIO_SPEAKER_SET_ERROR, reason);
    }
    deviceManager.updateSelectedSpeakerDevices(
      speaker,
      Date.now() - start,
      success
    );
  }

  setShareVolumeLevel(userId, volume, isFromMainSession, updateMap = true) {
    if (updateMap) {
      this.receiveAudioStatus.set(userId * 10 + (isFromMainSession ? 1 : 0), {
        volume,
        isFromMainSession,
      });
    }
    let regex = isFromMainSession ? /(\d+)\+OS/ : /(\d+)\+CS/;
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      let matchRes = streamId.match(regex);
      if (
        matchRes &&
        matchRes.length >= 2 &&
        matchRes[1] >> 10 === userId &&
        matchRes[1] & shareAudioMask
      ) {
        audioPlayer.muted = !volume;
      }
    }
  }

  async set_CC_lang(lang) {
    let res = null;
    try {
      res = await this.rpc('set_CC_lang', { lang: lang });
    } catch (e) {
      globalTracingLogger.error('error when setting language', e);
    }
    //UI need check result
    if (res && res.length === 2) {
      this.notifyUIMessage(
        jsEvent.AUDIOBRIDGE_SET_CC_LANG_SUCCESS,
        res[1].lang
      );
    } else {
      this.notifyUIMessage(jsEvent.AUDIOBRIDGE_SET_CC_LANG_FAILURE, lang);
    }
  }

  /** store webrtc ssrc -> zoom userId map */
  updateSsrcUserIdMap(offer) {
    if (offer) {
      const { sdp = '' } = offer;
      const matches = sdp.match(/a=ssrc:(.+) cname:(.+)/g);
      if (matches) {
        matches.forEach((item) => {
          const match = item.match(/a=ssrc:(.+) cname:(.+)/);
          if (match && match[1] && match[2]) {
            const id = Number(match[2].split('+')[0] || '0');
            const userId = Number.isNaN(id) || !id ? null : (id >> 10) << 10;
            const newSsrc = Number(match[1]);
            this.ssrcUserIdMap.set(newSsrc, userId);
          }
        });
      }
    }
  }

  /** store user mute/unmute status */
  updateUserMuteUnmuteStatus(data) {
    const { update, remove } = data;
    if (update && update.length > 0) {
      update.forEach((user) => {
        const { userId, muted } = user;
        if (userId) {
          this.audioMuteStatus.set((userId >> 10) << 10, !!muted);
        }
      });
    }
    if (remove && remove.length > 0) {
      remove.forEach((user) => {
        const { userId } = user;
        if (userId) {
          this.audioMuteStatus.set((userId >> 10) << 10, true);
        }
      });
    }
  }

  /** get target ssrc mute status */
  isUserMuted(ssrc) {
    const userId = this.ssrcUserIdMap.get(ssrc);
    if (!userId) return false;
    const muted = this.audioMuteStatus.get(userId);
    if (muted === undefined) return false;
    return !!muted;
  }

  async setAudioProfile(profile) {
    if (
      this.audioMode !== WEBRTC_COMMPUTER_AUDIO_MODE &&
      this.audioMode != WEBRTC_MULTI_AUDIO_MODE
    )
      return;
    let currentAudioProfile = this.audioProfile;
    if (profile.currentSelect === 'backgroundNoiseSuppression') {
      if (profile.highBitrate) {
        this.audioProfile = ORIGINAL_SOUND_OFF_HIGH_BITRATE;
      } else {
        this.audioProfile = ORIGINAL_SOUND_OFF;
      }
      deviceManager.changeDenoiseSwitch(
        profile.backgroundNoiseSuppression === 'Zoom'
      );
    } else {
      deviceManager.changeDenoiseSwitch(false);
      if (profile.originalSound.highfidelity && profile.originalSound.stereo) {
        this.audioProfile = ORIGINAL_SOUND_HIGHFIDELITY_STEREO;
      } else if (profile.originalSound.highfidelity) {
        this.audioProfile = ORIGINAL_SOUND_HIGHFIDELITY;
      } else if (profile.originalSound.stereo) {
        this.audioProfile = ORIGINAL_SOUND_STEREO;
      } else {
        this.audioProfile = ORIGINAL_SOUND_ON;
      }
    }
    if (currentAudioProfile !== this.audioProfile) {
      //update sdp, republish
      if (!this.publisher || this.publisher.connectionState !== 'connected') {
        this.changeSDPAfterConnect = true;
      } else {
        await this.resetOfferandAnswer();
      }
    }
  }

  clearAudioPlayerMap() {
    this.muteAllRemoteAudio();
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.remove();
    }
    this.audioPlayerMap.clear();
    this.audioMuteStatus.clear();
  }

  removeAudioSender() {
    if (this.normalAudioSender) {
      this.publisher.removeTrack(this.normalAudioSender);
      this.normalAudioSender = null;
    }
    if (this.shareAudioSender) {
      this.publisher.removeTrack(this.shareAudioSender);
      this.shareAudioSender = null;
    }
  }

  destroySocketAndWebRtcConnect(isRetry) {
    this.published = false;

    // should replace track here
    try {
      this.removeAudioSender();
    } catch (e) {}

    if (this.publisher) {
      this.publisher.close();
      this.publisher = null;
    }
    if (this.subscriber) {
      this.subscriber.close();
      this.subscriber = null;
    }

    this.isOpen = false;
    if (!isRetry) {
      this.retry = 0;
    }
    this.retryPublish = 0;

    if (this.signal) {
      this.signal.destroy();
      this.signal = null;
    }
    this.ssrcUserIdMap.clear();
  }

  destroy() {
    audioBridgeMonitor('DS');
    this.isDestroyed = true;
    if (this.cleanUserEventListener) {
      this.cleanUserEventListener();
      this.cleanUserEventListener = null;
    }
    this.stopAudioQualityMonitorTimer();
    this.stopAVSyncTimer();

    if (this.webRTCWorkletManager) {
      this.webRTCWorkletManager.destroy();
      this.webRTCWorkletManager = null;
    }
    /** replace send stream with fake audio stream */
    this.setNormalAudioStream(null);
    this.setShareAudioStream(null);
    this.clearAudioPlayerMap();
    this.destroySocketAndWebRtcConnect();

    this.recvOnly = false;
  }

  //computer audio and desktop audio has two media-source
  //need consider audio level log
  startAudioQualityMonitorTimer() {
    this.audioReportCount = 0;
    this.stopAudioQualityMonitorTimer();
    this.monitorTimer = setInterval(() => {
      let onlyAudioLevel = this.audioReportCount % 5;
      this._publisherStatsParse(onlyAudioLevel);
      this._subscriberStatsParse(onlyAudioLevel);
      this.audioReportCount++;
    }, this.monitorTimerDuration);
  }

  stopAudioQualityMonitorTimer() {
    if (this.monitorTimer) {
      clearInterval(this.monitorTimer);
      this.monitorTimer = null;
      this.audioReportCount = 0;
      this.monitorInfo = {
        subscriber: {},
        publisher: {},
      };
    }
  }

  publishStream(stream, shareStream = false) {
    if (shareStream) {
      this.setShareAudioStream(stream);
    } else {
      this.setNormalAudioStream(stream);
    }
    return new Promise((resolve, reject) => {
      if (this.published) {
        resolve(true);
      } else {
        this.publish().then(() => {
          resolve(false);
        });
      }
    });
  }

  stopAVSyncTimer() {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
      this.syncTimer = null;
    }
  }

  syncSingleView(shareAudio, playedNTPTime) {
    let realNtp = 0;
    if (playedNTPTime) {
      let result = parseInt(playedNTPTime).toString(2);
      let result1 = result.substring(0, result.length - 32);
      let result2 = result.substring(result.length - 32, result.length);
      result1 = parseInt(result1, 2);
      result2 = parseInt(result2, 2);
      realNtp = result1 * 1000 + (result2 * 232.8) / 1000000000;
    }
    //0 indicate render in worker
    if (jsMediaEngineVariables.mediaSDKHandle.RenderInMain == 0) {
      if (shareAudio) {
        Notify_Sharing_Decode_Thread({
          command: 'audioTimestamp',
          data: realNtp,
        });
      } else {
        Notify_Video_Decode_Thread({
          command: 'audioDecodeTime',
          status: 0,
          data: realNtp,
        });
      }
    } else {
      //set data in main worker
      if (shareAudio) {
        if (jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj)
          jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.SetcATimeStamp(
            realNtp
          );
      } else {
        //video render in main thread
        if (jsMediaEngineVariables.CurrentSSRCTime != realNtp) {
          jsMediaEngineVariables.CurrentSSRCTime = realNtp;
          jsMediaEngineVariables.audioPlayTime = Date.now();
        }
      }
    }
  }

  startAVSyncTimer() {
    this.stopAVSyncTimer();
    this.syncTimer = setInterval(() => {
      if (!this.subscriber) return;
      let audioReportCount = 0;
      let receivers = this.subscriber.getReceivers();
      receivers.forEach((receiver) => {
        let reports = receiver.getSynchronizationSources();
        reports.forEach((data) => {
          audioReportCount++;
          let userId = this.ssrcUserIdMap.get(data.source);
          let baseNTP_RTP = null;
          let isShareAudio = false;

          baseNTP_RTP = this.shareAudioMap.get(userId >> 10);
          if (baseNTP_RTP && baseNTP_RTP.abssrc === data.source) {
            isShareAudio = true;
          } else {
            baseNTP_RTP = this.normalAudioMap.get(userId >> 10);
          }
          if (baseNTP_RTP) {
            let baseNTP = baseNTP_RTP.ntptime;
            let baseRTP = baseNTP_RTP.rtptime;

            let nextNTP =
              baseNTP +
              ((data.rtpTimestamp - baseRTP) * Math.pow(2, 32)) / 48000;

            if (
              !this.codecDoAVSync &&
              (userId >> 10 == jsMediaEngineVariables.CurrentSSRC >> 10 ||
                isShareAudio)
            ) {
              if (data.source === baseNTP_RTP.abssrc) {
                this.syncSingleView(isShareAudio, nextNTP);
              }
            }
          }
        });
      });
      if (audioReportCount === 0) {
        this.syncSingleView(false, 0);
        this.syncSingleView(true, 0);
      }
    }, 500);
  }

  async _publisherStatsParse(onlyAudioLevel) {
    if (!this.publisher || onlyAudioLevel) return;
    const stats = await this.publisher.getStats();
    if (!stats) return;

    let statInfo = {};
    const { publisher: publisherInfo } = this.monitorInfo;

    stats.forEach((stat) => {
      if (stat.type === 'remote-inbound-rtp') {
        if (stat.roundTripTime) {
          let key =
            stat.ssrc.toString() === this.normalAudioSSRC ? 'NORMAL' : 'SHARE';
          if (!statInfo[key]) {
            statInfo[key] = {};
          }
          statInfo[key]['rtt'] = Math.floor(stat.roundTripTime * 1000);
        }
      } else if (stat.type === 'outbound-rtp') {
        //get bytes sent/packets sent for every ssrc
        this._publisherStatisticsParse(stat, statInfo, publisherInfo);
      }
      //get port info, protcol
      this.getNetworkInfo(stats, stat, publisherInfo, 'publisher');
    });

    if (this.webRTCWorkletManager && statInfo['NORMAL']) {
      const { resLevelR16Log, resLevelR16LogDenoise } =
        this.webRTCWorkletManager.getLevelR16Log();

      statInfo['NORMAL'].audioLevel = resLevelR16Log;
      statInfo['NORMAL'].audioLevelDenoise = resLevelR16LogDenoise;
    }
    audioSendQualityMonitor(statInfo);
  }

  _publisherStatisticsParse(stat, statInfo, publisherInfo) {
    let key =
      stat.ssrc.toString() === this.normalAudioSSRC ? 'NORMAL' : 'SHARE';
    if (!statInfo[key]) {
      statInfo[key] = {};
    }
    const { timestamp, bytesSent, packetsSent } = stat;
    if (!publisherInfo[key]) {
      publisherInfo[key] = {
        lastBytesSent: bytesSent,
        lastTimeStamp: timestamp,
      };
    }
    const { lastTimeStamp, lastBytesSent } = publisherInfo[key];

    statInfo[key].bytesSentPerSecond = Math.floor(
      timestamp - lastTimeStamp
        ? (1000 * (bytesSent - lastBytesSent)) / (timestamp - lastTimeStamp)
        : 0
    );
    statInfo[key].packetsSent = packetsSent;
    Object.assign(publisherInfo[key], {
      lastBytesSent: bytesSent,
      lastTimeStamp: timestamp,
    });
  }

  getNetworkInfo(stats, stat, info, direction) {
    let port = 0;
    let protocol = null;
    if (stat.type === 'candidate-pair') {
      const remoteCandidate = stats.get(stat.remoteCandidateId);
      //for Firefox, which port and protocol is used
      if (stat.selected && remoteCandidate) {
        port = remoteCandidate.port;
        protocol = remoteCandidate.protocol;
      }
    } else if (stat.type === 'transport' && stat.selectedCandidatePairId) {
      //Chrome and Safari, which port and protocol is used
      let candidatePair = stats.get(stat.selectedCandidatePairId);
      const remoteCandidate = stats.get(candidatePair.remoteCandidateId);
      if (remoteCandidate) {
        port = remoteCandidate.port;
        protocol = remoteCandidate.protocol;
      }
    }

    if (port && protocol && (port !== info.port || protocol != info.protocol)) {
      info.port = port;
      info.protocol = protocol;
      globalTracingLogger.directReport(
        `${direction} port:${port}, ${direction} protocol:${protocol}`
      );
    }
  }

  async _subscriberStatsParse(onlyAudioLevel) {
    if (!this.subscriber) return;
    const stats = await this.subscriber.getStats();
    if (!stats) return;
    let statInfo = {
      ssrcMap: {},
    };
    let shouldeSendMonitor = false;
    const { subscriber: subscriberInfo } = this.monitorInfo;
    stats.forEach((stat) => {
      if (!onlyAudioLevel) {
        this._subscriberCandidatePairParse(stat, statInfo);
        shouldeSendMonitor = true;
        this.getNetworkInfo(stats, stat, subscriberInfo, 'subscriber');
      }
      shouldeSendMonitor =
        this._subscriberInboundRTPParse(
          stat,
          statInfo,
          subscriberInfo,
          onlyAudioLevel
        ) || shouldeSendMonitor;
    });
    if (shouldeSendMonitor) {
      audioRecvQualityMonitor(statInfo);
    }
  }

  _subscriberCandidatePairParse(stat, statInfo) {
    const { subscriber: subscriberInfo } = this.monitorInfo;
    const { totalRoundTripTime, responsesReceived, type, state, nominated } =
      stat;
    if (type === 'candidate-pair' && state === 'succeeded' && nominated) {
      if (!subscriberInfo.prevStatInfo) {
        subscriberInfo.prevStatInfo = {
          responsesReceived: responsesReceived,
          totalRoundTripTime: totalRoundTripTime,
        };
        subscriberInfo.ssrcMap = {};
      }
      const { prevStatInfo } = subscriberInfo;
      Object.assign(statInfo, {
        rtt: Math.floor(
          (responsesReceived === prevStatInfo.responsesReceived
            ? 0
            : (totalRoundTripTime - prevStatInfo.totalRoundTripTime) /
              (responsesReceived - prevStatInfo.responsesReceived)) * 1000
        ),
      });

      Object.assign(subscriberInfo.prevStatInfo, {
        totalRoundTripTime,
        responsesReceived,
      });
    }
  }

  _subscriberInboundRTPParse(stat, statInfo, subscriberInfo, onlyAudioLevel) {
    const { type } = stat;
    let shouldeSendMonitor = undefined;
    if (type === 'inbound-rtp') {
      const {
        totalAudioEnergy,
        timestamp,
        jitterBufferDelay,
        jitterBufferEmittedCount,
        ssrc,
        jitter,
        audioLevel,
        packetsLost,
        packetsReceived,
        bytesReceived,
      } = stat;
      const userId = this.ssrcUserIdMap.get(ssrc);
      audioRecvQualityMonitor({
        key: `${ssrc}-${userId}`,
        audioLevel: getR10Level(audioLevel),
        onlyAudioLevel: true,
      });
      if (onlyAudioLevel) {
        return false;
      }
      if (!subscriberInfo.ssrcMap) {
        subscriberInfo.ssrcMap = {};
      }
      let prevInfo = subscriberInfo.ssrcMap[ssrc];
      if (!prevInfo) {
        prevInfo = subscriberInfo.ssrcMap[ssrc] = {};
        shouldeSendMonitor = false;
      } else {
        if (userId) {
          shouldeSendMonitor = true;
          const _aveAudioLevel = Math.sqrt(
            (totalAudioEnergy - prevInfo.totalAudioEnergy) /
              (timestamp - prevInfo.timestamp)
          ).toFixed(4);

          let _audioLevel = -1;
          //there is no audioLevel in stat sometimes
          if (typeof audioLevel === 'number') {
            _audioLevel = audioLevel.toFixed(4);
          }
          const _aveJitterBufferDelay = Math.floor(
            (jitterBufferEmittedCount === prevInfo.jitterBufferEmittedCount
              ? 0
              : (jitterBufferDelay - prevInfo.jitterBufferDelay) /
                (jitterBufferEmittedCount -
                  prevInfo.jitterBufferEmittedCount)) * 1000
          );
          statInfo.ssrcMap[`${ssrc}-${userId}`] = Object.assign(
            statInfo.ssrcMap[`${ssrc}-${userId}`] || {},
            {
              aveAudioLevel: _aveAudioLevel < 0.0001 ? 0 : _aveAudioLevel,
              audioLevel: _audioLevel < 0.0001 ? 0 : _audioLevel,
              aveJitterBufferDelay: _aveJitterBufferDelay,
              jitter,
              packetsLost: packetsLost - prevInfo.packetsLost,
              packetsReceived: Math.floor(
                ((packetsReceived - prevInfo.packetsReceived) /
                  (timestamp - prevInfo.timestamp)) *
                  1000
              ),
              bytesReceived: Math.floor(
                ((bytesReceived - prevInfo.bytesReceived) /
                  (timestamp - prevInfo.timestamp)) *
                  1000
              ),
            }
          );
        }
      }
      if (this.isUserMuted(ssrc) || !this.ssrcUserIdMap.has(ssrc)) {
        subscriberInfo.ssrcMap[ssrc] = null;
      } else {
        Object.assign(prevInfo, {
          totalAudioEnergy,
          timestamp,
          jitterBufferDelay,
          jitterBufferEmittedCount,
          packetsLost,
          packetsReceived,
          bytesReceived,
        });
      }
    }
    return shouldeSendMonitor;
  }

  changeDenoiseSwitch(enable, isHeadSet) {
    this.denoiseSwitch = enable;
    this.isHeadSet = isHeadSet;
    this.webRTCWorkletManager?.changeDenoiseSwitch(enable, isHeadSet);
  }

  updateQos(message) {
    if (this.signal) {
      this.signal.notify('qos', {
        enable: message.enable,
        encoding: parseInt(message.workerType),
        pollingInterval: message.pollingInterval,
      });
    }
  }
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function JsonRPCSignal() {
  this.onOffer;

  this.isDestroyed = false;
  this.heartBeatDetection = null;

  var _notifyhandlers;

  this.onopen = function (f) {
    this._onopen = f;
  };

  this.onclose = function (f) {
    this._onclose = f;
  };

  this.onerror = function (f) {
    this._onerror = f;
  };

  this.onmessage = function (f) {
    this._onmessage = f;
  };

  this.notifyUImessage = function (f) {
    this.notifyUIMessage = f;
  };

  this.init = function (uri) {
    audioBridgeMonitor(`INIT: ${uri}`);
    this.socket = window.audioBridgeSignal = new WebSocket(uri);
    this._notifyhandlers = {};

    this.socket.addEventListener('open', () => {
      audioBridgeMonitor('WSO');
      this.lastMessageTimeStamp = performance.now();
      this.ping();
      if (this.heartBeatDetection) {
        clearTimeout(this.heartBeatDetection);
        this.heartBeatDetection = null;
      }
      this.heartBeatDetection = setTimeout(
        function run() {
          if (this.isDestroyed || this.socket?.readyState !== 1) return;
          let duration = performance.now() - this.lastMessageTimeStamp;
          if (duration > 32 * 1000) {
            audioBridgeMonitor('WST');
            globalTracingLogger.error(
              "didn't reveive message from websocket in the last 30s, notify UI failover, duration:  " +
                duration
            );
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.NOTIFY_UI_FAILOVER,
              NO_MESSAGE_FAILOVER
            );
          } else {
            this.heartBeatDetection = setTimeout(
              run.bind(this),
              HEARTBEAT_TIMEOUT
            );
          }
        }.bind(this),
        HEARTBEAT_TIMEOUT
      );
      if (this._onopen) this._onopen();
    });

    this.socket.addEventListener('error', (e) => {
      if (this.isDestroyed) return;
      globalTracingLogger.directReport('Audio Bridge WebSocket received error');
      audioBridgeMonitor('WSE');

      if (this._onerror) {
        this._onerror(e || true);
      }
    });

    this.socket.addEventListener('close', (e) => {
      if (this.isDestroyed) return;
      globalTracingLogger.directReport('Audio Bridge WebSocket close');
      audioBridgeMonitor('WSC');

      if (this._onclose) {
        this._onclose(e || true);
      }
    });

    this.socket.addEventListener('message', async (event) => {
      this.lastMessageTimeStamp = performance.now();
      const resp = JSON.parse(event.data);
      let hasHandleMessage = false;
      if (this._onmessage) hasHandleMessage = this._onmessage(resp);
      if (hasHandleMessage) return;
      if (resp.method === 'offer') {
        if (this.onOffer) this.onOffer(resp.params);
      } else if (resp.method === 'trickle') {
        if (this.onTrickle)
          this.onTrickle(resp.params.candidate, resp.params.role);
      } else if (resp.method === 'rtcpsr') {
        this.UpdateNTP(
          resp.params.ntptime,
          resp.params.rtptime,
          resp.params.ssrc,
          resp.params.abssrc
        );
      } else if (resp.method === 'qos') {
        let qos = resp.params;
        qos.encoding = qos.encoding === parseInt(WORKER_TYPE.AUDIO_ENCODE);
        qos.sample_rate = 48;
        this.notifyUIMessage(jsEvent.AUDIO_QOS_DATA, qos);
      } else if (resp.result === 'pong') {
        let rtt = parseInt(performance.now()) - resp.id;
        audioBridgeMonitor('RTT: ' + rtt);
      } else {
        const handler = this._notifyhandlers[resp.method];
        if (handler) {
          handler(resp.params);
        }
      }
    });
  };

  this.on_notify = function (method, cb) {
    this._notifyhandlers[method] = cb;
  };

  this.notify = function (method, params, id) {
    if (this.isDestroyed) {
      globalTracingLogger.error(
        `audioBridge instance is destroyed, method ${method}`
      );
      return;
    }
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          method,
          params,
          id,
        })
      );
    } else {
      globalTracingLogger.error(
        `Websocket is not open: ${this.socket.readyState}, ${this.socket?.url}`
      );
      return;
    }
  };

  this.call = async function (method, params) {
    const id = uuidv4();
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          method,
          params,
          id,
        })
      );
    } else {
      globalTracingLogger.error(
        `Websocket is not open: ${this.socket.readyState}, ${this.socket?.url}`
      );
      return;
    }

    return new Promise((resolve, reject) => {
      const handler = (event) => {
        const resp = JSON.parse(event.data);
        if (resp.id === id) {
          if (resp.error) resolve(resp);
          else resolve(resp.result);
          this.socket.removeEventListener('message', handler);
        }
      };
      this.socket.addEventListener('message', handler);
    });
  };

  this.ping = () => {
    this.notify('ping', {}, parseInt(performance.now()));
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
    this.pingTimeout = setTimeout(() => {
      this.ping();
    }, 10000);
  };

  this.destroy = () => {
    this.isDestroyed = true;
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
      this.pingTimeout = null;
    }
    if (this.heartBeatDetection) {
      clearTimeout(this.heartBeatDetection);
      this.heartBeatDetection = null;
    }

    if (this.socket && this.socket.close) {
      this.socket.close(3456, 'close socket when destroy signal');
    }
  };
}
