import WebGPURenderDisplay from './WebGPURenderDisplay';
import * as RenderConst from './RenderConst';

/**
 * RenderDisplayPool is used for creating, recycling and acquiring a render display.
 *
 * Each backend renderer is attached to a single canvas, meanwhile, a render display pool
 * is attached to the single canvas too. It is created for backend WebGPU renderer only,
 * WebGL/WebGL2 renderer has its own render displays container.
 */
class RenderDisplayPool {
  #mMaxSize = 0;
  #mPool = null;
  #mInUsePool = null;
  #mServeFor = RenderConst.SERVE_FOR.AVAILABLE;
  #mGPUResMgr = null;

  constructor(maxSize, serverFor, resMgr) {
    this.#mMaxSize = maxSize;
    this.#mServeFor = serverFor;
    this.#mGPUResMgr = resMgr;
    this.#mPool = [];
    this.#mInUsePool = [];
  }

  /**
   * Initialize a render display pool by creating the number of size render displays.
   * The size should be smaller than the max size.
   *
   * @param {*} initSize size for initializing
   */
  initPool(initSize) {
    if (initSize > this.#mMaxSize) {
      throw new Error(
        `initSize=${initSize} is larger than maxSize=${
          this.#mMaxSize
        }, invalid!`
      );
    }

    if (initSize < 0) {
      throw new Error(`initSize=${initSize} is smaller than 0, invalid!`);
    }

    for (let i = 0; i < initSize; i++) {
      const renderDisplay = new WebGPURenderDisplay(i, this.#mGPUResMgr);
      this.#mPool.push(renderDisplay);
    }
  }

  /**
   * Expand the capacity of render display pool.
   *
   * @param {*} expandSize the number of how many render displays are going to expand
   */
  expandPool(expandSize) {
    if (this.#mPool.length >= this.#mMaxSize) {
      // exceed max size
      return;
    }

    let leftSpace = 0;
    if (this.#mPool.length + expandSize >= this.#mMaxSize) {
      leftSpace = this.#mMaxSize - this.#mPool.length;
    }

    if (leftSpace > 0) {
      const endIdx = this.#mPool.length;
      for (let i = 0; i < leftSpace; i++) {
        const nextIndex = i + endIdx;
        const renderDisplay = new WebGPURenderDisplay(
          nextIndex,
          this.#mGPUResMgr
        );
        this.#mPool.push(renderDisplay);
      }
    }
  }

  /**
   * Pop an available render display.
   *
   * You can set the parameter autoExpand to true to decide to expand pool automatically or not.
   * Once an available render display is popped, it will be pushed to a in-use pool at the same time.
   *
   * @param {*} autoExpand if true, to expand the pool while the pop is empty
   * @returns an available render display or undefined if the pool is empty
   */
  pop(autoExpand = true) {
    if (autoExpand) {
      if (this.isPoolEmpty()) {
        this.expandPool(4);
      }
    }

    const availableRenderDisplay = this.#mPool.pop();
    if (availableRenderDisplay) {
      availableRenderDisplay.markRenderingStatePending();
      this.#mInUsePool.push(availableRenderDisplay);
    }

    return availableRenderDisplay;
  }

  /**
   * Recycle a render display to the pool and make it available again.
   *
   * @param {*} renderDisplay a render display is recycled
   */
  recycle(renderDisplay) {
    if (this.#mPool.length < this.#mMaxSize) {
      // let render display to release resources
      renderDisplay.recycle();

      // recycle it to the pool
      this.#mPool.push(renderDisplay);

      // remove it from the inUsePool
      const index = this.#mInUsePool.indexOf(renderDisplay);
      if (index != -1) {
        this.#mInUsePool.splice(index, 1);
      }
    }
  }

  /**
   * Clear all the render displays in the available pool.
   */
  clear() {
    if (this.#mPool) {
      for (const renderDisplay of this.#mPool) {
        if (renderDisplay) {
          renderDisplay.clear();
        }
      }
      this.#mPool.length = 0;
    }
  }

  /**
   * Cleanup all render displays in in-used and available pools.
   *
   * @param {boolean} [needToRecycle=true] indicates whether need to recycle the GPUBuffers or not
   */
  cleanup(needToRecycle = true) {
    this.#mPool.forEach((element) => {
      element.cleanup(needToRecycle);
    });

    this.#mInUsePool.forEach((element) => {
      element.cleanup(needToRecycle);
    });

    this.#mPool = [];
    this.#mInUsePool = [];
  }

  isPoolEmpty() {
    return this.#mPool.length == 0;
  }

  getInUseRenderDisplays() {
    return this.#mInUsePool;
  }

  getAllRenderDisplays() {
    return this.#mPool;
  }

  isServeForVideoRendering() {
    return this.#mServeFor === RenderConst.SERVE_FOR.VIDEO;
  }

  isServeForShareRendering() {
    return this.#mServeFor === RenderConst.SERVE_FOR.SHARE;
  }

  isServingForNow(serveFor) {
    return this.#mServeFor === serveFor;
  }
}

export default RenderDisplayPool;
