import Zoom_Monitor from './Monitor';
import util from '../common/util';
import globalTracingLogger from '../common/globalTracingLogger';
import { StatisticHelper } from '../common/statistic-helper';

export class ComputePressureManager {
  static isSupportComputePressure = () =>
    typeof PressureObserver === 'function';

  constructor() {
    this.pressureLevel = {
      nominal: 0,
      fair: 1,
      serious: 2,
      critical: 3,
    };
    this.pressureStatisticHelper = new StatisticHelper();
    this.observer = new PressureObserver((changes) => {
      // nominal fair serious critical
      this.result = this.pressureLevel[changes[0].state];
      // CPC: compute pressure change
      Zoom_Monitor.add_monitor('CPC:' + this.result);
      this.pressureStatisticHelper.update(changes[0].state);
    });
  }

  init() {
    this.initPromise = this.observer
      .observe('cpu')
      .then(() => {
        this.pressureStatisticHelper.start('nominal');
      })
      .catch((e) => {
        // Other platforms might not support and throw an error, even if the API is available.
        if (util.isWindows() || util.isMac() || util.isChromeOS()) {
          globalTracingLogger.warn('ComputePressure observe error: ', e);
        }
      })
      .finally(() => {
        this.initPromise = null;
      });
  }

  destroy() {
    if (this.initPromise) {
      this.initPromise.then(() => {
        this.observer.unobserve('cpu');
        const result = this.pressureStatisticHelper.end();
        globalTracingLogger.log(
          `CPU pressure report: ${JSON.stringify(result)}`
        );
      });
    } else {
      this.observer.unobserve('cpu');
      const result = this.pressureStatisticHelper.end();
      globalTracingLogger.log(`CPU pressure report: ${JSON.stringify(result)}`);
    }
  }
}
