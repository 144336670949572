import * as jsEvent from '../common/jsEvent';
import * as consts from '../worker/common/consts';
import * as SdkEvent from '../common/sdkEvent';
import * as RenderConst from '../common/renderer/RenderConst';
import JsMediaSDK_WaterMarkRGBA from './JsMediaSDK_WaterMark';
import { SharingQueueMGR } from './JsMediaBuffer';
import {
  YuvWrap,
  globaltracing_error,
  globaltracing_DT,
} from '../worker/common/common';

function SharingRender(params) {
  this.Notify_APPUI = params.Notify_APPUI;
  this.PubSub = params.PubSub;
  this.jsMediaEngine = params.jsMediaEngine;
  this.globalTracingLogger = params.globalTracingLogger;
  this.renderManager = params.renderManager;

  this.currentshareactive = 0;
  this.isFromMainSession = 0;
  this.sharingWidthAndHeightInfo = {
    logicHeight: 0,
    logicWidth: 0,
  };
  this.currentSharingHeight = 0;
  this.currentSharingWidth = 0;
  this.currentSharingLogicHeight = 0;
  this.currentSharingLogicWidth = 0;
  this.isCreateSharingWaterMark = false;
  this.sharingWaterMarkName = '';
  /** if makes watermark repeated */
  this.isWaterMarkRepeatedEnable = false;
  this.waterMarkOpacity = 0.15;
  this.SharingCanvasSizeInfo = null;
  this.Cursorx = null;
  this.Cursory = null;
  this.CursorWidth = null;
  this.CursorHeight = null;
  this.xratio = 1;
  this.yratio = 1;
  this.sharingDisplay = null;
  this.mouseQueue = new SharingQueueMGR();
  this.sharingQueue = new SharingQueueMGR();
  this.WaterMarkRGBA = new JsMediaSDK_WaterMarkRGBA();

  this.sMonitorCount = 0;
  this.mMonitorCount = 0;
  this.firstFrameForIOS = false;
  // this.prevRequestAnimationTimestap = 0;
  this.timestart = 0;
  this.asTime = 0; //audio set time
  this.rAFID = 0;
  this.requestAnimation = false;
  this.requestF = this.No_Bindthis_RAF.bind(this);
  this.cATimeStamp = 0; //current active timestamp;
  this.lRTimeStamp = 0; //last render timestamp;
  this.pacingtime = 1; //request animation pacing index
  this.sharingFps = 0;
  this.lfTimeStamp = 0; //last frame time stamp;
  this.maxQueueLength = 0;
  this.vaTimeDelta = 0;
  this.renderMode = consts.RQUEST_ANIMATION_MODE; //0: requestanimation 1: interval;
  this.SharingRenderInterval = 0;
  this.RAFhealthCheckInterval = 0;
  this.RAFLastTime = 0;
  this.brefresh = false;
  this.statisticObj = null;
}

SharingRender.prototype.Start_Draw = function () {
  this.requestAnimation = true;
  return this.Start_Request_Animation_Frame();
};

SharingRender.prototype.Stop_Draw = function () {
  this.requestAnimation = false;
  this.lRTimeStamp = 0;
  this.cATimeStamp = 0;
  return this.Stop_Request_Animation_Frame();
};

SharingRender.prototype.Start_Request_Animation_Frame = function () {
  // this.prevRequestAnimationTimestap = performance.now();

  this.rAFID = requestAnimationFrame(this.requestF);
  return this.rAFID;
};

SharingRender.prototype.Stop_Request_Animation_Frame = function () {
  if (this.rAFID) {
    cancelAnimationFrame(this.rAFID);
    this.rAFID = 0;
  }
};

SharingRender.prototype.No_Bindthis_RAF = function () {
  let now = performance.now();
  this.RAFLastTime = now;
  if (!this.requestAnimation) {
    this.Stop_Request_Animation_Frame();
    return;
  }
  this.calPacingTime(now);
  if (now - this.timestart > this.pacingtime) {
    this.timestart = now;
    this.JsMediaSDK_SharingRender();
  }

  this.Start_Request_Animation_Frame();
};

SharingRender.prototype.No_Bindthis_Interval = function () {
  let now = performance.now();

  this.calPacingTime(now);
  if (now - this.timestart > this.pacingtime) {
    this.timestart = now;
    this.JsMediaSDK_SharingRender();
  }
};

SharingRender.prototype.calPacingTime = function (now) {
  this.pacingtime = 30;

  //real pacing time for sharing decode
  if (this.sharingFps && this.sharingFps > 0 && this.sharingFps < 100) {
    this.pacingtime = 1000 / this.sharingFps;
  }
  let queuelength = this.Get_Current_QueueLength();
  //jitter handle
  if (!!this.cATimeStamp && !!this.lRTimeStamp) {
    let audioDriftClock = this.cATimeStamp + now - this.asTime;
    this.vaTimeDelta = this.lRTimeStamp + this.pacingtime - audioDriftClock;
    if (this.vaTimeDelta > 65 && this.vaTimeDelta < 10000 && queuelength > 1) {
      this.pacingtime = this.pacingtime * 1.5;
    }
    if (this.vaTimeDelta < -65) {
      this.pacingtime = (this.pacingtime * 1) / 2;
    }
    if (process.env.NODE_ENV === 'development') {
      if (this.vaTimeDelta > 2000 || this.vaTimeDelta < -2000) {
        console.error(this.vaTimeDelta, queuelength);
      }
    }
  } else if (!this.cATimeStamp) {
    if (this.pacingtime > 150 || queuelength > 20) {
      this.pacingtime = (this.pacingtime * 1) / 2;
    } else {
      this.pacingtime = this.pacingtime - 10;
    }
  }
};

SharingRender.prototype.JsMediaSDK_SharingRender = function () {
  if (!this.sharingDisplay) {
    globaltracing_error(`JsMediaSDK_SharingRender error, display is null`);
    return;
  }
  if (this.sharingDisplay.isAvaiable?.() === false) {
    this.sharingDisplay.restoreContext?.();
    globaltracing_error(`JsMediaSDK_SharingRender canvas get context lost`);
    return;
  }
  this.statisticObj?.sample();

  var message = this.Get_Decoded_Sharing_Frame(
    this.currentshareactive,
    this.isFromMainSession
  );

  var message_mouse = this.Get_Decoded_Mouse_Frame(
    this.currentshareactive,
    this.isFromMainSession
  );
  if (message) {
    let yuvData;
    /**
     * @type {null|YuvWrap}
     */
    this.lRTimeStamp = message.ntptime;
    let yuvWrapIns;
    if (message.yuvdata instanceof YuvWrap) {
      yuvData = message.yuvdata.yuvdata;
      yuvWrapIns = message.yuvdata;
    } else {
      yuvData = message.yuvdata;
      yuvWrapIns = null;
    }

    //remoteControl
    if (
      this.sharingWidthAndHeightInfo.logicWidth != message.logic_w ||
      this.sharingWidthAndHeightInfo.logicHeight != message.logic_h
    ) {
      if (this.PubSub) {
        PubSub.publish(jsEvent.SHARING_PARAM_INFO_FROM_SOCKET, {
          body: {
            width: message.logic_w,
            height: message.logic_h,
            logicWidth: message.logic_w,
            logicHeight: message.logic_h,
          },
        });
      } else {
        postMessage({
          status: SdkEvent.Sharing_Width_And_Height_Info,
          logicWidth: message.logic_w,
          logicHeight: message.logic_h,
        });
        this.updateOffscreenCanvasSize(message.logic_w, message.logic_h);
      }
      this.sharingWidthAndHeightInfo.logicWidth = message.logic_w;
      this.sharingWidthAndHeightInfo.logicHeight = message.logic_h;
    }
    var locallogicalheight = message.logic_h;
    var locallogicalwidth = message.logic_w;
    var localheight = message.r_h;
    var localwidth = message.r_w;
    this.xratio = localwidth / locallogicalwidth;
    this.yratio = localheight / locallogicalheight;

    var croppingParams = {
      top: message.r_x,
      left: message.r_y,
      height: message.r_h,
      width: message.r_w,
    };
    if (
      this.currentSharingHeight != message.r_h ||
      this.currentSharingWidth != message.r_w ||
      this.currentSharingLogicHeight != message.logic_h ||
      this.currentSharingLogicWidth != message.logic_w
    ) {
      if (this.Notify_APPUI) {
        this.Notify_APPUI(jsEvent.SHARING_PARA, {
          body: {
            height: message.logic_h,
            width: message.logic_w,
            logicHeight: message.logic_h,
            logicWidth: message.logic_w,
          },
        });
      } else {
        postMessage({
          status: SdkEvent.Sharing_Width_And_Height_Info,
          logicWidth: message.logic_w,
          logicHeight: message.logic_h,
        });
        /**
         * offscreencanvas donot use logic_w or lgic_h, then why need this?
         * because the offscreencanvas width/height is unknown before rendering, webclient UI need SHARING_PARA to resize canvas dom size on the webpage.
         * then the real canvas dom size will be set into SDK from webclient UI,
         *
         * During this period, if offscrrencanvas donot have a correct length-width ratio, the sharing would be distorted picture.
         */
        this.updateOffscreenCanvasSize(message.logic_w, message.logic_h);
      }
      this.currentSharingHeight = message.r_h;
      this.currentSharingWidth = message.r_w;
      this.currentSharingLogicHeight = message.logic_h;
      this.currentSharingLogicWidth = message.logic_w;
    }
    const waterMarkWidth = this.SharingCanvasSizeInfo
      ? this.SharingCanvasSizeInfo.width
      : message.r_w;
    const waterMarkHeight = this.SharingCanvasSizeInfo
      ? this.SharingCanvasSizeInfo.height
      : message.r_h;
    if (
      this.Should_Update_Watermark(
        this.sharingDisplay,
        waterMarkWidth,
        waterMarkHeight
      )
    ) {
      this.Update_Display_Watermark(
        this.sharingDisplay,
        waterMarkWidth,
        waterMarkHeight
      );
    }

    if (this.sMonitorCount == 3 * 1000) {
      if (this.jsMediaEngine) {
        this.jsMediaEngine.Send_Render_Monitor_Log('SDIMM');
      } else {
        postMessage({
          status: SdkEvent.SHARING_RENDER_MONITOR_LOG,
          data: 'SDIMW',
        });
      }
      this.sMonitorCount = 0;
    }
    this.sMonitorCount++;
    this.sharingDisplay.drawNextOutputPictureFrame(
      message.width,
      message.height,
      croppingParams,
      yuvData,
      null,
      message.yuv_limited
    );

    if (yuvWrapIns) {
      yuvWrapIns.recycle();
    }
    if (message.dataptr) {
      Module._free(message.dataptr);
    }
  } else if (this.brefresh) {
    this.brefresh = false;
    if (
      this.sharingDisplay.getTextureWidth() != 0 &&
      this.sharingDisplay.getTextureHeight() != 0 &&
      this.currentSharingWidth !== 0 &&
      this.currentSharingHeight !== 0
    ) {
      const waterMarkWidth = this.SharingCanvasSizeInfo
        ? this.SharingCanvasSizeInfo.width
        : this.currentSharingWidth;
      const waterMarkHeight = this.SharingCanvasSizeInfo
        ? this.SharingCanvasSizeInfo.height
        : this.currentSharingHeight;
      if (
        this.Should_Update_Watermark(
          this.sharingDisplay,
          waterMarkWidth,
          waterMarkHeight
        )
      ) {
        this.Update_Display_Watermark(
          this.sharingDisplay,
          waterMarkWidth,
          waterMarkHeight
        );
      }

      this.sharingDisplay.drawNextOutputPictureFrame(
        this.sharingDisplay.getTextureWidth(),
        this.sharingDisplay.getTextureHeight(),
        this.sharingDisplay.getCroppingParams(),
        null,
        this.picRotation,
        true,
        null,
        false
      );
      message = true;
    }
  }
  if (message_mouse) {
    this.Cursorx = message_mouse.r_x * this.xratio;
    this.Cursory = message_mouse.r_y * this.yratio;
    this.CursorWidth = message_mouse.width * this.xratio;
    this.CursorHeight = message_mouse.height * this.yratio;
    this.sharingDisplay.updateCursor(
      message_mouse.width,
      message_mouse.height,
      message_mouse.buffer
    );
    if (this.mMonitorCount == 3 * 1000) {
      if (this.jsMediaEngine) {
        this.jsMediaEngine.Send_Render_Monitor_Log('SDSBM');
      } else {
        postMessage({
          status: SdkEvent.SHARING_RENDER_MONITOR_LOG,
          data: 'SDSBW',
        });
      }
      this.mMonitorCount = 0;
    }
    this.mMonitorCount++;
    this.sharingDisplay.drawCursor(
      1,
      this.Cursorx,
      this.Cursory,
      this.CursorWidth,
      this.CursorHeight
    );
  }

  if (message) {
    this.renderManager.renderFor(RenderConst.SERVE_FOR.SHARE);
  }
};

SharingRender.prototype.setOnlyAcceptUISize = function (bOnlyAcceptUISize) {
  this.bOnlyAcceptUISize = bOnlyAcceptUISize;
};

SharingRender.prototype.updateOffscreenCanvasSize = function (
  width,
  height,
  bUISize = false
) {
  /**
   * why need this?
   * Failed to set the 'width' property on 'HTMLCanvasElement':
   * Cannot resize canvas after call to transferControlToOffscreen()
   */
  if (this.bOnlyAcceptUISize && !bUISize) {
    return console.log('drop logic w/h');
  }
  try {
    let canvasElement = this.sharingDisplay.getAttachedCanvas();
    if (canvasElement && canvasElement instanceof OffscreenCanvas) {
      canvasElement.width = width;
      canvasElement.height = height;
      this.brefresh = true;
    }
  } catch (e) {
    this.Log_Error('Error updating OffscreenCanvas size', e);
  }
};

SharingRender.prototype.Set_Render_Display = function (context) {
  this.sharingDisplay = context;
};
SharingRender.prototype.Change_Current_SSRC = function (
  ssrc,
  isFromMainSession
) {
  this.currentshareactive = ssrc;
  this.currentSharingHeight = 0;
  this.currentSharingWidth = 0;
  this.currentSharingLogicHeight = 0;
  this.currentSharingLogicWidth = 0;
  this.isFromMainSession = isFromMainSession;
  this.firstFrameForIOS = false;
  this.ClearQueue();
};
SharingRender.prototype.Set_WaterMark_Info = function ({
  waterMarkCanvas,
  isCreateSharingWaterMark,
  sharingWaterMarkName,
  watermarkOpacity,
  watermarkRepeated,
  watermarkPosition,
}) {
  if (!isCreateSharingWaterMark) {
    this.SharingCanvasSizeInfo = null;
  }
  this.Replace_WaterMark_Canvas(waterMarkCanvas);
  this.isCreateSharingWaterMark = isCreateSharingWaterMark;
  this.sharingWaterMarkName = sharingWaterMarkName;
  if (watermarkRepeated !== undefined) {
    this.isWaterMarkRepeatedEnable = !!watermarkRepeated;
  }
  if (watermarkOpacity !== undefined) {
    this.waterMarkOpacity = watermarkOpacity;
  }
  if (watermarkPosition !== undefined) {
    this.watermarkPosition = watermarkPosition;
  }
};

SharingRender.prototype.Replace_WaterMark_Canvas = function (waterMarkCanvas) {
  this.waterMarkCanvas = waterMarkCanvas;
};

SharingRender.prototype.Set_WaterMark_Flag = function (enable) {
  this.sharingDisplay.setWatermarkFlag(enable ? 1 : 0);
};

SharingRender.prototype.Should_Watermark_Repeated = function (width, height) {
  return this.isWaterMarkRepeatedEnable && width > 306 && height > 202;
};

const getHigherQualitySize = function (width, height) {
  if (width < 640 && width) {
    const zoom = 640 / width;
    width = 640;
    height = Math.round(height * zoom);
  }
  return { width, height };
};

SharingRender.prototype.Update_Display_Watermark = function (
  display,
  width,
  height
) {
  if (
    typeof OffscreenCanvas === 'function' &&
    this.waterMarkCanvas instanceof OffscreenCanvas &&
    OffscreenCanvasRenderingContext2D &&
    !OffscreenCanvasRenderingContext2D.prototype.measureText
  ) {
    return;
  }

  /** follow native client, if video size too small, always render watermark in middle */
  const position = width < 512 || height < 288 ? 16 : this.watermarkPosition;
  const shouldRepeated = this.Should_Watermark_Repeated(width, height);
  const highQualitySize = getHigherQualitySize(width, height);
  width = highQualitySize.width;
  height = highQualitySize.height;
  const watermarkData = shouldRepeated
    ? this.WaterMarkRGBA.Get_Repeated_WaterMarkRGBA({
        canvas: this.waterMarkCanvas,
        name: this.sharingWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      })
    : this.WaterMarkRGBA.Get_WaterMarkRGBA({
        canvas: this.waterMarkCanvas,
        name: this.sharingWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      });
  display.updateWatermark(width, height, watermarkData);
};

SharingRender.prototype.Should_Update_Watermark = function (
  display,
  width,
  height
) {
  if (!this.isCreateSharingWaterMark) return false;
  let shouldUpdate = false;
  const highQualitySize = getHigherQualitySize(width, height);
  if (
    highQualitySize.width !== display.getWatermarkWidth() ||
    highQualitySize.height !== display.getWatermarkHeight()
  ) {
    shouldUpdate = true;
  }
  const isWatermarkRepeated = this.Should_Watermark_Repeated(width, height);
  /** have no watermark yet */
  if (!display.isSetWatermark()) {
    shouldUpdate = true;
  }
  /** switch between repeated and no repeated */
  if (isWatermarkRepeated !== display.isWatermarkRepeated()) {
    shouldUpdate = true;
    display.setWatermarkRepeated(isWatermarkRepeated);
  }
  if (
    this.waterMarkOpacity &&
    this.waterMarkOpacity !== display.getWatermarkOpacity()
  ) {
    shouldUpdate = true;
    display.setWatermarkOpacity(this.waterMarkOpacity);
  }
  /** follow native client, if video size too small, always render watermark in middle */
  const position = width < 512 || height < 288 ? 16 : this.watermarkPosition;
  if (position !== display.getWatermarkPosition()) {
    shouldUpdate = true;
    display.setWatermarkPosition(position);
  }
  return shouldUpdate;
};

SharingRender.prototype.Update_Sharing_Canvas_Size = function ({
  width,
  height,
}) {
  this.SharingCanvasSizeInfo = {
    width: Math.round(width),
    height: Math.round(height),
  };
};

SharingRender.prototype.ClearQueue = function () {
  try {
    let map = this.sharingQueue.ssrcQueueMap;
    for (let [k, v] of map) {
      while (!v.isEmpty()) {
        let item = v.dequeue();
        if (item.yuvdata && item.yuvdata instanceof YuvWrap) {
          item.yuvdata.recycle();
        }
        if (item.dataptr) {
          Module._free(item.dataptr);
        }
      }
    }
  } catch (e) {
    this.Log_Error('Exception from SharingRender.ClearQueue', e);
  }

  if (this.sharingQueue) {
    this.sharingQueue.ClearQueue();
  }
  if (this.mouseQueue) {
    this.mouseQueue.ClearQueue();
  }
  this.currentSharingHeight = 0;
  this.currentSharingWidth = 0;
  this.currentSharingLogicHeight = 0;
  this.currentSharingLogicWidth = 0;
};

SharingRender.prototype.Get_Decoded_Sharing_Frame = function (
  ssrc,
  isFromMainSession
) {
  if (!this.sharingQueue) {
    return null;
  }
  var ssrc_node_part = this.GetLogicalSSRCPart(ssrc, isFromMainSession);
  var ssrc_queue_sharing = this.sharingQueue.GetQueue(ssrc_node_part);
  if (ssrc_queue_sharing) {
    var data_node = ssrc_queue_sharing.dequeue();
    return data_node;
  }
  return null;
};

SharingRender.prototype.Get_Decoded_Mouse_Frame = function (
  ssrc,
  isFromMainSession
) {
  if (!this.mouseQueue) {
    return;
  }
  var ssrc_node_part = this.GetLogicalSSRCPart(ssrc, isFromMainSession);
  var ssrc_queue_mouse = this.mouseQueue.GetQueue(ssrc_node_part);
  if (ssrc_queue_mouse) {
    var data_node = ssrc_queue_mouse.dequeue();
    return data_node;
  }
  return null;
};

SharingRender.prototype.Put_Sharing_Data_From_Queue = function (
  message,
  maxQuqueBufferLen = 50
) {
  if (this.sharingQueue) {
    var ssrc_node_part = this.GetLogicalSSRCPart(
      message.ssrc,
      message.isFromMainSession
    );
    if (
      !this.firstFrameForIOS &&
      ssrc_node_part == this.currentshareactive >> 10
    ) {
      this.firstFrameForIOS = true;
      if (this.Notify_APPUI) {
        this.Notify_APPUI(jsEvent.FIRST_IOS_FRAME, this.currentshareactive);
      } else {
        postMessage({
          status: SdkEvent.FIRST_SHARING_FRAME_FOR_MOBILE,
          ssrc: this.currentshareactive,
        });
      }
    }
    var ssrcqueue_ = this.sharingQueue.GetQueue(ssrc_node_part);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.sharingQueue.AddQueue(ssrc_node_part);
    }
    ssrcqueue_.enqueue(message);
    if (this.lfTimeStamp) {
      if (!this.sharingFps) {
        this.sharingFps = 1000 / (message.ntptime - this.lfTimeStamp);
      } else {
        this.sharingFps =
          500 / (message.ntptime - this.lfTimeStamp) + this.sharingFps / 2;
      }
    }

    if (this.sharingFps == Infinity || !this.sharingFps) {
      this.sharingFps = 20;
    }
    this.lfTimeStamp = message.ntptime;

    var sharingqueuelength = this.sharingQueue.GetQueueLength(ssrc_node_part);
    var diff = sharingqueuelength - maxQuqueBufferLen;
    this.maxQueueLength = maxQuqueBufferLen;
    while (diff >= 0) {
      let buf = this.Get_Decoded_Sharing_Frame(
        message.ssrc,
        message.isFromMainSession
      );

      if (buf.yuvdata instanceof YuvWrap) {
        buf.yuvdata.recycle();
      }
      if (buf.dataptr) {
        Module._free(buf.dataptr);
      }
      diff--;
    }
  }
};
SharingRender.prototype.Get_Current_QueueLength = function () {
  if (!this.sharingQueue) {
    return;
  }
  let ssrc = this.currentshareactive;
  var ssrc_node_part = this.GetLogicalSSRCPart(ssrc, this.isFromMainSession);
  return this.sharingQueue.GetQueueLength(ssrc_node_part);
};
SharingRender.prototype.Put_Mouse_Data_Into_Queue = function (message) {
  if (this.mouseQueue) {
    var ssrc_node_part = this.GetLogicalSSRCPart(
      message.ssrc,
      message.isFromMainSession
    );
    var ssrcqueue_ = this.mouseQueue.GetQueue(ssrc_node_part);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.mouseQueue.AddQueue(ssrc_node_part);
    }
    ssrcqueue_.enqueue(message);
    var sharingqueuelength = this.mouseQueue.GetQueueLength(ssrc_node_part);
    var diff = sharingqueuelength - 10;
    while (diff >= 0) {
      this.Get_Decoded_Mouse_Frame(message.ssrc, message.isFromMainSession);
      diff--;
    }
  }
};
SharingRender.prototype.GetLogicalSSRCPart = function (
  ssrc,
  isFromMainSession
) {
  let ssrc_node_part = ssrc >> 10;
  if (isFromMainSession) {
    ssrc_node_part = (1 << 23) | ssrc_node_part;
  }
  return ssrc_node_part;
};

SharingRender.prototype.SetcATimeStamp = function (cATimeStamp) {
  this.cATimeStamp = cATimeStamp;
  this.asTime = performance.now();
};
// Based on the browser/hardware, SharingRender might exist on the main thread or a worker.
// The logger instance only exists on the main thread, so handle that case by sending the log message to the main thread.
SharingRender.prototype.Log_Error = function (message, errorEvent = null) {
  if (this.globalTracingLogger) {
    // in main thread, log directly
    this.globalTracingLogger.error(message, errorEvent);
  } else {
    // in worker, log via postMessage
    globaltracing_error(message, errorEvent);
  }
};

SharingRender.prototype.Log_DT = function (message) {
  if (this.globalTracingLogger) {
    // in main thread, log directly
    this.globalTracingLogger.directReport(message);
  } else {
    // in worker, log via postMessage
    globaltracing_DT(message);
  }
};

SharingRender.prototype.setMode = function (mode) {
  this.Stop_Draw2();
  this.renderMode = mode;
};

SharingRender.prototype.Start_Draw2 = function (mode) {
  this.statisticObj?.start();
  if (!this.renderMode) {
    this.Start_Draw();
    this.startRAFHealthCheck();
  } else {
    if (this.SharingRenderInterval) {
      clearInterval(this.SharingRenderInterval);
      this.SharingRenderInterval = 0;
    }
    this.SharingRenderInterval = setInterval(() => {
      this.No_Bindthis_Interval();
    }, 20);
  }
};

SharingRender.prototype.Stop_Draw2 = function (mode) {
  this.statisticObj?.stop();
  if (!this.renderMode) {
    this.Stop_Draw();
    this.stopRAFHealthCheck();
  } else {
    if (this.SharingRenderInterval) {
      clearInterval(this.SharingRenderInterval);
      this.SharingRenderInterval = 0;
    }
  }
};
SharingRender.prototype.startRAFHealthCheck = function () {
  this.RAFLastTime = performance.now();
  this.RAFhealthCheckInterval = setInterval(() => {
    let at_now = performance.now();
    if (!this.renderMode && at_now - this.RAFLastTime > 2000) {
      this.Stop_Draw2();
      this.setMode(consts.SET_INTERVAL_MODE);
      this.Start_Draw2();
      this.Log_DT('Sharing RAF Failed');
    }
  }, 2000);
};

SharingRender.prototype.stopRAFHealthCheck = function () {
  this.RAFLastTime = 0;
  if (this.RAFhealthCheckInterval) {
    clearInterval(this.RAFhealthCheckInterval);
  }
};
export default SharingRender;
