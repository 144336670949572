import H264bsdCanvas from '../common/WebGLCanvas';
import { IVBFrame } from './api';
import {
  VIDEO_I420,
  VIDEO_RGBA,
  VIDEO_BGRA,
  VIDEO_NV12,
} from '../worker/common/consts';

export default class VBRender {
  private display: H264bsdCanvas;
  private isMirror = false;

  public onUnsupportedFrame: (format: string) => void;
  constructor(cvsElement: HTMLCanvasElement | OffscreenCanvas, id: string) {
    this.display = new H264bsdCanvas(
      cvsElement,
      id,
      0,
      undefined,
      { preserveDrawingBuffer: false },
      undefined,
      false,
      false // Option of isEnableCanvasAlphaChannel is ensured false in VB
    );
  }

  private renderFrame(
    y_width: number,
    y_height: number,
    rawvideodata: Uint8Array | Uint8ClampedArray,
    croppingParams: any
  ) {
    if (this.display) {
      let coordinate = {
        x: 0,
        y: 0,
        width: this.display.canvasElement.width,
        height: this.display.canvasElement.height,
      };

      this.display.updateRemoteVideoTextures(
        y_width,
        y_height,
        croppingParams,
        rawvideodata,
        0,
        true,
        coordinate,
        false
      );
      this.display.drawRemoteVideo(coordinate, this.isMirror);
    }
  }

  public async render(frame: VideoFrame | ImageData | IVBFrame) {
    let dataFormat = VIDEO_RGBA;
    if ('data_ptr' in frame) {
      const {
        data,
        format_width,
        format_height,
        valid_x,
        valid_y,
        valid_width,
        valid_height,
      } = frame;
      let croppingParams = {
        top: valid_y,
        left: valid_x,
        width: valid_width,
        height: valid_height,
      };
      this.display.setVideoMode(VIDEO_I420);
      this.renderFrame(format_width, format_height, data, croppingParams);
    } else if (self.VideoFrame && frame instanceof VideoFrame) {
      const { format, visibleRect } = frame;
      if (format && visibleRect) {
        if (format === 'I420' || format === 'I420A') {
          dataFormat = VIDEO_I420;
        } else if (format === 'NV12') {
          dataFormat = VIDEO_NV12;
        } else if (format === 'BGRA') {
          dataFormat = VIDEO_BGRA;
        } else {
          this.onUnsupportedFrame && this.onUnsupportedFrame(format);
          return;
        }
        const { width, height } = visibleRect;
        const rawData = new Uint8Array(frame.allocationSize());
        const croppingParams = {
          top: 0,
          left: 0,
          width,
          height,
        };
        this.display.setVideoMode(dataFormat);
        try {
          await frame.copyTo(rawData);
          this.renderFrame(width, height, rawData, croppingParams);
        } catch (e) {
          //
        }
        frame.close();
      }
    } else if (frame instanceof ImageData) {
      const { width, height } = frame;
      const croppingParams = {
        top: 0,
        left: 0,
        width,
        height,
      };
      this.display.setVideoMode(VIDEO_RGBA);
      this.renderFrame(width, height, frame.data, croppingParams);
    }
  }

  public updateSize(width: number, height: number) {
    this.display.canvasElement.width = width;
    this.display.canvasElement.height = height;
  }

  public setMirror(isMirror: boolean) {
    this.isMirror = isMirror;
  }
}
