import {
  GPU_RES_TYPE,
  RES_OCCUPANCY_LEVEL,
  RES_RECYCLE_INTERVAL,
} from './RenderConst';

import { add_monitor } from '../../worker/common/common';

/**
 * GPUResourcesWatchDog helps to observe the occupancy of GPU resources.
 *
 * A timer will be started inside and observe all the observables every 30 seconds.
 * Once the timer is triggered, the GPU resources are collected to evaluate an occupancy
 * level which is used to decide how to recycle GPU resources.
 */
class GPUResourcesWatchDog {
  // this field is changed from static to dynamic in order to control the output numbers of log
  // and the recycling of the GPU resources.
  // if the occupancy level is low which means the resources is easy to acquire, the interval
  // should be longer than normal, if the level is high which means massive of resources are requested,
  // the interval should be adjusted to a shorter for recycling the idle resources as soon as possible.
  #mMonitorInterval = RES_RECYCLE_INTERVAL.HIGH;
  #mObservables = [];
  #mTimerId = null;
  #mLabel = '';

  constructor(label) {
    this.#mLabel = label;
  }

  /**
   * Add an observable which holds GPU resources.
   *
   * @param {*} observable a hold of GPU resources to be observed
   */
  addObservable(observable) {
    this.#mObservables.push(observable);
  }

  /**
   * Remove an observable which holds GPU resources.
   *
   * @param {*} observable a hold of GPU resources to be observed
   */
  removeObservable(observable) {
    const index = this.#mObservables.indexOf(observable);
    if (index != -1) {
      this.#mObservables.splice(index, 1);
    }
  }

  /**
   * Remove all observables.
   */
  removeAllObservables() {
    this.#mObservables.length = 0;
  }

  /**
   * Start to monitor all observables by launching a timer.
   */
  monitor() {
    if (!this.#mTimerId) {
      this.#mTimerId = setInterval(() => {
        this.#onObserved();
      }, this.#mMonitorInterval);
    }
  }

  #onObserved() {
    let maxOccupancyLevel = RES_OCCUPANCY_LEVEL.LOW;

    const title = `---WatchDog(${this.#mLabel}) starts analyzing---\n`;
    console.log(`${title}`);

    for (const ob of this.#mObservables) {
      // 1. collect the resources usage info
      const resInfo = ob.collectResourceInfo();

      // 2. output the log to the console and monitor log
      console.log(`${resInfo.output}`);
      // add_monitor(`[WatchDog#onObserved()] resInfo:${resInfo.output}`);

      // 3. to evaluate an occupancy level for deciding how to recycle
      const occupancyLevel = this.#evaluate(resInfo);
      ob.onOccupancyLevelEvaluated(occupancyLevel);

      // 4. record the higher level
      if (occupancyLevel > maxOccupancyLevel) {
        maxOccupancyLevel = occupancyLevel;
      }
    }

    // 5. adjust the interval of monitoring
    const interval = this.#evalMonitorInterval(maxOccupancyLevel);
    if (interval != this.#mMonitorInterval) {
      clearInterval(this.#mTimerId);
      this.#mTimerId = null;
      this.#mMonitorInterval = interval;
      this.monitor();
    }
  }

  /**
   * Evaluate the occupancy level which is used to help decide how to recycle GPU resources.
   *
   * @param {*} resInfo GPU resources information
   * @returns an occupancy level
   */
  #evaluate(resInfo) {
    let level = RES_OCCUPANCY_LEVEL.LOW;
    if (resInfo.type == GPU_RES_TYPE.TEXTURE) {
      if (resInfo.usedBytes <= 30 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.LOW;
      } else if (resInfo.usedBytes <= 90 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.MEDIUM;
      } else if (resInfo.usedBytes <= 150 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.HIGH;
      } else {
        level = RES_OCCUPANCY_LEVEL.OVERUSE;
      }
    } else if (resInfo.type == GPU_RES_TYPE.VERTEX_BUFFER) {
      if (resInfo.usedBytes <= 5 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.LOW;
      } else if (resInfo.usedBytes <= 10 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.MEDIUM;
      } else if (resInfo.usedBytes <= 15 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.HIGH;
      } else {
        level = RES_OCCUPANCY_LEVEL.OVERUSE;
      }
    } else if (resInfo.type == GPU_RES_TYPE.TEXTURE_BUFFER) {
      if (resInfo.usedBytes <= 50 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.LOW;
      } else if (resInfo.usedBytes <= 100 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.MEDIUM;
      } else if (resInfo.usedBytes <= 200 * 1024 * 1024) {
        level = RES_OCCUPANCY_LEVEL.HIGH;
      } else {
        level = RES_OCCUPANCY_LEVEL.OVERUSE;
      }
    }

    return level;
  }

  cleanup() {
    this.removeAllObservables();
    clearInterval(this.#mTimerId);
    this.#mTimerId = null;
    this.#mMonitorInterval = 0;
  }

  #evalMonitorInterval(maxOccupancyLevel) {
    let interval = 0;
    switch (maxOccupancyLevel) {
      case RES_OCCUPANCY_LEVEL.LOW:
        interval = RES_RECYCLE_INTERVAL.LOW;
        break;

      case RES_OCCUPANCY_LEVEL.MEDIUM:
        interval = RES_RECYCLE_INTERVAL.MEDIUM;
        break;

      case RES_OCCUPANCY_LEVEL.HIGH:
        interval = RES_RECYCLE_INTERVAL.HIGH;
        break;

      case RES_OCCUPANCY_LEVEL.OVERUSE:
        interval = RES_RECYCLE_INTERVAL.OVERUSE;
        break;

      default:
        interval = RES_RECYCLE_INTERVAL.MEDIUM;
        break;
    }
    return interval;
  }
}

export default GPUResourcesWatchDog;
