import util, { createAudioContext } from '../common/util';
import * as jsMediaEngine from '../../src/lib/JsMediaEngine';
import zoomAudioWorkletNode from '../lib/ZoomAudioWorkletNode';
import globalTracingLogger from '../common/globalTracingLogger';
import Zoom_Monitor from './Monitor';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import { AUDIO_LEVEL_INDICATOR } from '../common/jsEvent';
import { AUDIO_WEBRTC_WORKLET } from '../worker/common/consts';

const audioBridgeMonitor = (log, e) => Zoom_Monitor.add_monitor('AB' + log);
var levelR16Log = [];
var levelR16LogDenoise = [];
const maxLevelR16LogLength = 30;
const JOIN_AUDIO_UNMUTE = 1;
const JOIN_AUDIO_MUTE = 2;
const LEAVE_AUDIO = 3;

class LogTimer {
  constructor() {
    this.levelR16LogTimer = null;
    this.audioStatus = -1;
  }

  startLogTimer() {
    try {
      if (!this.levelR16LogTimer) {
        this.levelR16LogTimer = setInterval(() => {
          // When mute, replace audiolevel log with x.
          levelR16Log.push('x');
          levelR16LogDenoise.push('x');
          if (levelR16Log.length > maxLevelR16LogLength) {
            levelR16Log.shift();
          }
          if (levelR16LogDenoise.length > maxLevelR16LogLength) {
            levelR16LogDenoise.shift();
          }
        }, 1000);
      }
      this.audioStatus = JOIN_AUDIO_MUTE;
    } catch {
      audioBridgeMonitor('SALLTERR');
    }
  }

  suspendLogTimer(leave) {
    try {
      if (this.levelR16LogTimer) {
        clearInterval(this.levelR16LogTimer);
        this.levelR16LogTimer = null;
      }
      if (leave) {
        this.audioStatus = LEAVE_AUDIO;
      } else {
        this.audioStatus = JOIN_AUDIO_UNMUTE;
      }
    } catch {
      audioBridgeMonitor('PALLTERR');
    }
  }

  destroy() {
    this.suspendLogTimer();
    this.levelR16LogTimer = null;
  }
}

export class WebRTCWorkletManager {
  constructor(callback, workletPath) {
    this.workletPath = workletPath || {};
    this.audioStream = null;
    this.audioCtx = null;
    // Quickly switch devices. The SDK cannot receive the message of change micphone
    // and audioCtx will be suspended, so monitor statechange here.
    this.webRTCWorkletNode = null;
    this.inputNode = null;
    this.outputNode = null;
    this.isDestroyed = false;
    this.mutedLevelLogTimer = new LogTimer();
    this.initAudioContext();
    this.isCreatingWorklet = false;
    this.setAudioStreamCallback = callback;
    this.doingDenoise = false;
    this.checkProcessInterval = null;
  }

  initAudioContext() {
    if (!this.audioCtx) {
      let audioContextConfigure = util.getAudioContextConfigure();
      this.audioCtx = createAudioContext('WebRTCInput', audioContextConfigure);
      if (!this.audioCtx) return;
      this.audioCtx.onstatechange = () => {
        if (this.audioStream) {
          this.setAudioStreamCallback(null, true);
          const audioTrack = this.audioStream.getAudioTracks[0];
          if (audioTrack) {
            const { muted } = audioTrack;
            if (this.audioCtx?.state === 'running' && muted) {
              // Add log when audio stream is muted but audio context is running
              audioBridgeMonitor('ACRM');
              globalTracingLogger.error(
                'Audio context running when track muted'
              );
              // jsMediaEngineVariables.Notify_APPUI_SAFE(AUDIO_STREAM_ABNORNAL);
            }
          }
        }
      };
      this.outputNode = this.audioCtx.createMediaStreamDestination();
      this.resumeAudioCtx();
    }
  }

  async createWebRTCWorklet() {
    const { jsPath, wasmPath } = this.workletPath;
    if (!this.audioCtx || !jsPath) return;
    if (this.isCreatingWorklet) return;
    this.isCreatingWorklet = true;
    if (!this.webRTCWorkletNode) {
      try {
        await this.audioCtx.audioWorklet.addModule(jsPath);
      } catch (e) {
        globalTracingLogger.error('Error when add webRTC worklet module', e);
        this.isCreatingWorklet = false;
        return;
      }

      //if support denoise, worklet need load wasm
      let wasmModule = undefined;
      if (util.isSupportAudioDenoise(true) && wasmPath) {
        wasmModule = await util.downloadAndCompileWebAssembly(
          wasmPath,
          'AudioWorklet',
          Zoom_Monitor,
          !util.browser.isSafari &&
            jsMediaEngineVariables.enableStreamingInstantiate // Safari has a bug when instantiating the result of compileStreaming inside an AudioWorklet
        );
      }

      if (this.isDestroyed) {
        globalTracingLogger.log(
          'webrtc manager has been destroyed before create audio worklet node'
        );
        this.destroy();
        return;
      }

      let inputChannelNumber = util.isBrowserSupportStereo() ? 2 : 1;
      this.webRTCWorkletNode = new zoomAudioWorkletNode(
        this.audioCtx,
        'webRTCWorklet',
        {
          processorOptions: {
            userAgent: navigator.userAgent,
            wasmModule,
            inputAudioChannel: inputChannelNumber,
          },
          numberOfOutputs: 1,
          outputChannelCount: [2],
        }
      );
      this.webRTCWorkletNode.onprocessorerror = (event) => {
        globalTracingLogger.error(
          'Exception thrown in WebRTC AudioWorkletProcessor',
          event
        );
      };
      this.webRTCWorkletNode.postCMD('audiowasm');
      this.webRTCWorkletNode.port.addEventListener('message', (event) => {
        this.handleMessage(event);
      });
      if (this.audioStream && !this.inputNode) {
        this.inputNode = this.audioCtx.createMediaStreamSource(
          this.audioStream
        );
        this.inputNode.connect(this.webRTCWorkletNode);
      }
      this.webRTCWorkletNode.connect(this.outputNode);
      if (this.audioCtx.state === 'running') {
        this.startCheckProcess();
      }
      globalTracingLogger.log('create worklet successfully');
    }
    this.isCreatingWorklet = false;
    this.webRTCWorkletNode.postCMD('clearBuffer');
    this.resumeAudioCtx();
  }

  handleMessage(event) {
    var data = event.data;
    switch (data.status) {
      case 'AUDIO_LEVEL_R16':
        {
          if (this.mutedLevelLogTimer?.audioStatus != JOIN_AUDIO_UNMUTE) break;
          const levelR16 = data.level.toString(16);
          levelR16Log.push(levelR16);
          if (levelR16Log.length > maxLevelR16LogLength) {
            levelR16Log.shift();
          }
        }
        break;
      case 'AUDIO_LEVEL_R16_DENOISE':
        {
          if (this.mutedLevelLogTimer?.audioStatus != JOIN_AUDIO_UNMUTE) break;
          const levelR16 = data.level.toString(16);
          levelR16LogDenoise.push(levelR16);
          if (levelR16LogDenoise.length > maxLevelR16LogLength) {
            levelR16LogDenoise.shift();
          }
        }
        break;
      case 'WASM_INIT_SUCCESS': {
        this.changeDenoiseSwitch(this.denoiseSwitch, this.isHeadSet);
        break;
      }
      case 'audio_process_changed':
        {
          globalTracingLogger.log('denoise switch changed' + data.data);
          this.doingDenoise = data.data;
          this.setAudioStreamCallback(null, true);
        }
        break;
      case AUDIO_LEVEL_INDICATOR:
        {
          if (data.data != undefined) {
            jsMediaEngineVariables.Notify_APPUI_SAFE(AUDIO_LEVEL_INDICATOR, {
              value: data.data,
            });
          }
        }
        break;
      // case 'serverLog':
      //   {
      //     console.log(data);
      //     if (ws_log.readyState == 1) {
      //       ws_log.send(data.data);
      //     }
      //   }
      //   break;
      case 'SPEECH_LOG': {
        jsMediaEngine.addAudioMonitorLog.push({
          log: data.data.log,
          logSource: AUDIO_WEBRTC_WORKLET,
        });
      }
    }
  }

  setAudioStream(stream) {
    this.audioStream = stream;
    if (this.webRTCWorkletNode) {
      if (this.inputNode) {
        this.inputNode.disconnect();
        this.inputNode = null;
      }
      if (stream) {
        this.inputNode = this.audioCtx.createMediaStreamSource(
          this.audioStream
        );
        this.inputNode.connect(this.webRTCWorkletNode);
      }
    }
  }

  getAudioStream() {
    if (
      this.outputNode &&
      this.webRTCWorkletNode &&
      this.doingDenoise &&
      this.audioCtx?.state === 'running'
    ) {
      return this.outputNode.stream;
    } else {
      return this.audioStream;
    }
  }

  changeDenoiseSwitch(enable, isHeadSet) {
    this.denoiseSwitch = !!enable;
    this.isHeadSet = !!isHeadSet;
    if (this.webRTCWorkletNode) {
      this.webRTCWorkletNode.postCMD('audio_denoise_switch', {
        enable: this.denoiseSwitch,
        isHeadSet: this.isHeadSet,
      });
    }
  }
  getLevelR16Log() {
    const resLevelR16Log = levelR16Log.join('');
    const resLevelR16LogDenoise = levelR16LogDenoise.join('');
    levelR16Log = [];
    levelR16LogDenoise = [];
    return { resLevelR16Log, resLevelR16LogDenoise };
  }

  resumeAudioCtx() {
    if (
      this.mutedLevelLogTimer?.audioStatus === JOIN_AUDIO_UNMUTE &&
      this.audioCtx?.state !== 'running' &&
      this.audioCtx?.state !== 'closed' &&
      !this.isCreatingWorklet
    ) {
      this.startCheckProcess();
      this.audioCtx.resume().catch((e) => {
        globalTracingLogger.error('webRTC audioContext resume fail', e);
      });
    }
  }

  suspendAudioCtx() {
    if (this.audioCtx?.state !== 'suspended' && !this.isCreatingWorklet) {
      this.stopCheckProcess();
      this.audioCtx.suspend().catch((e) => {
        globalTracingLogger.error('Error when suspend audio context', e);
      });
    }
  }

  changeAudioStatus(muted, leave) {
    if (leave) {
      this.mutedLevelLogTimer?.suspendLogTimer(true);
      this.suspendAudioCtx();
    } else {
      if (muted) {
        this.mutedLevelLogTimer?.startLogTimer();
        this.suspendAudioCtx();
      } else {
        this.mutedLevelLogTimer?.suspendLogTimer(false);
        this.resumeAudioCtx();
      }
    }
  }

  startCheckProcess() {
    this.stopCheckProcess();

    if (this.webRTCWorkletNode) {
      this.webRTCWorkletNode.postCMD('clearProcess', null);

      this.checkProcessInterval = setInterval(() => {
        if (this.webRTCWorkletNode) {
          this.webRTCWorkletNode.postCMD('checkProcess', null);
        }
      }, 10000);
    }
  }

  stopCheckProcess() {
    if (this.checkProcessInterval) {
      clearInterval(this.checkProcessInterval);
      this.checkProcessInterval = null;
    }
  }

  destroy() {
    try {
      this.isDestroyed = true;
      if (this.webRTCWorkletNode) {
        this.webRTCWorkletNode.disconnect();
        this.webRTCWorkletNode.postCMD('stopWorklet', true);
        this.webRTCWorkletNode = null;
      }
      if (this.inputNode) {
        this.inputNode.disconnect(this.audioLevelNode);
        this.inputNode = null;
      }
      if (this.outputNode) {
        this.outputNode.disconnect();
        this.outputNode = null;
      }
      if (this.audioCtx && this.audioCtx.state !== 'closed') {
        this.audioCtx.suspend().finally(() => {
          this.audioCtx.close();
          this.audioCtx = null;
        });
      }

      this.audioStream = null;
      if (this.mutedLevelLogTimer) {
        this.mutedLevelLogTimer.destroy();
        this.mutedLevelLogTimer = null;
      }
      this.wasmModule = null;
      this.isCreatingWorklet = false;
      audioBridgeMonitor('DSALOK');
    } catch (error) {
      audioBridgeMonitor('DSALERR');
      globalTracingLogger.error('Error when destroy webRTC manager', error);
    }
  }
}
