/**
 * only for internal use
 */
export const Audio_Dec_WASM_OK = 1;
export const Audio_Dec_Handle_OK = 2;
export const Audio_Dec_WebSocket_OK = 3;
export const Audio_Enc_WASM_OK = 4;
export const Audio_Enc_Handle_OK = 5;
export const Video_Dec_WASM_OK = 7;
export const Video_Dec_Handle_OK = 8;
export const Video_Dec_WebSocket_OK = 9;
export const Video_Enc_WASM_OK = 10;
export const Video_Enc_Handle_OK = 11;
export const Sharing_Dec_WASM_OK = 12;
//export const Sharing_Dec_PICTURE = 13;
export const AUDIO_DELAY = 14;
export const Sharing_Dec_WebSocket_OK = 15;
export const Sharing_Handle_OK = 16;
//export const Sharing_Data = 17;
export const DECODE_MESSAGE = 18;
export const Video_Capture_Tick = 20;
export const MONITOR_MESSAGE = 21;
//export const SHARING_DECODE_MONITOR_MESSAGE = 3021;
export const WORKER_MAIN_VIDEO_ENCODE_RINGBUFFER_TICK = 22;
export const WORKER_MAIN_AUDIO_ENCODE_RINGBUFFER_TICK = 23;
export const WORKER_MAIN_VIDEO_DECODE_RINGBUFFER_TICK = 24;
export const Audio_Encode_Preview_OK = 26;
export const Video_Encode_Preview_OK = 27;

export const DOWNLOAD_WASM_FROM_MAIN_THREAD = 30;
export const APP_TROUBLESHOOTING_INFO = 31;

//export const DELTA_D = 32;
//export const DELTA_C = 33;
//export const DELTA_S = 34;
export const WCL_TROUBLESHOOTING_INFO = 35;
export const SHARING_DATA_VIDEO_MODE = 36;
export const MOUSE_DATA_VIDEO_MODE = 37;
export const SHARING_DECODE_MESSAGE = 38;
export const VIDEO_ENCODED_DATA = 39;
//export const VIDEO_DROP_RATIO_FROM_WCL = 40;
//export const VIDEO_DATA_DROP_RATIO = 41;
export const AUDIO_ENCODED_DATA = 42;
//export const THREAD_ENCODE_BUFFER = 46;
export const WASMPTR = 47;
export const AUDIO_MONITOR_LOG = 48;
export const VIDEO_RESOLUTION_UPDATE = 50;
export const VIDEO_RENDER_MONITOR_LOG = 51;
export const Sharing_Width_And_Height_Info = 52;
export const SHARING_RENDER_MONITOR_LOG = 53;
export const SHARING_GET_IMAGE_DATA_WRONG = 54;

//export const AES_GCM_IV_TO_WASM = 55;
export const AES_GCM_IV_CALLBACK_FROM_WASM = 56;
export const CURRENT_SSRC_TIME = 57;

//export const HARDWARE_VIDEO_INFO_LOG = 58;
export const WhiteBoard_Video_Capture_Tick = 60;
export const GLOBAL_TRACING_LOG = 61;
export const Video_Sharing_Handle_OK = 62;

export const CURRENT_DECODE_VIDEO_QUALITY = 66.5;
export const CURRENT_DECODE_VIDEO_FPS = 66.6;
export const CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT = 67;
export const CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT = 68;
export const SHARING_FIRST_DECODE_FRAME_RECEIVED = 69;
export const VIDEO_CAPTURER_RESOLUTION_CHANGE = 71;
export const VIDEO_CAPTURE_FRAME_COUNT_STATISTIC = 72;
export const SHARING_CAPTURE_FRAME_COUNT_STATISTIC = 73;
export const UNSUPPORTED_SHARING_FORMAT = 75;
export const UNSUPPORTED_VIDEO_FORMAT = 76;
export const FIRST_SHARING_FRAME_FOR_MOBILE = 78;
export const CONNECT_WEBTRANSPORT_OK = 105;
export const CONNECT_WEBTRANSPORT_CLOSE = 106;
export const CURRENT_MEDIA_DATA_TRANSPORT_TYPE = 107;
export const CONNECT_WEBSOCKET_CLOSE = 108;
export const CURRENT_ENCODED_TYPE = 109;
export const WHITEBOARD_WORKER_MESSAGE = 120;
export const NETWORK_QUALITY_CHANGE = 121;
export const NETWORK_QUALITY_CHANGE_AUDIO = 122;
export const WEBCODEC_PERFORMANCE_STATUS = 123;

// For annotation pdu
export const RECEIVE_ANNOTATION_PDU = 151;

//failed
export const Audio_Dec_WASM_FAILED = -1;
export const Audio_Dec_Handle_FAILED = -2;
export const Audio_Dec_WebSocket_FAILED = -3;
export const Audio_Enc_WASM_FAILED = -4;
export const Audio_Enc_Handle_FAILED = -5;
export const Video_Dec_WASM_FAILED = -7;
export const Video_Dec_Handle_FAILED = -8;
export const Video_Dec_WebSocket_FAILED = -9;
export const Video_Enc_WASM_FAILED = -10;
export const Video_Enc_Handle_FAILED = -11;
export const Sharing_Dec_WASM_FAILED = -12;
//export const Audio_Mute = -13;
export const Sharing_Handle_FAILED = -14;
export const Sharing_Dec_WebSocket_FAILED = -15;
export const AUDIO_CLIPPING = -23;

export const MULTIVIEW_WEBGL_CONTEXT_LOST = -26;
export const WEBGL_CONTEXT_CREATE_FAILED = -27;
export const MULTIVIEW_WEBGL_CONTEXT_RESTORED = -28;

export const WASM_MEMORY_GRROW_FAILED = -35;

export const SHARING_HEALTH_CHECK_FAILED = -129;
export const VIDEO_HEALTH_CHECK_FAILED = -130;
export const AUDIO_HEALTH_CHECK_FAILED = -131;
