export default class VBLoadingTimer {
  private timer3s: NodeJS.Timeout | null = null;
  private timer10s: NodeJS.Timeout | null = null;
  public ontimeout3s: () => void;
  public ontimeout10s: () => void;
  public isVBPredictDone = false;
  public inited = false;

  public start() {
    this.inited = true;
    this.clear();
    this.timer3s = setTimeout(() => {
      if (!this.isVBPredictDone) this.ontimeout3s();
    }, 3000);
    this.timer10s = setTimeout(() => {
      if (!this.isVBPredictDone) this.ontimeout10s();
    }, 10000);
  }

  public clear() {
    clearTimeout(this.timer3s);
    clearTimeout(this.timer10s);
    this.timer3s = null;
    this.timer10s = null;
  }
}
