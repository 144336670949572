//JSPLD JsMediaSDK_PreLoad
//JSINIT JsMediaSDK_Init
//event abbr
// https://zoomvideo.atlassian.net/wiki/spaces/ZTP/pages/451805636/ZOOM-71587+Add+trouble+shooting+main+steps+tracking+for+web+client
import * as jsEvent from '../common/jsEvent';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import globalTracingLogger, { isPowerOf2 } from '../common/globalTracingLogger';

// Descriptive names for errors that may occur. Sent as part of GlobalTracing messages.
const errorLogDescriptions = {
  AEWF: 'Audio encode WASM failed to download/compile',
  ADWF: 'Audio decode WASM failed to download/compile',
  VDWF: 'Video decode WASM failed to download/compile',
  VEWF: 'Video encode WASM failed to download/compile',
  SEWF: 'Sharing encode WASM failed to download/compile',
  SDWF: 'Sharing decode WASM failed to download/compile',
  MWCGLF: 'WebGL context failed to be restored',
  VCFF: 'Captured video format is not supported',
  SHHF: 'Initialization of sharing decode WASM failed',
  SDSF: 'Sharing decode WebSocket failed to connect after 10 attempts',
  SEHF: 'Initialization of sharing encode WASM failed',
  SESF: 'Sharing encode WebSocket failed to connect after 10 attempts',
  ADHF: 'Initialization of audio decode WASM failed',
  ADSF: 'Audio decode WebSocket failed to connect after 10 attempts',
  AEHF: 'Initialization of audio encode WASM failed',
  AESF: 'Audio encode WebSocket failed to connect after 10 attempts',
  VDHF: 'Initialization of video decode WASM failed',
  VDSF: 'Video decode WebSocket failed to connect after 10 attempts',
  VEHF: 'Initialization of video encode WASM failed',
  VESF: 'Video encode WebSocket failed to connect after 10 attempts',
  INITVDCERR: 'An error occurred when initializing video WebRTC DataChannel',
  INITADCERR: 'An error occurred when initializing audio WebRTC DataChannel',
};

const logFliter = [
  'VCAPTURE',
  'VFCLOSE',
  'VDCS',
  'CPC',
  'VDCR',
  'ADCS',
  'ADCR',
  'VCFOK',
  'VCTN',
  'ABRTT',
];

var Zoom_Monitor = (function () {
  function Monitor() {
    this.base_time = null;
    this.monitor = '';
    this.last_get_monitor_time = 0;
    this.checkIsNecessaryLogMap = new Map();
    this.highfrequencyerror = new Map();
    this.startSendLog = false;
  }

  Monitor.prototype = {
    //adds abbr audio/decode/download/start
    //init
    init: function () {
      if (this.base_time) return;
      this.base_time = new Date().getTime();
      this.checkIsNecessaryLogMap = new Map();
      this.add_monitor('STARTMONITOR' + this.base_time);
    },

    add_monitor: function (log, additionalInformation) {
      if (!this.base_time) {
        this.init();
      }
      if (log && this.checkLogValidity(log)) {
        try {
          if (errorLogDescriptions[log]) {
            let message = errorLogDescriptions[log];
            if (additionalInformation) {
              message = `${message}. Additional information: ${additionalInformation}`;
            }
            globalTracingLogger.error(message);
          }

          let send_instant = false;
          if (log[log.length - 1] == 'F' || log[log.length - 1] == 'f') {
            send_instant = true;
          }
          let delta_t = new Date().getTime() - this.base_time; //seconds
          if (this.monitor) {
            this.monitor = this.monitor + log + '(' + Math.ceil(delta_t) + ')';
          } else {
            this.monitor = log + '(' + Math.ceil(delta_t) + ')';
          }
          /** if size too large, server decode failed, rwg limit size to 2000 */
          if (this.monitor.length > 2000) {
            send_instant = true;
          }
          if (send_instant) {
            this.send_instant_monitor();
          }
        } catch (e) {
          globalTracingLogger.error(
            'Error when adding data to the monitor log',
            e
          );
        }
      }
    },
    get_monitor: function () {
      let zm = this.monitor;
      let now_t = new Date().getTime();
      if (
        zm == null ||
        !(zm.length > 80 || now_t - this.last_get_monitor_time > 3 * 60)
      ) {
        return '';
      }
      this.last_get_monitor_time = now_t;
      this.monitor = null;
      return 'WCL_M, ' + zm;
    },
    get_instant_monitor: function () {
      let zm = this.monitor;
      this.monitor = null;
      return zm ? 'WCL_M, ' + zm : null;
    },
    send_instant_monitor: function () {
      let zm = this.get_instant_monitor();
      if (zm) {
        jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
          evt: jsEvent.RWG_MONITOR_LOG_EVENT,
          seq: 1,
          body: {
            data: zm,
          },
        });
      }
    },
    send_monitor_directly: function (log) {
      if (!log || !this.checkLogValidity(log)) return;
      jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
        evt: jsEvent.RWG_MONITOR_LOG_EVENT,
        seq: 1,
        body: {
          data: log,
        },
      });
    },
    add_monitor2(log) {
      if (!this.base_time) {
        this.init();
      }
      if (log && this.checkLogValidity(log)) {
        let delta_t = new Date().getTime() - this.base_time;
        if (this.monitor) {
          this.monitor = this.monitor + log + '(' + delta_t + ')';
        } else {
          this.monitor = log + '(' + delta_t + ')';
        }
      }
    },
    add_monitor3(log) {
      if (!this.base_time) {
        this.init();
      }
      if (!log || !this.checkLogValidity(log)) {
        return;
      }
      if (!this.highfrequencyerror[log]) {
        this.highfrequencyerror[log] = 1;
      } else {
        this.highfrequencyerror[log] += 1;
      }

      const shouldReportHighFrequencyLog = isPowerOf2(
        this.highfrequencyerror[log]
      );

      if (shouldReportHighFrequencyLog) {
        this.add_monitor(log, `Occurred ${this.highfrequencyerror[log]} times`);
      }
    },
    /**
     * many exception logs are the same, do not send duplicated logs
     * checkIsNecessaryLogMap store N logs recently sent, and if new log is inside checkIsNecessaryLogMap, ignore the new log
     *
     * checkIsNecessaryLogMap store Max N logs, if storage is full, delete the first in logs ( first-in first-out )
     * @type logRawData {string}
     */
    checkIsNecessaryExceptionLogAndReturnRepeatTimes(logRawData) {
      let mapCacheMaxSize = 200; // The maximum number of logs stored in the map table
      let deletePiecesOnceTime = 20;
      let everyNTimesSendLog = 100; // N === value
      let isNecessary = true;
      let repeatNumber = 0;

      try {
        if (
          this.checkIsNecessaryLogMap.get(logRawData) &&
          this.checkIsNecessaryLogMap.get(logRawData) % everyNTimesSendLog !== 0
        ) {
          isNecessary = false;
        }

        repeatNumber = this.checkIsNecessaryLogMap.get(logRawData);

        return {
          isNecessary,
          repeatNumber: repeatNumber === undefined ? 0 : repeatNumber,
        };
      } catch (e) {
        return {
          isNecessary: true,
          repeatNumber: 0,
        };
      } finally {
        let oldCount = this.checkIsNecessaryLogMap.get(logRawData) || 0;
        this.checkIsNecessaryLogMap.set(logRawData, oldCount + 1);

        if (this.checkIsNecessaryLogMap.size > mapCacheMaxSize) {
          let deleteKeys = Array.from(this.checkIsNecessaryLogMap.keys()).slice(
            0,
            deletePiecesOnceTime
          );
          console.log('delete log cache keys', deleteKeys);
          deleteKeys.forEach((k) => this.checkIsNecessaryLogMap.delete(k));
        }
      }
    },

    reset() {
      this.base_time = null;
      this.last_get_monitor_time = 0;
      this.monitor = '';
      this.highfrequencyerror.clear();
    },
    startSend() {
      this.startSendLog = true;
    },
    checkLogValidity(log) {
      //only 60 logs can be sent in 3s. We need to reduce the number of logs on preview pages
      if (this.startSendLog) return true;
      return !logFliter.some((e) => log.indexOf(e) !== -1);
    },
  };

  return new Monitor();
})();

export default Zoom_Monitor;
