// import WebrtcAudioBridge from '../inside/WebrtcAudioBridge';
// import {
//   MEDIA_SOLUTION_WASM,
//   MEDIA_SOLUTION_WEBRTC,
// } from '../worker/common/consts';

import globalTracingLogger from './globalTracingLogger';

// const deviceList = {
//   audio: ['desktop', 'tablet'],
// };
class MediaSolutionInfo {
  constructor(mediaType) {
    this.mediaType = mediaType;
    this.firstSelect = null;
    this.finalSelect = null;
    this.webRTCProb = {
      probTime: 0,
      probResult: null,
    };
    this.hasProcess = false;
  }

  // process(rtcParams, forceSelect, options) {
  //   if (this.hasProcess) return;
  //   this.hasProcess = true;
  //   const { select, deviceType } = util.detectAudioSolutionByUA(options);
  //   this.firstSelect = select;
  //   this.deviceType = deviceType;

  //   //don't update localStorage if forceSelect is true or the device can't change solution
  //   if (forceSelect) {
  //     this.finalSelect = forceSelect;
  //     return;
  //   }

  //   const deviceCanChangeSolution = deviceList[this.mediaType].filter(
  //     (device) => device === deviceType
  //   );

  //   if (this.firstSelect === MEDIA_SOLUTION_WASM || !deviceCanChangeSolution) {
  //     this.finalSelect = this.firstSelect;
  //     return;
  //   }

  //   let lastMediaSolution = JSON.parse(
  //     localStorage.getItem(`${this.mediaType}States`)
  //   );
  //   if (!lastMediaSolution?.webRTCProb?.probTime) {
  //     this.finalSelect = this.firstSelect;
  //     this.updateLocalStorage();
  //     return;
  //   }
  //   let shouldChecRTC =
  //     !lastMediaSolution?.webRTCProb?.probResult &&
  //     Date.now() - lastMediaSolution.webRTCProb.probTime > 24 * 60 * 60 * 1000;

  //   if (shouldChecRTC) {
  //     this.finalSelect = MEDIA_SOLUTION_WASM;
  //     const { conID, reportDomain, ABToken, supportLocalAB, rwgHost } =
  //       rtcParams;
  //     if (conID && reportDomain && supportLocalAB && rwgHost) {
  //       this.checkRTCAvailability(
  //         `wss://${
  //           supportLocalAB ? rwgHost : reportDomain
  //         }/ab/signal?rwg=${rwgHost}&cid=${conID}`,
  //         ABToken
  //       );
  //     }
  //   } else {
  //     this.finalSelect = lastMediaSolution?.webRTCProb?.probResult
  //       ? MEDIA_SOLUTION_WEBRTC
  //       : MEDIA_SOLUTION_WASM;
  //   }
  //   this.updateLocalStorage();
  // }

  // checkRTCAvailability(wsUrl, ABToken) {
  //   if (this.mediaType === 'audio') {
  //     this.webRTCProb = new WebrtcAudioBridge(
  //       wsUrl,
  //       false,
  //       false,
  //       true,
  //       null,
  //       (event, data) => {
  //         switch (event) {
  //           case 'destroy':
  //             this.webRTCProb.destroy();
  //             this.webRTCProb = null;
  //             break;
  //           case 'updateConnectionResult':
  //             this.updatewebRTCProbResult(data);
  //             break;
  //         }
  //       },
  //       true
  //     );
  //     this.webRTCProb.join(false, ABToken);
  //   }
  // }

  // getFinalSolution() {
  //   return this.finalSelect;
  // }

  updatewebRTCProbResult(result) {
    this.webRTCProb.probTime = Date.now();
    this.webRTCProb.probResult = result;
    this.updateLocalStorage();
  }

  updateLocalStorage() {
    let mediaState =
      JSON.parse(localStorage.getItem(`${this.mediaType}States`)) || {};
    let newState = {
      mediaType: this.mediaType,
      firstSelect: this.firstSelect,
      finalSelect: this.finalSelect,
      webRTCProb: {
        probTime: this.webRTCProb.probTime || mediaState?.webRTCProb?.probTime,
        probResult:
          this.webRTCProb.probResult === null
            ? mediaState?.webRTCProb?.probResult
            : this.webRTCProb.probResult,
      },
    };
    localStorage.setItem(`${this.mediaType}States`, JSON.stringify(newState));
    globalTracingLogger.directReport(newState);
  }
}

/**
 *  used : {failed,total};
 *  delay: {avgdelay};
 *  timeout ratio:{ratio};
 * failedusedtime {usedmaxtime,avgusedtime}
 *
 */

class WebCodecPerformanceInfo {
  constructor(mediaType) {
    this.mediaType = mediaType;
  }

  getInfo() {
    try {
      let mediaState =
        JSON.parse(localStorage.getItem(`webcodec${this.mediaType}`)) || {};
      return mediaState;
    } catch (e) {
      return {};
    }
  }
  updateInfo(info, sysInfo) {
    try {
      let old = this.getInfo();
      let { failed, total } = old.usedcount || {
        failed: 0,
        total: 0,
      };

      let { avgusedtime, usedmaxtime } = old.usedtime || {
        avgusedtime: 0,
        usedmaxtime: 0,
      };

      let { avgratio, maxratio } = old.ratio || { avgratio: 0, maxratio: 0 };

      let { avgdelay } = old.delay || { avgdelay: 0 };

      if (info.failed) {
        failed++;
        avgusedtime = (avgusedtime * 3 + info.elapsed) / 4;
        usedmaxtime = info.elapsed > usedmaxtime ? info.elapsed : usedmaxtime;

        old.usedtime = Object.assign(old.usedtime || {}, {
          usedmaxtime,
          avgusedtime,
        });
      }

      if (!(info.elapsed > 31 * 1000 && info.failed)) {
        total++;
      }
      old.usedcount = Object.assign(old.usedcount || {}, { failed, total });

      if (info.outputIndex > 3) {
        avgdelay = (avgdelay * 3 + info.avgDelay) / 4;
        old.delay = Object.assign(old.delay || {}, { avgdelay });
      }

      if (info.inputIndex > 5) {
        avgratio = (avgratio * 3 + info.ratio) / 4;
        maxratio = info.ratio > maxratio ? info.ratio : maxratio;

        old.ratio = Object.assign(old.ratio || {}, { avgratio, maxratio });
      }

      let osVersion = sysInfo.osVersion;
      let browserVersion = sysInfo.browserVersion;
      let sdbversion = sysInfo.sdkVersion;
      old.version = { osVersion, browserVersion, sdbversion };
      localStorage.setItem(`webcodec${this.mediaType}`, JSON.stringify(old));
    } catch (e) {
      globalTracingLogger.error('<<< update localStorage error', e);
    }
  }
}

export class PersistenceInfo {
  constructor() {
    this.audioSolutionInfo = new MediaSolutionInfo('audio');
    this.videoEncoderInfo = new WebCodecPerformanceInfo('videoencode');
    this.videoDecoderInfo = new WebCodecPerformanceInfo('videodecode');
  }

  getAudioSolutionInfo() {
    return this.audioSolutionInfo;
  }
  getVideoEncoderInfo() {
    return this.videoEncoderInfo;
  }

  getVideoDecoderInfo() {
    return this.videoDecoderInfo;
  }
}
