import {
  VB_EVENT_TYPE,
  VB_EVENT,
  IVBInitParam,
  VB_START_MSG,
  VB_BOOLEAN_MSG,
  VB_WORKER_INIT_MSG,
  VB_RENDER_CANVAS_MSG,
  VB_SEND_FRAME_MSG,
  VB_UPDATE_BG_MSG,
  VB_FREE_MEMORY_MSG,
  VB_RENDER_FRAME_MSG,
  VB_GENERATE_STREAM_MSG,
  VB_CHANGE_STREAM_CANVAS_SIZE_MSG,
  VB_NO_PAYLOAD_MSG,
  VB_UPDATE_FRAME_RATE_MSG,
} from './api';
import wasmFeature from '../common/detectWasmFeatures';
import {
  UNIFIED_VB_FRAME,
  UNIFIED_VB_STOP,
  UNIFIED_VB_PAUSE,
  UNIFIED_VB_ACK,
} from '../common/jsEvent';
import VBRender from './vb.render';
import VBLoadingTimer from './vb.loadingTimer';

interface IVB_PARAM extends IVBInitParam {
  canvas?: string | HTMLCanvasElement;
  canvasID?: string;
  bgImage?: HTMLImageElement | ImageBitmap;
}

declare global {
  interface Window {
    MediaStreamTrackProcessor: any;
  }
}

export default class VB {
  public static cdnPath: string;
  private static VB_WORKER_NAME = 'vb_worker';
  private static VB_CAPTURE_VIDEO = 'VB_CAPTURE_VIDEO';
  private static VB_STREAM_CANVAS = 'VB_STREAM_CANVAS';
  private static EXTERNAL_EVENTS = new Set([
    VB_EVENT_TYPE.VB_GENERATED_FRAME,
    VB_EVENT_TYPE.VB_INIT_FAILED,
    VB_EVENT_TYPE.VB_INIT_SUCCESS,
    VB_EVENT_TYPE.VB_MODEL_READY,
    VB_EVENT_TYPE.VB_PREDICT_DONE,
    VB_EVENT_TYPE.VB_VIDEO_FORMAT_UNSUPPORTED,
    VB_EVENT_TYPE.VB_WORKER_ERROR,
    VB_EVENT_TYPE.VB_GENERATE_FIRST_FRAME,
  ]);
  private vbWorkerUrl: string;
  private worker: Worker;
  private bgConvertCanvas: OffscreenCanvas;
  private callback: (e: VB_EVENT) => void;
  public isVBReady = false;
  private videoStream: MediaStream | null = null;
  private bgImage: HTMLImageElement | ImageBitmap;
  private initResolve: (value?: any) => void;
  private initReject: (err: any) => void;
  private isVBInitializing: boolean = false;
  private initPromise: Promise<any>;
  private initParam: IVBInitParam | undefined;
  private cdnPath: string;
  private receiveTimer: any = null;
  public captureConf: Pick<
    MediaStreamConstraints,
    'video' | 'peerIdentity' | 'preferCurrentTab'
  > | null;
  private msgChannel: MessageChannel | null = null;
  public isEnabled = true;
  private isSafari: boolean;
  private cvsEle: HTMLCanvasElement | null;
  private trackProcessor: any | null = null;
  private captureVideoEle: HTMLVideoElement | null;
  private captureCanvas: HTMLCanvasElement | null;
  private captureCtx: CanvasRenderingContext2D | null;
  private isVBRunning: boolean = false;
  private vbTrackGenerator: MediaStreamTrackGenerator<VideoFrame>;
  private streamCanvas: HTMLCanvasElement | null;
  private streamRender: VBRender;
  private frameRate = 24;
  private isStreamEnabled: boolean = false;
  public vbStream: MediaStream | null;
  private vbLoadingTimer: VBLoadingTimer;
  private destoryed = false;
  private _needFrame = false;
  private _fps = 0;
  private _backend = '';

  constructor(param: IVB_PARAM) {
    const { canvas, canvasID, bgImage, cdnPath, ...restInitParam } =
      param || {};
    if (cdnPath) this.cdnPath = cdnPath;
    else this.cdnPath = VB.cdnPath;
    if (!this.cdnPath) throw new Error('VB module: cdnPath is not provided');
    this.vbWorkerUrl = `${this.cdnPath}/vb_worker.min.js`;
    this.initParam = restInitParam;
    this._needFrame = restInitParam.needFrame;
    if (canvas) {
      if (typeof canvas === 'string')
        this.cvsEle = document.querySelector<HTMLCanvasElement>(`#${canvas}`);
      else this.cvsEle = canvas;
    }
    if (bgImage) this.bgImage = bgImage;

    this.handle_message_from_vb_worker =
      this.handle_message_from_vb_worker.bind(this);
    const ua = navigator.userAgent.toLocaleLowerCase();
    this.isSafari =
      ua.includes('safari') &&
      !ua.includes('chrome') &&
      !ua.includes('chromium');
    this.vbLoadingTimer = new VBLoadingTimer();
  }

  public set needFrame(needFrame: boolean) {
    this._needFrame = needFrame;
    this.postMessage<VB_BOOLEAN_MSG>({
      cmd: VB_EVENT_TYPE.VB_UPDATE_NEED_FRAME,
      payload: needFrame,
    });
  }

  public get needFrame() {
    return this._needFrame;
  }

  public get fps() {
    return this._fps;
  }

  public get backend() {
    return this._backend;
  }

  public set ontimeout3s(cb: () => void) {
    this.vbLoadingTimer.ontimeout3s = cb;
  }

  public set ontimeout10s(cb: () => void) {
    this.vbLoadingTimer.ontimeout10s = cb;
  }

  public static async isSupportVB() {
    try {
      const isSupportSIMD = await wasmFeature.simd();
      return (
        navigator.hardwareConcurrency &&
        navigator.hardwareConcurrency > 2 &&
        isSupportSIMD &&
        typeof OffscreenCanvas == 'function'
      );
    } catch (e) {
      return false;
    }
  }

  private sendCanvas() {
    if (this.cvsEle) {
      const ofs = this.cvsEle.transferControlToOffscreen();
      this.postMessage<VB_RENDER_CANVAS_MSG>(
        {
          cmd: VB_EVENT_TYPE.VB_RENDER_CANVAS,
          payload: ofs,
        },
        [ofs]
      );
    }
  }

  private generateVBStream() {
    if (this.vbTrackGenerator) {
      const vbWriteStream = this.vbTrackGenerator.writable;
      this.postMessage<VB_GENERATE_STREAM_MSG>(
        {
          cmd: VB_EVENT_TYPE.VB_GENERATE_STREAM,
          payload: vbWriteStream,
        },
        [vbWriteStream]
      );
    } else if (this.streamCanvas) {
      if (this.isSafari) {
        const ofs = this.streamCanvas.transferControlToOffscreen();
        this.postMessage<VB_GENERATE_STREAM_MSG>(
          {
            cmd: VB_EVENT_TYPE.VB_GENERATE_STREAM,
            payload: ofs,
          },
          [ofs]
        );
      } else {
        this.streamRender = new VBRender(
          this.streamCanvas,
          VB.VB_STREAM_CANVAS
        );
        this.postMessage<VB_GENERATE_STREAM_MSG>({
          cmd: VB_EVENT_TYPE.VB_GENERATE_STREAM,
        });
      }
    }
  }

  public initialize(): Promise<any> {
    if (this.isVBReady) return Promise.resolve();
    if (this.isVBInitializing) return this.initPromise;
    this.isVBInitializing = true;
    this.vbLoadingTimer.isVBPredictDone = false;
    this.initPromise = new Promise(async (resolve, reject) => {
      this.initReject = reject;
      this.initResolve = resolve;
      try {
        const res = await fetch(this.vbWorkerUrl);
        if (res.ok) {
          const text = await res.arrayBuffer();
          if (this.destoryed) return;
          const url = window.URL.createObjectURL(new Blob([text]));
          this.worker = new Worker(url, { name: VB.VB_WORKER_NAME });
          this.worker.addEventListener(
            'message',
            this.handle_message_from_vb_worker
          );
          this.postMessage<VB_WORKER_INIT_MSG>({
            cmd: VB_EVENT_TYPE.VB_WORKER_INIT,
            payload: {
              ...this.initParam,
              cdnPath: this.cdnPath,
            },
          });
          this.sendCanvas();
          this.generateVBStream();
          window.URL.revokeObjectURL(url);
        } else {
          const { url, status } = res;
          this.isVBInitializing = false;
          reject(`Fetch worker file failed. url: ${url}, status: ${status}`);
        }
      } catch (e) {
        this.isVBInitializing = false;
        reject(e);
      }
    });
    return this.initPromise;
  }

  public send_frame(frame: VideoFrame | ImageData) {
    if (!this.isVBReady) return;
    if ('format' in frame) {
      this.postMessage<VB_SEND_FRAME_MSG>(
        {
          cmd: VB_EVENT_TYPE.VB_SEND_FRAME,
          payload: frame,
        },
        [frame]
      );
    } else {
      this.postMessage<VB_SEND_FRAME_MSG>(
        {
          cmd: VB_EVENT_TYPE.VB_SEND_FRAME,
          payload: frame,
        },
        [frame.data.buffer]
      );
    }
  }

  private Crop_Mask_Bg_16V9() {
    let sx,
      sy,
      sw,
      sh = 0;
    let destRate = 16 / 9;
    let srcWidth = this.bgImage.width;
    let srcHeight = this.bgImage.height;
    let dw = destRate * srcHeight;
    if (dw >= srcWidth) {
      sw = srcWidth;
      sh = srcWidth / destRate;
      sx = 0;
      sy = (srcHeight - sh) / 2;
    } else {
      sw = srcHeight * destRate;
      sh = srcHeight;
      sx = (srcWidth - sw) / 2;
      sy = 0;
    }
    return {
      sx,
      sy,
      sw,
      sh,
    };
  }

  public set_background_image(
    bgImage: HTMLImageElement | ImageBitmap
  ): boolean {
    this.bgImage = bgImage;
    if (!this.isVBReady) return false;
    const bgImageCroppingParams = this.Crop_Mask_Bg_16V9();
    let sx, sy, sw, sh;
    sx = bgImageCroppingParams.sx;
    sy = bgImageCroppingParams.sy;
    sw = bgImageCroppingParams.sw;
    sh = bgImageCroppingParams.sh;
    let width, height;
    if (sw > 1920) {
      width = 1920;
      height = 1080;
    } else {
      width = sw;
      height = sh;
    }
    if (!this.bgConvertCanvas) {
      this.bgConvertCanvas = new OffscreenCanvas(width, height);
    }
    this.bgConvertCanvas.width = width;
    this.bgConvertCanvas.height = height;
    let ctx = this.bgConvertCanvas.getContext('2d');
    if (!ctx) return false;
    ctx.drawImage(
      bgImage,
      sx,
      sy,
      sw,
      sh,
      0,
      0,
      this.bgConvertCanvas.width,
      this.bgConvertCanvas.height
    );
    let bgData = ctx.getImageData(
      0,
      0,
      this.bgConvertCanvas.width,
      this.bgConvertCanvas.height
    );
    this.postMessage<VB_UPDATE_BG_MSG>({
      cmd: VB_EVENT_TYPE.VB_UPDATE_BG,
      payload: bgData,
    });
    if ('close' in bgImage) bgImage.close();
    return true;
  }

  public set_background_blur() {
    if (!this.isVBReady) return;
    this.postMessage<VB_UPDATE_BG_MSG>({
      cmd: VB_EVENT_TYPE.VB_UPDATE_BG,
      payload: 'blur',
    });
  }

  private handle_message_from_vb_worker(e: MessageEvent<VB_EVENT>) {
    const { cmd } = e.data;
    if (VB.EXTERNAL_EVENTS.has(cmd)) {
      if (cmd !== VB_EVENT_TYPE.VB_GENERATED_FRAME || this._needFrame) {
        this.callback && this.callback(e.data);
      }
    }
    switch (cmd) {
      case VB_EVENT_TYPE.VB_MODEL_READY: {
        this.vbLoadingTimer.start();
        break;
      }
      case VB_EVENT_TYPE.VB_PREDICT_DONE: {
        const { payload } = e.data;
        this.vbLoadingTimer.isVBPredictDone = true;
        this.vbLoadingTimer.clear();
        this._backend = payload;
        break;
      }
      case VB_EVENT_TYPE.VB_INIT_FAILED: {
        const { payload } = e.data;
        this.isVBInitializing = false;
        this.initReject(payload);
        break;
      }
      case VB_EVENT_TYPE.VB_INIT_SUCCESS: {
        this.isVBReady = true;
        this.isVBInitializing = false;
        if (this.bgImage) this.set_background_image(this.bgImage);
        this.initResolve();
        break;
      }
      case VB_EVENT_TYPE.VB_REQUEST_FRAME: {
        this.updateConstraints();
        if (this.captureVideoEle && this.isVideoPlaying(this.captureVideoEle)) {
          if (window.VideoFrame) {
            const frame = new VideoFrame(this.captureVideoEle);
            this.send_frame(frame);
            frame.close();
          } else if (this.captureCtx) {
            this.captureCtx.drawImage(this.captureVideoEle, 0, 0);
            const imgData = this.captureCtx.getImageData(
              0,
              0,
              this.captureVideoEle.videoWidth,
              this.captureVideoEle.videoHeight
            );
            if (this.streamRender && this.isStreamEnabled && !this.isEnabled) {
              this.streamRender.render(imgData);
            }
            this.send_frame(imgData);
          }
        }
        break;
      }

      case VB_EVENT_TYPE.VB_GENERATED_FRAME: {
        const vbFrame = e.data.payload;
        const { data_ptr } = vbFrame;
        if (this.streamRender && this.isStreamEnabled)
          this.streamRender.render(vbFrame);
        if (data_ptr) this.freeMemory(data_ptr);
        break;
      }

      case VB_EVENT_TYPE.VB_UPDATE_FPS: {
        this._fps = e.data.payload;
        break;
      }
    }
  }

  public onMessage(callback: (e: VB_EVENT) => void) {
    this.callback = callback;
  }

  private createCaptureEle() {
    if (!window.MediaStreamTrackProcessor) {
      this.captureVideoEle = document.querySelector<HTMLVideoElement>(
        `#${VB.VB_CAPTURE_VIDEO}`
      );
      if (!this.captureVideoEle) {
        this.captureVideoEle = document.createElement('video');
        this.captureVideoEle.autoplay = true;
        this.captureVideoEle.playsInline = true;
        this.captureVideoEle.id = VB.VB_CAPTURE_VIDEO;
        this.captureVideoEle.style.position = 'fixed';
        this.captureVideoEle.style.width = '1px';
        this.captureVideoEle.style.height = '1px';
        this.captureVideoEle.style.bottom = '0px';
        this.captureVideoEle.style.right = '0px';
        this.captureVideoEle.muted = true;
        document.body.appendChild(this.captureVideoEle);
      }

      if (!this.captureCanvas && !window.VideoFrame) {
        this.captureCanvas = document.createElement('canvas');
        this.captureCtx = this.captureCanvas.getContext('2d');
      }
    }
  }

  private isVideoPlaying(videoEle: HTMLVideoElement): boolean {
    if (videoEle.paused || videoEle.ended) {
      videoEle.play();
    }
    return (
      videoEle.currentTime > 0 &&
      !videoEle.paused &&
      !videoEle.ended &&
      videoEle.readyState > 2
    );
  }

  private postMessage<T>(message: T, transferable?: Transferable[]) {
    if (transferable) {
      this.worker?.postMessage(message, transferable);
    } else {
      this.worker?.postMessage(message);
    }
  }

  private updateStreamCanvasSize(width: number, height: number) {
    if (this.streamCanvas) {
      if (this.isSafari) {
        this.postMessage<VB_CHANGE_STREAM_CANVAS_SIZE_MSG>({
          cmd: VB_EVENT_TYPE.VB_CHANGE_STREAM_CANVAS_SIZE,
          payload: {
            width,
            height,
          },
        });
      } else {
        this.streamCanvas.width = width;
        this.streamCanvas.height = height;
      }
    }
  }

  private updateConstraints() {
    const track = this.videoStream.getVideoTracks()[0];
    const { width, height, frameRate } = track.getSettings();
    if (width && height) {
      if (
        this.streamCanvas &&
        (width !== this.streamCanvas.width ||
          height !== this.streamCanvas.height)
      ) {
        this.updateStreamCanvasSize(width, height);
      }
      if (this.captureCanvas) {
        if (width !== this.captureCanvas.width)
          this.captureCanvas.width = width;
        if (height !== this.captureCanvas.height)
          this.captureCanvas.height = height;
      }
    }

    if (frameRate && this.frameRate != frameRate) {
      this.frameRate = Math.min(frameRate, 24);
      this.postMessage<VB_UPDATE_FRAME_RATE_MSG>({
        cmd: VB_EVENT_TYPE.VB_UPDATE_FRAME_RATE,
        payload: this.frameRate,
      });
    }
  }

  public async captureVideo(
    param:
      | Pick<
          MediaStreamConstraints,
          'video' | 'peerIdentity' | 'preferCurrentTab'
        >
      | MediaStream
  ) {
    if (!this.isVBReady && !this.isVBInitializing) {
      await this.initialize();
    } else if (this.isVBInitializing) {
      await this.initPromise;
    }

    let isNewStream = true;
    if ('id' in param) {
      if (!param.active) return Promise.reject('stream is not active');
      if (this.videoStream && this.videoStream.id === param.id) {
        isNewStream = false;
      } else {
        this.stopCapture();
        this.videoStream = param;
      }
    } else {
      if (this.videoStream && this.captureConf === param) {
        isNewStream = false;
      } else {
        this.stopCapture();
        this.captureConf = param;
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          ...param,
          audio: false,
        });
      }
    }

    this.createCaptureEle();
    if (isNewStream) {
      let videoTrack: MediaStreamTrack;

      if ((videoTrack = this.videoStream.getVideoTracks()[0])) {
        const videoTrackSetting = videoTrack.getSettings();
        const { width = 0, height = 0, frameRate = 24 } = videoTrackSetting;
        this.updateStreamCanvasSize(width, height);
        this.frameRate = Math.min(frameRate, 24);
        if (window.MediaStreamTrackProcessor) {
          this.trackProcessor = new window.MediaStreamTrackProcessor({
            track: videoTrack,
          });
          const stream = this.trackProcessor.readable;
          this.postMessage<VB_START_MSG>(
            {
              cmd: VB_EVENT_TYPE.VB_START,
              payload: {
                videoStream: stream,
                frameRate: this.frameRate,
              },
            },
            [stream]
          );
        } else if (this.captureVideoEle) {
          this.captureVideoEle.srcObject = this.videoStream;
          this.captureVideoEle.play();
          if (this.captureCanvas) {
            this.captureCanvas.width = width;
            this.captureCanvas.height = height;
          }
          this.postMessage<VB_START_MSG>({
            cmd: VB_EVENT_TYPE.VB_START,
            payload: {
              frameRate: this.frameRate,
            },
          });
        }
      } else {
        return Promise.reject('No video track in stream');
      }
    } else {
      if (this.isVBRunning) return;
      const track = this.videoStream.getVideoTracks()[0];
      track.enabled = true;
      if (this.captureVideoEle) this.captureVideoEle.play();
      this.postMessage<VB_START_MSG>({
        cmd: VB_EVENT_TYPE.VB_START,
      });
    }
    this.isVBRunning = true;
  }

  public stopCapture(destoryStream: boolean = true) {
    this.postMessage<VB_BOOLEAN_MSG>({
      cmd: VB_EVENT_TYPE.VB_STOP,
      payload: destoryStream,
    });

    if (this.captureVideoEle) this.captureVideoEle.pause();

    if (this.videoStream && destoryStream) {
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.videoStream = null;
    }

    this.isVBRunning = false;
  }

  public setMirror(isMirror: boolean) {
    this.postMessage<VB_BOOLEAN_MSG>({
      cmd: VB_EVENT_TYPE.VB_MIRROR,
      payload: isMirror,
    });
  }

  public freeMemory(ptr: number) {
    if (!this.isVBReady) return;
    this.postMessage<VB_FREE_MEMORY_MSG>({
      cmd: VB_EVENT_TYPE.VB_FREE_MEMORY,
      payload: ptr,
    });
  }

  public startReceiveMode(
    constraint?: Pick<
      MediaStreamConstraints,
      'video' | 'peerIdentity' | 'preferCurrentTab'
    >
  ) {
    if (constraint) this.captureConf = constraint;
    if (!this.msgChannel) {
      this.msgChannel = new MessageChannel();
      this.msgChannel.port1.onmessage = (
        e: MessageEvent<{ type: string; frame?: VideoFrame }>
      ) => {
        const { type, frame } = e.data;
        if (this.receiveTimer) clearTimeout(this.receiveTimer);
        if (type === UNIFIED_VB_FRAME && frame) {
          this.msgChannel?.port1.postMessage({
            type: UNIFIED_VB_ACK,
          });
          if (this.isEnabled) {
            if (this.isVBRunning) this.stopCapture();
            this.renderFrame(frame);
          } else {
            frame.close();
          }
          // On safari, camera can only be accessed exclusively
          if (!this.isSafari) {
            this.receiveTimer = setTimeout(() => {
              if (this.captureConf && !this.isVBRunning)
                this.captureVideo(this.captureConf);
            }, 1000);
          }
        } else if (type === UNIFIED_VB_PAUSE || type === UNIFIED_VB_STOP) {
          if (this.captureConf && !this.isVBRunning) {
            this.captureVideo(this.captureConf);
          }
          if (type === UNIFIED_VB_STOP && this.msgChannel) {
            this.msgChannel.port1.onmessage = null;
            this.msgChannel = null;
          }
        }
      };
    }
    return this.msgChannel.port2;
  }

  public enable() {
    this.postMessage<VB_BOOLEAN_MSG>({
      cmd: VB_EVENT_TYPE.VB_TOGGLE_VB,
      payload: true,
    });
    this.isEnabled = true;
  }

  public disable() {
    this.postMessage<VB_BOOLEAN_MSG>({
      cmd: VB_EVENT_TYPE.VB_TOGGLE_VB,
      payload: false,
    });
    this.isEnabled = false;
    if (!this.isVBRunning && this.msgChannel && this.captureConf) {
      this.captureVideo(this.captureConf).catch((e) => {});
      clearTimeout(this.receiveTimer);
    }
  }

  private async renderFrame(frame: VideoFrame | ImageData) {
    this.postMessage<VB_RENDER_FRAME_MSG>({
      cmd: VB_EVENT_TYPE.VB_RENDER_FRAME,
      payload: frame,
    });
    if ('close' in frame) frame.close();
  }

  public destory() {
    this.destoryed = true;
    this.stopCapture();
    this.vbLoadingTimer.clear();
    if (this.msgChannel) this.msgChannel.port2.onmessage = null;
    if (this.captureVideoEle) document.body.removeChild(this.captureVideoEle);
    if (this.worker) {
      this.worker.removeEventListener(
        'message',
        this.handle_message_from_vb_worker
      );
      this.worker.terminate();
    }
  }

  public createStream() {
    this.isStreamEnabled = true;
    if (this.vbStream) {
      this.postMessage<VB_GENERATE_STREAM_MSG>({
        cmd: VB_EVENT_TYPE.VB_GENERATE_STREAM,
      });
      return this.vbStream;
    }
    if (window.MediaStreamTrackGenerator) {
      this.vbTrackGenerator = new MediaStreamTrackGenerator({
        kind: 'video',
      });
      this.vbStream = new MediaStream([this.vbTrackGenerator]);
    } else {
      this.streamCanvas = document.createElement('canvas');
      if (this.videoStream) {
        const track = this.videoStream.getVideoTracks()[0];
        if (track) {
          const { width = 0, height = 0 } = track.getSettings();
          this.streamCanvas.width = width;
          this.streamCanvas.height = height;
        }
      }
      this.vbStream = this.streamCanvas.captureStream(24);
    }
    if (this.isVBReady) this.generateVBStream();
    return this.vbStream;
  }

  public stopStream() {
    if (!this.vbStream) return;
    this.isStreamEnabled = false;
    this.postMessage<VB_NO_PAYLOAD_MSG>({
      cmd: VB_EVENT_TYPE.VB_STOP_STREAM,
    });
  }
}

if (document) {
  const vbScript = document.currentScript;
  if (vbScript) {
    const vbSrc = (vbScript as HTMLScriptElement).src;
    if (vbSrc) {
      let vbIndex = vbSrc.indexOf('/vb.min.js');
      if (vbIndex !== -1) VB.cdnPath = vbSrc.substring(0, vbIndex);
    }
  }
}
