class RenderConfig {
  static #isEnableCanvasCtxOptionsOpt = false;

  static isEnableCanvasCtxOptionsOpt() {
    return RenderConfig.#isEnableCanvasCtxOptionsOpt;
  }

  static setIsEnableCanvasCtxOptionsOpt(enable) {
    RenderConfig.#isEnableCanvasCtxOptionsOpt = enable;
  }
}

export default RenderConfig;
