import globalTracingLogger from './globalTracingLogger';

const MediaConfigParser = {
  /**
   * Check whether the target device hits the WebCodec whitelist.
   *
   * @param {*} targetVendor vendor name, like intel, apple, arm, etc
   * @param {*} targetRenderInfo the renderer info, maybe from WebGL render info
   * @param {*} codecType a string value, it should be 'encoder' or 'decoder' or 'all'
   * @param {*} webCodecWhitelist the WebCodec whitelist from op
   * @returns if true, the target device in on the WebCodec whitelist, if false, on the blacklist or not support
   */
  isOnWebCodecWhitelist(
    targetVendor,
    targetRenderInfo,
    codecType,
    webCodecWhitelist
  ) {
    if (
      targetVendor === '' ||
      targetVendor === undefined ||
      targetRenderInfo === undefined ||
      targetRenderInfo === ''
    ) {
      return false;
    }

    // if webCodecBlacklist is not set, we should follow the previous logic
    if (!webCodecWhitelist) {
      const isArm = targetVendor.includes('arm');
      const isIntel = targetRenderInfo.includes('intel');
      const isAMD = targetRenderInfo.includes('amd');
      const isNvidia = targetRenderInfo.includes('nvidia');
      if (codecType === 'encoder') {
        if (isArm) {
          return false;
        } else if (isIntel || isAMD || isNvidia) {
          return true;
        } else {
          return false;
        }
      } else if (codecType === 'decoder') {
        if (isArm) {
          return false;
        } else if (isAMD) {
          return true;
        } else if (isIntel) {
          return !this.isInRangeOfGenerations(
            'intel',
            targetRenderInfo,
            1000,
            4000
          );
        } else if (isNvidia) {
          return !this.isLowerThanMinGeneration(
            'nvidia',
            targetRenderInfo,
            '600'
          );
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    targetRenderInfo = this.replaceSpacesWithUnderscores(targetRenderInfo);

    for (let i = 0; i < webCodecWhitelist.length; i++) {
      const entry = webCodecWhitelist[i];

      const hasVendorField = 'vendor' in entry;
      const hasModelField = 'model' in entry;
      const hasRenderInfoField = 'renderInfo' in entry;
      const hasBlacklistField = 'blacklist' in entry;

      let entryVendor = '';
      if (hasVendorField) {
        if (entry.vendor !== '') {
          entryVendor = entry.vendor.toLowerCase();
        }
      }

      let entryModel = '';
      if (hasModelField) {
        if (entry.model !== '') {
          entryModel = this.replaceSpacesWithUnderscores(
            entry.model.toLowerCase()
          );
        }
      }

      let entryRenderInfo = '';
      if (hasRenderInfoField) {
        if (entry.renderInfo !== '') {
          entryRenderInfo = this.replaceSpacesWithUnderscores(
            entry.renderInfo.toLowerCase()
          );
        }
      }

      let entryBlacklist = null;
      if (hasBlacklistField) {
        if (entry.blacklist.length > 0) {
          entryBlacklist = entry.blacklist;
        }
      }

      if (hasVendorField) {
        if (entryVendor !== '' && targetVendor.includes(entryVendor)) {
          if (entryModel === '' && entryRenderInfo === '' && !entryBlacklist) {
            // all GPUs of this vendor are allowed
            return true;
          }

          if (
            targetRenderInfo === entryRenderInfo ||
            (entryRenderInfo !== '' &&
              targetRenderInfo.includes(entryRenderInfo))
          ) {
            // if entry renderInfo equals to the target renderInfo totally
            // it means a specific GPU is allowed only
            // blacklist will be ignored under this case
            return true;
          }

          if (entryModel !== '' && targetRenderInfo.includes(entryModel)) {
            // if the target renderInfo includes the model, like intel uhd
            // it means a series of GPUs of this vendor are allowed
            // but here we need to check the blacklist
            if (entryBlacklist) {
              const isHitBlacklist = this.isHitBlacklist(
                targetVendor,
                targetRenderInfo,
                codecType,
                entryModel,
                entryBlacklist
              );
              return !isHitBlacklist;
            } else {
              // if no blacklist set and target renderInfo contains the model
              // it means a series of GPUs are allowed
              return true;
            }
          } else {
            // if the target renderInfo doesn't include the model or the model is not set
            // next, we need to check the blacklist
            // here are two cases if renderInfo doesn't include the model
            if (entryModel !== '') {
              // if entryModel is not empty, no need to check the blacklist because parent model must be included in the target renderInfo
              return false;
            } else {
              if (entryBlacklist) {
                const isHitBlacklist = this.isHitBlacklist(
                  targetVendor,
                  targetRenderInfo,
                  codecType,
                  entryModel,
                  entryBlacklist
                );
                return !isHitBlacklist;
              } else {
                return true;
              }
            }
          }
        }
      } else {
        // if no vendor field in entry, it means the entry is invalid
        globalTracingLogger.error(
          `isOnWebCodecWhitelist() no vendor field in the json entry! entry:${entry}`
        );
      }
    }

    return false;
  },

  isGPUProfileOnWebCodecWhitelist(codecType, webCodecConfig) {
    if (!this.isOffscreenCanvasSupported()) {
      globalTracingLogger.log(
        `isGPUProfileOnWebCodecWhitelist() OffscreenCanvas is not supported.`
      );
      return false;
    }

    try {
      const gpuInfo = this.getGPUInfo();
      const vendor = gpuInfo.vendor;
      const renderInfo = gpuInfo.renderer.toLowerCase();

      const isOnWhitelist = this.isOnWebCodecWhitelist(
        vendor,
        renderInfo,
        codecType,
        webCodecConfig
      );

      globalTracingLogger.directReport(
        `isGPUProfileOnWebCodecWhitelist() isOnWhitelist:${isOnWhitelist}, vendor:${vendor}, renderInfo:${renderInfo}, codecType:${codecType}, config:${JSON.stringify(
          webCodecConfig
        )}`
      );
      return isOnWhitelist;
    } catch (e) {
      return false;
    }
  },

  isLowerThanMinGeneration(vendor, renderInfo, minGeneration) {
    try {
      const _vendor = vendor.toLowerCase();
      const _renderInfo = renderInfo.toLowerCase();
      const _minGeneration = parseInt(minGeneration);

      if (_vendor === 'nvidia') {
        if (_renderInfo.includes('geforce')) {
          const index = _renderInfo.indexOf('geforce gt ');
          const gen = parseInt(_renderInfo.slice(index + 11));
          if (gen < _minGeneration) {
            return true;
          }
        }
      } else if (_vendor === 'intel') {
        if (_renderInfo.includes('hd graphics')) {
          const index = _renderInfo.indexOf('hd graphics ');
          const gen = parseInt(_renderInfo.slice(index + 12));
          if (gen < _minGeneration) {
            return true;
          }
        }
      }

      return false;
    } catch (e) {
      globalTracingLogger.error(e);
      return false;
    }
  },

  isInRangeOfGenerations(vendor, renderInfo, leftGeneration, rightGeneration) {
    try {
      const _vendor = vendor.toLowerCase();
      const _renderInfo = renderInfo.toLowerCase();
      if (_vendor === 'intel') {
        if (_renderInfo.includes('hd graphics')) {
          const index = _renderInfo.indexOf('hd graphics ');
          const gen = parseInt(_renderInfo.slice(index + 12));
          if (gen > leftGeneration && gen < rightGeneration) {
            return true;
          }
        }
      }
      return false;
    } catch (e) {
      globalTracingLogger.error(e);
      return false;
    }
  },

  getGPUInfo() {
    let canvas = null;
    if (this.isOffscreenCanvasSupported()) {
      canvas = new OffscreenCanvas(1, 1);
    }

    if (!canvas) {
      return {
        renderer: '',
        vendor: '',
      };
    }

    try {
      const glCtx = canvas.getContext('webgl');
      if (glCtx) {
        let debugInfo = glCtx.getExtension('WEBGL_debug_renderer_info');
        let renderer = glCtx.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        let vendor = glCtx.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        return {
          renderer: renderer.toLowerCase(),
          vendor: vendor.toLowerCase(),
        };
      }
    } catch (e) {
      globalTracingLogger.error(e);
      return null;
    } finally {
      if (canvas) {
        canvas = null;
      }
    }
  },

  isOffscreenCanvasSupported() {
    return typeof OffscreenCanvas === 'function';
  },

  isHitBlacklist(
    targetVendor,
    targetRenderInfo,
    codecType,
    parentModel,
    blacklist
  ) {
    if (!targetRenderInfo || targetRenderInfo === '') {
      globalTracingLogger.error(`isHitBlacklist() targetRenderInfo is invalid`);
      return true;
    }

    if (!blacklist) {
      globalTracingLogger.error(
        `isHitBlacklist() an invalid blacklist configuration object`
      );
      return false;
    }

    if (
      codecType !== 'encoder' &&
      codecType !== 'decoder' &&
      codecType !== 'all'
    ) {
      globalTracingLogger.error(
        `isHitBlacklist() an invalid codecType(${codecType}).`
      );
      return true;
    }

    const _parentModel = parentModel.toLowerCase();
    let hitBlacklist = false;
    for (const entry of blacklist) {
      const hasModel = 'model' in entry;
      const hasCodecType = 'codecType' in entry;
      const hasRenderInfo = 'renderInfo' in entry;
      const hasMinGeneration = 'minGeneration' in entry;
      const hasOS = 'os' in entry;

      // optional field
      let _model = null;
      if (hasModel) {
        if (entry.model !== '') {
          _model = this.replaceSpacesWithUnderscores(entry.model.toLowerCase());
        }
      }

      // must field
      let _codecType = null;
      if (hasCodecType) {
        _codecType = entry.codecType;
        if (
          _codecType !== 'all' &&
          _codecType !== 'encoder' &&
          _codecType !== 'decoder'
        ) {
          globalTracingLogger.error(
            `isHitBlacklist() codecType(${_codecType}) should be all/(empty)/encoder/decoder.`
          );
          continue;
        }
      } else {
        globalTracingLogger.warn(
          `isHitBlacklist() miss codecType field in the configuration.`
        );
        continue;
      }

      // optional field
      let _renderInfo = null;
      if (hasRenderInfo) {
        if (entry.renderInfo !== '') {
          _renderInfo = this.replaceSpacesWithUnderscores(
            entry.renderInfo.toLowerCase()
          );
        }
      }

      // optional field
      let _minGeneration = null;
      if (hasMinGeneration) {
        _minGeneration = entry.minGeneration;
      }

      // optional field
      let _os = null;
      if (hasOS) {
        if (entry.os !== '') {
          _os = entry.os.toLowerCase();
        }
      }

      if (!hasModel && !hasRenderInfo) {
        // if os field set, check it whether a platform should be disallowed
        if (hasOS) {
          if (this.isOnOSBlacklist(_os)) {
            hitBlacklist = true;
            break;
          }
        } else {
          // if no OS set and don't have neither model nor renderInfo, regard the entry as an invalid blacklist entry
          globalTracingLogger.warn(
            `isHitBlacklist() invalid blacklist entry. entry:${entry}`
          );
          continue;
        }
      }

      // 1. check renderInfo
      if (hasRenderInfo && _renderInfo !== '') {
        if (
          targetRenderInfo === _renderInfo ||
          targetRenderInfo.includes(_renderInfo)
        ) {
          if (_codecType === 'all' || _codecType === codecType) {
            if (hasOS) {
              if (this.isOnOSBlacklist(_os)) {
                hitBlacklist = true;
                break;
              }
            } else {
              hitBlacklist = true;
              break;
            }
          }
        }
      }

      // 2. check model
      // if set model in the blacklist entry
      // must a series of GPUs are disallowed
      if (_model) {
        if (_parentModel !== '') {
          if (_model === _parentModel) {
            if (_codecType === 'all' || _codecType === codecType) {
              if (_minGeneration && _minGeneration !== '') {
                const isLower = this.isLowerThanMinGeneration(
                  targetVendor,
                  targetRenderInfo,
                  _minGeneration
                );
                if (isLower) {
                  if (hasOS) {
                    if (this.isOnOSBlacklist(_os)) {
                      hitBlacklist = true;
                      break;
                    }
                  } else {
                    hitBlacklist = true;
                    break;
                  }
                }
              } else {
                if (hasOS) {
                  if (this.isOnOSBlacklist(_os)) {
                    hitBlacklist = true;
                    break;
                  }
                } else {
                  hitBlacklist = true;
                  break;
                }
              }
            }
          } else {
            // if model in blacklist is set, and model in parent whitelist is set
            // they should be the same value
            globalTracingLogger.warn(
              `isHitBlacklist() model(${_model}) in blacklist entry and model(${_parentModel}) in whitelist should be same!`
            );
            continue;
          }
        } else {
          // if parent model is not set, it means all GPUs of this vendor are allowed
          // if we need to put some of them to the blacklist, search the target renderInfo
          if (targetRenderInfo.includes(_model)) {
            if (_codecType === 'all' || _codecType === codecType) {
              if (_minGeneration && _minGeneration !== '') {
                const isLower = this.isLowerThanMinGeneration(
                  targetVendor,
                  targetRenderInfo,
                  _minGeneration
                );
                if (isLower) {
                  if (hasOS) {
                    if (this.isOnOSBlacklist(_os)) {
                      hitBlacklist = true;
                      break;
                    }
                  } else {
                    hitBlacklist = true;
                    break;
                  }
                }
              } else {
                if (hasOS) {
                  if (this.isOnOSBlacklist(_os)) {
                    hitBlacklist = true;
                    break;
                  }
                } else {
                  hitBlacklist = true;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return hitBlacklist;
  },

  isOnOSBlacklist(os) {
    const _os = os.toLowerCase();
    if (_os === 'windows' && this.isWindows()) {
      return true;
    }

    if (_os === 'mac' && this.isMac()) {
      return true;
    }

    if (_os === 'chromeos' && this.isChromeOS()) {
      return true;
    }

    if (_os === 'android' && this.isAndroid()) {
      return true;
    }

    if (_os === 'linux' && this.isLinux()) {
      return true;
    }

    if (_os === 'ios' && this.is_iOS()) {
      return true;
    }

    return false;
  },

  isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  },

  isMac() {
    return navigator.platform.indexOf('Mac') > -1;
  },

  isChromeOS() {
    try {
      if (/\bCrOS\b/.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  isAndroid() {
    try {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  isLinux() {
    return navigator.platform.indexOf('Linux') > -1 && !this.isChromeOS();
  },

  is_iOS() {
    try {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  replaceSpacesWithUnderscores(str) {
    if (!str || str === '') {
      return '';
    }

    const trimmed = str.trim();
    if (trimmed === '') {
      return '';
    }

    return `_${trimmed.replace(/ /g, '_')}_`;
  },
};

export default MediaConfigParser;
