export class StatisticHelper<T = string | number> {
  private status: T;
  private timeMap = new Map<T, number>();
  private baseTime = 0;
  private totalTime = 0;

  private flushTimeMap() {
    const currentTime = performance.now();
    const lastTime = this.timeMap.get(this.status) || 0;

    const deltaTime = currentTime - this.baseTime;

    this.timeMap.set(this.status, lastTime + deltaTime);
    this.baseTime = currentTime;
    this.totalTime += deltaTime;
  }

  public start(initialStatus: T) {
    this.status = initialStatus;
    this.baseTime = performance.now();
  }

  public update(newStatus: T) {
    if (newStatus !== this.status) {
      this.flushTimeMap();
      this.status = newStatus;
    }
  }

  public end() {
    this.flushTimeMap();

    const result: Record<string, number> = {};
    for (const [status, time] of this.timeMap) {
      result[String(status)] = Number(
        ((time / this.totalTime) * 100).toFixed(2)
      );
    }
    return result;
  }
}
