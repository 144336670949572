import { GPU_RES_TYPE, RES_OCCUPANCY_LEVEL } from './RenderConst';
import { add_monitor } from '../../worker/common/common';

/**
 * GPUBufferManager helps to create, manage and recycle GPUBuffers.
 */
class GPUBufferManager {
  #mResType = GPU_RES_TYPE.VERTEX_BUFFER;
  #mResInfo = {};
  #device = null;
  #numUsedBuffers = 0;
  #numFreeBuffers = 0;
  #freeBuffers = [];
  #usedBuffers = new Map();

  constructor(device) {
    this.#device = device;
  }

  /**
   * Acquire a GPUBuffer.
   *
   * @param {*} keyTag as identifier of the GPUBuffer
   * @param {*} usage usage of the GPUBuffer
   * @param {*} size buffer size
   * @param {*} mappedAtCreation if true, can write data directly without calling mapAsync
   * @param {*} reuse if true, the buffer will be put into the pool after creation
   * @returns a GPUBuffer or null
   */
  acquireBuffer(keyTag, usage, size, mappedAtCreation = false, reuse = true) {
    let buffer;

    if (reuse) {
      if (this.#usedBuffers.has(keyTag)) {
        buffer = this.#usedBuffers.get(keyTag);
      } else {
        if (this.#freeBuffers.length > 0) {
          buffer = this.#freeBuffers.pop();
          this.#usedBuffers.set(keyTag, buffer);
        } else {
          buffer = this.#device.createBuffer({ size, usage, mappedAtCreation });
          this.#usedBuffers.set(keyTag, buffer);
        }
      }
    } else {
      buffer = this.#device.createBuffer({ size, usage, mappedAtCreation });
    }

    return buffer;
  }

  /**
   * Release a GPUBuffer.
   * @param {*} keyTag as identifier of the GPUBuffer, use it find the buffer
   * @param {*} buffer the buffer will be released
   * @param {*} reuse if true, it will be put into the pool again, otherwise drop it
   */
  releaseBuffer(keyTag, buffer, reuse = true) {
    if (this.#usedBuffers.has(keyTag)) {
      const usedBuffer = this.#usedBuffers.get(keyTag);
      if (reuse) {
        this.#freeBuffers.push(usedBuffer);
      } else {
        usedBuffer.destroy();
      }

      this.#usedBuffers.delete(keyTag);
    } else {
      if (!reuse) {
        if (this.#freeBuffers.indexOf(buffer) != -1) {
          this.#freeBuffers[index] =
            this.#freeBuffers[this.#freeBuffers.length - 1];
          this.#freeBuffers.pop();
          buffer.destroy();
        }
      }
    }
  }

  /**
   * Get the used bytes of the allocated GPUBuffers.
   *
   * @returns used bytes of all allocated GPUBuffers
   */
  getNumUsedBuffers() {
    return this.#numUsedBuffers;
  }

  /**
   * Get the used bytes of the freed GPUBuffers.
   *
   * @returns bytes of all freed GPUBuffers
   */
  getNumFreeBuffers() {
    return this.#numFreeBuffers;
  }

  /**
   * Cleanup all the allocated GPUBuffers and reset all the states.
   */
  cleanup() {
    this.#freeBuffers.forEach((buffers, key) => {
      buffers.forEach((buffer) => {
        buffer.destroy();
      });
    });

    this.#usedBuffers.forEach((buffers, key) => {
      buffers.forEach((buffer) => {
        buffer.destroy();
      });
    });

    this.#freeBuffers.length = 0;
    this.#usedBuffers.clear();
    this.#numUsedBuffers = 0;
    this.#numFreeBuffers = 0;
  }

  /**
   * Release the GPUBuffers in the available pool.
   * It should be used when the GPU resources are nervous.
   *
   * @param {*} level an occupancy level helps to decide how to release resources
   */
  release(level) {
    if (level == RES_OCCUPANCY_LEVEL.OVERUSE) {
      this.#freeBuffers.forEach((buffers, key) => {
        buffers.forEach((buffer) => {
          buffer.destroy();
        });
      });

      this.#freeBuffers.length = 0;
      this.#numFreeBuffers = 0;
    }
  }

  getResourceType() {
    return this.#mResType;
  }

  collectResourceInfo() {
    let count = 0;
    let usedBytes = 0;
    let log = '';

    for (const [key, val] of this.#usedBuffers) {
      count++;
      usedBytes += val.size;
      log += `[GPUBufferMgr] entry{key:${key}, buffer:{label:${val.label} size:${val.size}}}\n`;
    }

    for (const buffer of this.#freeBuffers) {
      count++;
      usedBytes += buffer.size;
    }
    log += `[GPUBufferMgr] freeBuffers{size:${this.#freeBuffers.length}}\n`;
    log += `[GPUBufferMgr] total: count:${count} usedBytes:${usedBytes}\n`;

    this.#mResInfo.type = this.#mResType;
    this.#mResInfo.count = count;
    this.#mResInfo.usedBytes = usedBytes;
    this.#mResInfo.output = log;
    return this.#mResInfo;
  }

  /**
   * The occupancy level is evaluated and start to release some GPU resources by the level.
   *
   * @param {*} level an occupancy level helps to decide how to release resources
   */
  onOccupancyLevelEvaluated(level) {
    console.log(
      `[GPUBufferManager] onOccupancyLevelEvaluated() level:${level}`
    );
    add_monitor(
      `WGPU GPUBufferManager_onOccupancyLevelEvaluated() level:${level}`
    );
    this.release(level);
  }

  // #getBufferKey(size, usage) {
  //     return `${size}_${usage}`;
  // }
}

export default GPUBufferManager;
