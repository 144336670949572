import globalTracingLogger from '../common/globalTracingLogger';

class ScreenWakeLock {
  sentinel: WakeLockSentinel | null = null;
  isSupport = () => {
    return 'wakeLock' in navigator && 'request' in navigator.wakeLock;
  };

  requestWakeLock = async () => {
    if (!this.isSupport()) {
      return Promise.reject('wakeLock API not available');
    }

    if (this.sentinel && !this.sentinel.released) {
      return Promise.reject('wakeLock has requested');
    }

    await this.release();

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    document.addEventListener('fullscreenchange', this.handleVisibilityChange);

    return this.initWakeLock();
  };

  release = async () => {
    if (!this.isSupport()) {
      return 'wakeLock API not available';
    }

    if (this.sentinel) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
      document.removeEventListener(
        'fullscreenchange',
        this.handleVisibilityChange
      );
      return this.sentinel
        .release()
        .then(() => {
          this.sentinel = null;
          return 'WakeLock release success';
        })
        .catch((e) => {
          globalTracingLogger.error('Error WakeLock release:', e);
          return e;
        });
    }

    return 'WakeLock not exist';
  };

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      if (!this.sentinel || (this.sentinel && this.sentinel.released)) {
        this.sentinel = null;
        this.initWakeLock();
      }
    }
  };

  private initWakeLock = async () => {
    try {
      this.sentinel = await navigator.wakeLock.request('screen');
      return this.sentinel;
    } catch (e) {
      globalTracingLogger.error('Error WakeLock request:', e);
      return Promise.reject(e);
    }
  };
}

const screenWakeLock = new ScreenWakeLock();
export { screenWakeLock };
